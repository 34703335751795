import { useContext, useState } from 'react';
import './header.css';
import Login from '../../components/login/login/Login';
import Register from '../../components/login/register/Register';
// import ForgotPassword from '../../components/login/forgotPassword/ForgotPassword';
import { UserLoginSecContext } from '../../context/UserLoginSecContext';
import AccDrop from './accDrop/AccDrop';
import { ReactComponent as MenuIcon } from '../../assets/icons/menu.svg';
import { ReactComponent as LoginIcon } from '../../assets/icons/login.svg';
import { ReactComponent as SignUpIcon } from '../../assets/icons/signup.svg';
import { ReactComponent as PropertyIcon } from '../../assets/icons/property.svg';
import { ReactComponent as AccountIcon } from '../../assets/icons/account.svg';
import { ReactComponent as SearchIcon } from '../../assets/icons/search.svg';
import { UserAuthContext } from '../../context/UserAuthContext';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import logo from '../../assets/images/TrvTo_Logo_Small_Without_Back_PNG_zoom.png';
import Camp from '../tabsBar/searchComponents/camp';
import TabsBar from '../tabsBar/TabsBar';
import Holiday from '../tabsBar/searchComponents/holiday';

export default function Header() {
    const [searchKey, setSearchKey] = useState('');
    const [accountDrop, setAccountDrop] = useState(false);
    const [nav, setNav] = useState(false);
    const [isSearch, setIsSearch] = useState(false);
    const navigate = useNavigate();
    const [selectOption, setSelectOption] = useState('Holiday');

    const { isAuthenticated } = useContext(UserAuthContext);
    const { logButton, setLogButton, loginSec, setLoginSec } =
        useContext(UserLoginSecContext);

    const handleType = (e) => {
        setSelectOption(e.target.value)
    };

    if (nav) {
        return (
            <Navigate
                to={`/search-result/trip/${searchKey}?offset=0&limit=10`}
            />
        );
    }

    const renderSearch = () => {
        return (
            <>
                <div className="tabHidden">
                    <div style={{ display: 'flex', gap: '8px' }}>
                        <select
                            value={selectOption}
                            name="type"
                            onChange={(e) => handleType(e)}
                            className="headerSelect"
                        >
                            <option value="Select">Select</option>
                            <option value="Holiday">Holiday</option>
                            <option value="Camp" disabled>Camp</option>
                            <option value="Guide" disabled>Guide</option>
                            <option value="Vehicle" disabled>Vehicle</option>
                        </select>
                        {(selectOption === "Holiday" || selectOption === "Guide") && (<Holiday className="headerHolidaySearchBar" style={{ width: isAuthenticated ? '61.1vw' : '41.1vw' }} />)}
                        {(selectOption === "Camp" || selectOption === "Vehicle") && (<Camp className="headerSearchBar" />)}
                    </div>
                </div>
                <button
                    className="headerMenuBtn tabDisplay"
                    onClick={() => setIsSearch(!isSearch)}
                >
                    <SearchIcon className="headerMenuIcon" />
                </button>
            </>
        );
    };

    return (
        <>
            <div className="preHeader">Get 70% Off on your First Booking</div>
            <div className="headerWrapper">
                <Link to="/">
                    <img
                        alt="trvto logo"
                        src={logo}
                        className="headerLogoImg"
                    />
                </Link>

                <div className="headerTabsEndDiv">
                    {!isAuthenticated && (
                        <>
                            {renderSearch()}
                            <button
                                type="button"
                                className="headerBtn listYourPropertyBtn"
                                onClick={() => navigate('/org/login')}
                            >
                                <PropertyIcon /> List Your Trips
                            </button>
                            <button
                                type="button"
                                name="login"
                                className="headerBtn headerLoginBtn"
                                onClick={() => setLogButton(true)}
                            >
                                <LoginIcon /> Login
                            </button>
                            <button
                                type="button"
                                name="login"
                                className="headerBtn headerLoginBtn"
                                onClick={() => {
                                    setLogButton(true);
                                    setLoginSec('register')
                                }}
                            >
                                <SignUpIcon /> Sign Up
                            </button>
                        </>
                    )}

                    {isAuthenticated && (
                        <>
                            {renderSearch()}
                            <button className="headerMenuBtn" onClick={() => navigate('/account')}>
                                <AccountIcon className="headerMenuIcon" />
                            </button>
                        </>
                    )}
                    {isSearch && <TabsBar className="headerSearch" />}
                    <button
                        onClick={() => setAccountDrop(!accountDrop)}
                        className="headerMenuBtn"
                    >
                        <MenuIcon className="headerMenuIcon" />
                    </button>
                    {accountDrop && (
                        <div
                            className="headerOverlay"
                            onClick={() => setAccountDrop(false)}
                        ></div>
                    )}
                    {accountDrop && <AccDrop setAccountDrop={setAccountDrop} setLogButton={setLogButton} />}
                </div>
                {logButton && (
                    <div className="loginHeaderPop">
                        {loginSec === 'login' ? (
                            <Login
                                setLogButton={setLogButton}
                                setLoginSec={setLoginSec}
                            />
                        ) : loginSec === 'register' ? (
                            <Register
                                setLogButton={setLogButton}
                                setLoginSec={setLoginSec}
                            />
                        ) : // ) : loginSec === "forgot" ? (
                            // <ForgotPassword
                            //   setLogButton={setLogButton}
                            //   setLoginSec={setLoginSec}
                            // />
                            null}
                    </div>
                )}
            </div>
        </>
    );
}
