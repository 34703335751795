import { useNavigate } from "react-router-dom";
import tableData from "../../dummyData/tickets.json";
import "./supTickets.css";
import { useEffect, useState } from "react";
import ReactLoading from 'react-loading';
import { fetchTickets } from "./ticketsFunctions/fetchTicketsInfo";

const SupTickets = () => {
    const tableHeader = [
        'Ticket ID',
        'Customer ID',
        'Type',
        'Organizer',
        'Description',
        'Phone Number',
        'Place',
        'Date'
    ];
    const navigate = useNavigate();
    const [ticketsData, setTicketsData] = useState([]);
    const [loading, setLoading] = useState([]);

    useEffect(() => {
        fetchTickets(setLoading, setTicketsData);
    }, []);

    return (
        <>
            {tableData.data.length > 0 && (
                <>
                    <div className="tableContainer ticketsContainer">
                        <table className="table">
                            <thead className="tableHead">
                                <tr>
                                    {tableHeader.map((head, headIndex) => {
                                        return <th key={headIndex}>{head}</th>;
                                    })}
                                </tr>
                            </thead>
                            <tbody>
                                {tableData?.data?.map((row, rowIndex) => {
                                    return (
                                        <tr key={rowIndex} className="tableBodyRow" onClick={() => navigate(`/support/tickets/${row._id}`)}>
                                            <td>{row.ticket_id}</td>
                                            <td>{row.customer}</td>
                                            <td>{row.description}</td>
                                            <td>{row.type}</td>
                                            <td>{row.organizer}</td>
                                            <td>{row.phoneNumber}</td>
                                            <td>{row.placeName}</td>
                                            <td>{row.date}</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                    {loading && (
                        <div className="loadingBackground">
                            <ReactLoading
                                type="spin"
                                color="#006aff"
                                className="loadingSpin"
                            />
                        </div>
                    )}
                    {/* {tableData.length > 10 && (
                        <Pagination
                            paginationClick={paginationClick}
                            numberOfRows={totalData}
                            offSet={offSet}
                            pageNumber={pageNumber}
                        />
                    )} */}
                </>
            )}
        </>
    );
};

export default SupTickets;
