export const maskEmail = (email) => {
    const first = email[0];
    const last = email[email.indexOf('@') - 1];
    const domain = email.slice(email.indexOf('@'));
    const maskedPart = email.slice(1, email.indexOf('@')).replace(/./g, '*');
    return `${first}${maskedPart}${last}${domain}`;
}

export const maskPhoneNumber = (phoneNumber) => {
    const first = phoneNumber[0];
    const last = phoneNumber[phoneNumber.length - 1];
    const masked = phoneNumber.slice(1, -1).replace(/./g, '*');
    return `${first}${masked}${last}`;
}