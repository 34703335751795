import handleChange from "../../utils/handleChange";
import "./dropdownReg.css";

export default function DropdownReg({
  label,
  name,
  options,
  value,
  req,
  setFormData,
}) {
  return (
    <div className="dropRegDiv">
      <label className="dropRegLabel">{label}</label>
      <div className="dropRegInputDiv">
        <select
          value={value}
          name={name}
          onChange={(e) => handleChange(e, setFormData)}
          required={req}
          className="dropRegSelect"
        >
          <option value="-1">Select</option>
          {options.map(function (val, typeIndex) {
            return (
              <option key={typeIndex} value={val}>
                {val}
              </option>
            );
          })}
        </select>
      </div>
    </div>
  );
}
