//this is used to disply the images and quick information of the trips/others

import Carousel from '../carousel/Carousel';
import ReviewStars from '../reviewStars/ReviewStars';
import Location from '../../assets/icons/location.svg';
import { ReactComponent as LoveBlack } from '../../assets/icons/wishlist.svg';
import { ReactComponent as LoveRed } from '../../assets/icons/favorite.svg';
import './tripCard.css';
import { useEffect, useState } from 'react';
import { addWishlistFun, delWishFun, userWishlistFun } from '../../api/userAPI';

export default function TripCard({ imageData }) {
    const [typeSel, setTypeSel] = useState('trip');
    const [userWishlist, setUserWishlist] = useState([]);

    useEffect(() => {
        const fetchWishlistData = async () => {
            try {
                const res = await userWishlistFun(typeSel);
                setUserWishlist(res.data);
            } catch (error) {
                console.log(error);
            }
        };
        fetchWishlistData();
    }, []);

    const handleWishlistClick = async (e, id) => {
        try {
            const isFavorite = userWishlistIds?.has(id);
            let res;

            if (isFavorite) {
                console.log(id);
                res = await delWishFun(typeSel, id);
            } else {
                res = await addWishlistFun(typeSel, { packageId: id });
            }

            const updatedWishlist = await userWishlistFun(typeSel);
            setUserWishlist(updatedWishlist.data);
        } catch (error) {
            console.log(error);
        }
    };

    function TripCardClick(packId) {
        // return (window.location.href = `/trip/details/${packId}`);
        //to open the details page in the new tab
        return window.open(
            `/trip/details/${packId}`,
            '_blank',
            'noopener,noreferrer'
        );
    }

    let userWishlistIds;
    if (userWishlist.length > 0) {
        userWishlistIds = new Set(
            userWishlist[0][typeSel].map((item) => item._id)
        );
    }

    return imageData?.map((item, index) => {
        const { where } = item?.quickView;
        const isFavorite = userWishlistIds?.has(item._id);

        return (
            <div className="tripCard" key={index}>
                {/* Will use later for Ads section */}
                {/* {item.fillingFast && <div className="identityTag fillingFast alignCenter">Filling Fast</div>} */}
                {/* {item.onlyFewLeft && <div className="identityTag fewLeft alignCenter">Only Few Left</div>} */}
                <Carousel
                    imageData={item.images}
                    TripCardClick={TripCardClick}
                    packId={item._id}
                />
                <button
                    className="wishlist pop-animation"
                    onClick={(e) => handleWishlistClick(e, item._id)}
                >
                    {/* className={`wishlist ${popStates[item.id] ? "pop-animation" : ""}`} */}
                    {isFavorite ? (
                        <LoveRed className="wishlist-icon" />
                    ) : (
                        <LoveBlack className="wishlist-icon" />
                    )}
                </button>
                <div
                    className="tripCardInfo"
                    onClick={() => TripCardClick(item._id)}
                >
                    <p className="tripCardName">{item?.title || 'N/A'}</p>
                    <p className="tripCardLocation">
                        <img src={Location} alt="location" /> {where}
                    </p>
                    <div className="tripCardNumInfo">
                        <ReviewStars rating={item?.rating || 0} />
                        <span>{item?.rating || 0}</span>
                        {/*Enable once backend has value /*}
              {/* <p style={{ margin: 0 }}>({item.reviews} Reviews)</p> */}
                    </div>
                    <div className="tripCardPriceDiv">
                        <p className="tripPrice striked">
                            $
                            {item.price[0]?.price +
                                (item.price[0]?.price * 10) / 100}
                        </p>
                        <p className="tripPrice">${item.price[0]?.price}</p>
                    </div>
                    <button className="viewDetails">View Details</button>
                </div>
            </div>
        );
    });
}
