import handleTextChange from '../../utils/handleTextChange';
import { useState } from 'react';
import './supportAssistance.css';
import Pagination from '../../components/pagination/Pagination';
import SupportData from '../../dummyData/support.json';
import Dialog from '../../components/dialogModal/dialogModal';

export default function SupportAssistance() {
    const [modal, setModal] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [offSet, setOffSet] = useState(0);
    const [searchText, setSearchText] = useState('');
    const [tableData, setTableData] = useState([]);
    const totalData = tableData.length - 1;
    const tableHeader = [
        'Name',
        'Phone Number',
        'Email ID',
        'Customer ID',
        'Place Name',
        'Location',
        'Date & Time',
        'Time of Booked',
        'Type',
        'Number of People',
        'Price',
        'Organizer Name',
        'Pickup Location',
        'Action'
    ];

    const paginationClick = (number, offSetNumber) => {
        if (Math.ceil(totalData / 10) > 1) {
            setPageNumber(+number);
            setOffSet(+offSetNumber);
        }
    };

    function handleSearch() {
        setTableData(SupportData.data);
    }

    return (
        <div className="supportAssContainer">
            <div className="supportSearchContainer">
                <div className="supportAssSearchDiv">
                    <select
                        name="date"
                        id="date"
                        className="supportSelect select mr-2"
                    // onChange={(e) => setSelectedTrip(e.target.value)}
                    >
                        <option value="">Choose search type</option>
                        <option value="id">ID</option>
                        <option value="emailId">Email</option>
                        <option value="phoneNumber">Phone Number</option>
                    </select>
                    <div className="supAssdivider" />
                    <input
                        placeholder="Search here..."
                        onChange={(e) => handleTextChange(e, setSearchText)}
                        value={searchText}
                        className="supportAssSearchInput"
                    />
                    <button
                        onClick={handleSearch}
                        className="supportAssSearchBtn"
                    >
                        Search
                    </button>
                </div>
                {/* <div className="supportAssResultDiv">
                Search key word Search result
                <div>Customer Details</div>
                <div>
                    Bookings
                    <div>
                        Each booking will contain - Booking Id, name, no. of
                        peolpe, price, booked on, trip on, email, phone number,
                        Resend details button, comment, payment details, raise
                        ticket,
                    </div> */}
                {/* To send email and msg once more for particular package */}
                {/* <button>Send Email/Msg</button>
                </div>
            </div> */}
            </div>
            {tableData.length > 0 && (
                <>
                    <h4 className="supportAssLabel">Search Result</h4>
                    <div className="tableContainer">
                        <table className="table">
                            <thead className="tableHead">
                                <tr>
                                    {tableHeader.map((head, headIndex) => {
                                        return <th key={headIndex}>{head}</th>;
                                    })}
                                </tr>
                            </thead>
                            <tbody>
                                {tableData?.map((row, rowIndex) => {
                                    return (
                                        <tr
                                            key={rowIndex}
                                            className="tableBodyRow"
                                        >
                                            <td>{row.name}</td>
                                            <td>{row.phoneNumber}</td>
                                            <td>{row.emailId}</td>
                                            <td>{row.customerId}</td>
                                            <td>{row.placeName}</td>
                                            <td>{row.location}</td>
                                            <td>{row.date}</td>
                                            <td>{row.bookingTime}</td>
                                            <td>{row.type}</td>
                                            <td>{row.numberOfPeople}</td>
                                            <td>{row.price}</td>
                                            <td>{row.organizer}</td>
                                            <td>{row.pickupLocation}</td>
                                            <td className="accBtnContainer">
                                                <button className="actBtn payoutBtn">
                                                    Send Email
                                                </button>
                                                <button
                                                    className="actBtn billBtn"
                                                    onClick={() =>
                                                        setModal(true)
                                                    }
                                                >
                                                    Create Ticket
                                                </button>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                        <div className="responsiveCardContainer">
                            {tableData?.map((row, rowIndex) => (
                                <div key={rowIndex} className="responsiveCard">
                                    <div className="cardContent">
                                        <p>
                                            <strong>{tableHeader[0]}</strong>{' '}
                                            {row.name}
                                        </p>
                                        <p>
                                            <strong>{tableHeader[1]}</strong>{' '}
                                            {row.phoneNumber}
                                        </p>
                                        <p>
                                            <strong>{tableHeader[2]}</strong>{' '}
                                            {row.emailId}
                                        </p>
                                        <p>
                                            <strong>{tableHeader[3]}</strong>{' '}
                                            {row.customerId}
                                        </p>
                                        <p>
                                            <strong>{tableHeader[4]}</strong>{' '}
                                            {row.placeName}
                                        </p>
                                        <p>
                                            <strong>{tableHeader[5]}</strong>{' '}
                                            {row.location}
                                        </p>
                                        <p>
                                            <strong>{tableHeader[6]}</strong>{' '}
                                            {row.date}
                                        </p>
                                        <p>
                                            <strong>{tableHeader[7]}</strong>{' '}
                                            {row.bookingTime}
                                        </p>
                                        <p>
                                            <strong>{tableHeader[8]}</strong>{' '}
                                            {row.type}
                                        </p>
                                        <p>
                                            <strong>{tableHeader[9]}</strong>{' '}
                                            {row.numberOfPeople}
                                        </p>
                                        <p>
                                            <strong>{tableHeader[10]}</strong>{' '}
                                            {row.price}
                                        </p>
                                        <p>
                                            <strong>{tableHeader[11]}</strong>{' '}
                                            {row.organizer}
                                        </p>
                                        <p>
                                            <strong>{tableHeader[12]}</strong>{' '}
                                            {row.pickupLocation}
                                        </p>
                                        <div className="accBtnContainer">
                                            <button className="actBtn payoutBtn">
                                                Send Email
                                            </button>
                                            <button
                                                className="actBtn billBtn"
                                                onClick={() => setModal(true)}
                                            >
                                                Create Ticket
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    {tableData.length > 10 && (
                        <Pagination
                            paginationClick={paginationClick}
                            numberOfRows={totalData}
                            offSet={offSet}
                            pageNumber={pageNumber}
                        />
                    )}
                </>
            )}
            {modal && (
                <Dialog openModal={modal} heading={'Create Ticket'}>
                    <div className="dialogContent">
                        <p className="ticketTitle">Name of Booking</p>
                        <p className="ticketContent">Abhi</p>
                        <p className="ticketTitle">Booking ID</p>
                        <p className="ticketContent">23443</p>
                        <p className="ticketTitle">Date</p>
                        <p className="ticketContent">03/11/2024</p>
                        <p className="ticketTitle">Comment</p>
                        <textarea className="ticketComment" />
                        <div className="alignCenter">
                            <button
                                className="dialogActBtn cancel"
                                onClick={() => setModal(false)}
                            >
                                Cancel
                            </button>
                            <button className="dialogActBtn submit">
                                Submit
                            </button>
                        </div>
                    </div>
                </Dialog>
            )}
        </div>
    );
}
