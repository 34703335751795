import { useContext, useEffect, useRef, useState } from 'react';
import { SingleDatePicker } from 'react-dates';
import moment from 'moment';
import 'react-dates/lib/css/_datepicker.css';
import handleChange from '../../utils/handleChange';
import './orgTripDateTab.css';
import { OrgDatesContext } from '../../context/OrgDatesContest';
import { tripDateCreateFun } from '../../api/orgTripAPI';
import errorHandle from '../../utils/errorHandle';
import { toast } from 'react-toastify';
import ReactLoading from 'react-loading';

export default function OrgTripDateTab({ price, setDates }) {
    const bookingTabRef = useRef(null);
    const [dateDetails, setDateDetails] = useState({
        startDate: '',
        totalSlots: ''
    });
    const [loading, setLoading] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);
    //states for date picker
    const [focused, setFocused] = useState(null);
    const [selectedDate, setSelectedDate] = useState(null);
    const notAllowedDates = useRef([]);
    const { dates, upComing } = useContext(OrgDatesContext);
    function handleDateDis() {
        setFocused(true);
    }

    async function addDate() {
        setLoading(true);
        const packId = window.location.pathname.split('/')[4];
        try {
            const newDate = await tripDateCreateFun(packId, {
                startDate: selectedDate,
                totalSlots: dateDetails.totalSlots
            });
            //clear the old date and slot values
            toast.success('Successfully added new date');
            setDateDetails({ startDate: '', totalSlots: '' });
            setDates((prevState) => {
                return [...prevState, newDate.data];
            });
            setLoading(false);
        } catch (error) {
            setLoading(false);
            errorHandle(error);
        }
    }

    useEffect(() => {
        if (isExpanded && bookingTabRef.current) {
            window.scrollTo({
                top: bookingTabRef.current.offsetTop - 100,
                behavior: 'smooth'
            });
        }
    }, [isExpanded]);

    useEffect(() => {
        // The notAllowedDates should be updated omly when dates are of upcoming data
        if (upComing) {
            notAllowedDates.current = dates.map((da) => {
                return moment(da.startDate);
            });
            //Dont allow coming next two days to add trip
            const today = new Date();
            const twoDates = [
                moment(today),
                moment(new Date().setDate(today.getDate() + 1)),
                moment(new Date().setDate(today.getDate() + 2))
            ];
            notAllowedDates.current = [...notAllowedDates.current, ...twoDates];
        }
    }, [dates]);

    const isDayBlocked = (day) => {
        return notAllowedDates.current.some((notAllowedDate) =>
            notAllowedDate.isSame(day, 'day')
        );
    };

    const toggleExpand = () => setIsExpanded(!isExpanded);

    return (
        <div
            ref={bookingTabRef}
            className={`orgTripBookingTab ${
                isExpanded ? 'expanded' : 'collapsed'
            }`}
            onClick={toggleExpand}
        >
            {loading && (
                <div className="loadingBackground">
                    <ReactLoading
                        type="spin"
                        color="#006aff"
                        className="loadingSpin"
                    />
                </div>
            )}
            <div className="orgTrDaTabPriceDiv">
                <span className="orgTrDaTabPrice">Rs. {price}/person</span>
                <span className="orgTrDaTabIncu">Including all taxes</span>
            </div>
            <div className="tripBookingSubTab">
                <label className="tripBookDatalabel2">Start Date</label>
                <SingleDatePicker
                    date={selectedDate}
                    onDateChange={(date) => {
                        setSelectedDate(date);
                    }}
                    focused={focused}
                    onFocusChange={({ focused }) => setFocused(focused)}
                    numberOfMonths={1}
                    isDayBlocked={isDayBlocked}
                    isOutsideRange={(day) => day.isBefore(moment(), 'day')} // Disallow past dates
                    // className="tripBookInput"
                />
                {/* //the below p willl overlap the SingleDatePicker so that we can display date in indian formate */}
                {/* <p onClick={handleDateDis} className="orgTrDaTabDateDis">
                    {selectedDate
                        ? selectedDate.format('DD-MM-YYYY')
                        : 'Select Date'}
                </p> */}
                <label className="tripBookDatalabel2">Slots</label>
                <input
                    // placeholder="People"
                    type="number"
                    value={dateDetails.slots}
                    name="totalSlots"
                    className="orgTrDaTabSlotIn"
                    onChange={(e) => handleChange(e, setDateDetails)}
                />
                <button
                    type="button"
                    className="tripBookingButton"
                    onClick={addDate}
                >
                    Add New Date
                </button>
            </div>
        </div>
    );
}
