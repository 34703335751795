import './orgTripDateDetails.css';
import TripDateTable from './tripDateTable/TripDateTable';
// import booking from '../../dummyData/bookingDateDetails.json';
import { useEffect, useState } from 'react';
import ReactLoading from 'react-loading';
import fetchTripDateTable from './orgTripDateFun/fetchTripDateTable';
import moment from 'moment';
import AddBooking from './addBooking/AddBooking';
import EmptyPage from '../../components/empty-page/emptyPage';

//pagination not required as we are populating data from bookingId array.
//And data wont reach more than 200

export default function OrgTripDateDetails() {
    const [tripDateDetails, setTripDateDetails] = useState({
        bookingId: [],
        contentId: {}
    });
    const [addBo, setAddBo] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchTripDateTable(setTripDateDetails, setLoading);
        //eslint-disable-next-line
    }, []);

    return (
        <div>
            {loading && (
                <div className="loadingBackground">
                    <ReactLoading
                        type="spin"
                        color="#006aff"
                        className="loadingSpin"
                    />
                </div>
            )}
            {Object.keys(tripDateDetails.contentId).length !== 0 ? (
                <div className="orgTripDateUpDiv">
                    <div className="orgTripDateHeadingDiv">
                        <h1 className="orgTripDateName">
                            {tripDateDetails.contentId.title}
                        </h1>
                        <div>
                            <div>
                                <span className="orgTripDateSubDetails">
                                    {tripDateDetails.contentId.days +
                                        'D/' +
                                        tripDateDetails.contentId.nights +
                                        'N'}
                                </span>

                                <span className="orgTripDateDot"></span>
                                <span className="orgTripDateSubDetails">
                                    {tripDateDetails.startDate.split('T')[0]}
                                </span>
                                <span className="orgTripDateDot"></span>
                                <span className="orgTripDateSubDetails">
                                    {
                                        tripDateDetails.contentId.quickView
                                            .startTime
                                    }
                                </span>
                                <span className="orgTripDateDot"></span>
                                <span className="orgTripDateSubDetails">
                                    {
                                        tripDateDetails.contentId.quickView
                                            .baseCities
                                    }
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className='cardLayout'>
                        <div className="orgTripDateOverview">
                            <div className="orgTripDateInfo">
                                <span className="orgTripDateSpan">Booked</span>
                                <span className="orgTripDateSpan">
                                    {tripDateDetails.bookedSlots}
                                </span>
                            </div>
                            <div className="orgTripDateInfo">
                                <span className="orgTripDateSpan">Total Slots</span>
                                <span className="orgTripDateSpan">
                                    {tripDateDetails.totalSlots}
                                </span>
                            </div>
                            <div className="orgTripDateInfo">
                                <span className="orgTripDateSpan">Status</span>
                                <span className="orgTripDateSpan">
                                    {moment(new Date()) <
                                        moment(tripDateDetails.startDate)
                                        ? 'Upcoming'
                                        : 'Completed'}
                                </span>
                            </div>
                        </div>
                        <div className="orgTripBtnDiv">
                            <button
                                className="orgTipBtnDow blueBtn"
                                onClick={() => setAddBo(true)}
                            >
                                Add Booking
                            </button>
                        </div>
                    </div>
                    {/* <div className="orgTripBtnDiv">
            <button className="orgTipBtnDow">Download</button>
          </div> */}
                </div>
            ) : (
                <EmptyPage />
            )}

            {tripDateDetails.bookingId.length !== 0 ? (
                <TripDateTable bookingData={tripDateDetails.bookingId} />
            ) : (
                <EmptyPage />
            )}
            {Object.keys(tripDateDetails.contentId).length !== 0 && addBo && (
                <AddBooking
                    packData={tripDateDetails.contentId}
                    setAddBo={setAddBo}
                />
            )}
        </div>
    );
}
