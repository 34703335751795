import { ReactComponent as Nodata } from "../../assets/images/no-data.svg";
import "./emptyPage.css";

const EmptyPage = () => {
    return (
        <div className="noDataCon">
            <Nodata />
        </div>
    );
};

export default EmptyPage;
