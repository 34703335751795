import banner from '../assets/images/searchResult.jpg';

export const banners = [
  { "url": banner, "alt": "banner" }
];

export const data = [
  {
    "id": 1,
    "name": "Star lord Helmet",
    "Destination": "Egypt",
    "rating": 4.5,
    "reviews": 234,
    "price": 125.00,
    "isFavorite": true,
    "fillingFast": false,
    "onlyFewLeft": true,
    "images": [
      { "imgSrc": "https://picsum.photos/310/340?random=1", "alt": "img1 for travel" },
      { "imgSrc": "https://picsum.photos/310/340?random=2", "alt": "img2 for travel" },
      { "imgSrc": "https://picsum.photos/310/340?random=3", "alt": "img3 for travel" },
      { "imgSrc": "https://picsum.photos/310/340?random=1", "alt": "img1 for travel" },
      { "imgSrc": "https://picsum.photos/310/340?random=2", "alt": "img2 for travel" },
      { "imgSrc": "https://picsum.photos/310/340?random=3", "alt": "img3 for travel" }
    ]
  },
  {
    "id": 2,
    "name": "Star lord Helmet - 2",
    "Destination": "France",
    "rating": 4,
    "reviews": 34,
    "price": 105.00,
    "isFavorite": false,
    "fillingFast": true,
    "onlyFewLeft": false,
    "images": [
      { "imgSrc": "https://picsum.photos/310/340?random=1", "alt": "img1 for travel" },
      { "imgSrc": "https://picsum.photos/310/340?random=2", "alt": "img2 for travel" },
      { "imgSrc": "https://picsum.photos/310/340?random=3", "alt": "img3 for travel" },
      { "imgSrc": "https://picsum.photos/310/340?random=1", "alt": "img1 for travel" },
      { "imgSrc": "https://picsum.photos/310/340?random=2", "alt": "img2 for travel" },
      { "imgSrc": "https://picsum.photos/310/340?random=3", "alt": "img3 for travel" }
    ]
  },
  {
    "id": 3,
    "name": "Star lord Helmet - 3",
    "Destination": "Italy",
    "rating": 3.5,
    "reviews": 343,
    "price": 125.00,
    "isFavorite": true,
    "fillingFast": false,
    "onlyFewLeft": false,
    "images": [
      { "imgSrc": "https://picsum.photos/310/340?random=1", "alt": "img1 for travel" },
      { "imgSrc": "https://picsum.photos/310/340?random=2", "alt": "img2 for travel" },
      { "imgSrc": "https://picsum.photos/310/340?random=3", "alt": "img3 for travel" },
      { "imgSrc": "https://picsum.photos/310/340?random=1", "alt": "img1 for travel" },
      { "imgSrc": "https://picsum.photos/310/340?random=2", "alt": "img2 for travel" },
      { "imgSrc": "https://picsum.photos/310/340?random=3", "alt": "img3 for travel" }
    ]
  },
  {
    "id": 4,
    "name": "Star lord Helmet - 1",
    "Destination": "France",
    "rating": 3,
    "reviews": 233,
    "price": 145.00,
    "isFavorite": true,
    "fillingFast": false,
    "onlyFewLeft": false,
    "images": [
      { "imgSrc": "https://picsum.photos/310/340?random=1", "alt": "img1 for travel" },
      { "imgSrc": "https://picsum.photos/310/340?random=2", "alt": "img2 for travel" },
      { "imgSrc": "https://picsum.photos/310/340?random=3", "alt": "img3 for travel" },
      { "imgSrc": "https://picsum.photos/310/340?random=1", "alt": "img1 for travel" },
      { "imgSrc": "https://picsum.photos/310/340?random=2", "alt": "img2 for travel" },
      { "imgSrc": "https://picsum.photos/310/340?random=3", "alt": "img3 for travel" }
    ]
  },
  {
    "id": 5,
    "name": "Star lord Helmet",
    "Destination": "Egypt",
    "rating": 4.5,
    "reviews": 234,
    "price": 125.00,
    "isFavorite": true,
    "fillingFast": false,
    "onlyFewLeft": true,
    "images": [
      { "imgSrc": "https://picsum.photos/310/340?random=1", "alt": "img1 for travel" },
      { "imgSrc": "https://picsum.photos/310/340?random=2", "alt": "img2 for travel" },
      { "imgSrc": "https://picsum.photos/310/340?random=3", "alt": "img3 for travel" },
      { "imgSrc": "https://picsum.photos/310/340?random=1", "alt": "img1 for travel" },
      { "imgSrc": "https://picsum.photos/310/340?random=2", "alt": "img2 for travel" },
      { "imgSrc": "https://picsum.photos/310/340?random=3", "alt": "img3 for travel" }
    ]
  },
  {
    "id": 6,
    "name": "Star lord Helmet - 2",
    "Destination": "France",
    "rating": 4,
    "reviews": 34,
    "price": 105.00,
    "isFavorite": false,
    "fillingFast": true,
    "onlyFewLeft": false,
    "images": [
      { "imgSrc": "https://picsum.photos/310/340?random=1", "alt": "img1 for travel" },
      { "imgSrc": "https://picsum.photos/310/340?random=2", "alt": "img2 for travel" },
      { "imgSrc": "https://picsum.photos/310/340?random=3", "alt": "img3 for travel" },
      { "imgSrc": "https://picsum.photos/310/340?random=1", "alt": "img1 for travel" },
      { "imgSrc": "https://picsum.photos/310/340?random=2", "alt": "img2 for travel" },
      { "imgSrc": "https://picsum.photos/310/340?random=3", "alt": "img3 for travel" }
    ]
  },
  {
    "id": 7,
    "name": "Star lord Helmet - 3",
    "Destination": "Italy",
    "rating": 3.5,
    "reviews": 343,
    "price": 125.00,
    "isFavorite": true,
    "fillingFast": false,
    "onlyFewLeft": false,
    "images": [
      { "imgSrc": "https://picsum.photos/310/340?random=1", "alt": "img1 for travel" },
      { "imgSrc": "https://picsum.photos/310/340?random=2", "alt": "img2 for travel" },
      { "imgSrc": "https://picsum.photos/310/340?random=3", "alt": "img3 for travel" },
      { "imgSrc": "https://picsum.photos/310/340?random=1", "alt": "img1 for travel" },
      { "imgSrc": "https://picsum.photos/310/340?random=2", "alt": "img2 for travel" },
      { "imgSrc": "https://picsum.photos/310/340?random=3", "alt": "img3 for travel" }
    ]
  },
  {
    "id": 8,
    "name": "Star lord Helmet - 1",
    "Destination": "France",
    "rating": 3,
    "reviews": 233,
    "price": 145.00,
    "isFavorite": true,
    "fillingFast": false,
    "onlyFewLeft": false,
    "images": [
      { "imgSrc": "https://picsum.photos/310/340?random=1", "alt": "img1 for travel" },
      { "imgSrc": "https://picsum.photos/310/340?random=2", "alt": "img2 for travel" },
      { "imgSrc": "https://picsum.photos/310/340?random=3", "alt": "img3 for travel" },
      { "imgSrc": "https://picsum.photos/310/340?random=1", "alt": "img1 for travel" },
      { "imgSrc": "https://picsum.photos/310/340?random=2", "alt": "img2 for travel" },
      { "imgSrc": "https://picsum.photos/310/340?random=3", "alt": "img3 for travel" }
    ]
  },
  {
    "id": 9,
    "name": "Star lord Helmet",
    "Destination": "Egypt",
    "rating": 4.5,
    "reviews": 234,
    "price": 205.00,
    "isFavorite": true,
    "fillingFast": false,
    "onlyFewLeft": true,
    "images": [
      { "imgSrc": "https://picsum.photos/310/340?random=1", "alt": "img1 for travel" },
      { "imgSrc": "https://picsum.photos/310/340?random=2", "alt": "img2 for travel" },
      { "imgSrc": "https://picsum.photos/310/340?random=3", "alt": "img3 for travel" },
      { "imgSrc": "https://picsum.photos/310/340?random=1", "alt": "img1 for travel" },
      { "imgSrc": "https://picsum.photos/310/340?random=2", "alt": "img2 for travel" },
      { "imgSrc": "https://picsum.photos/310/340?random=3", "alt": "img3 for travel" }
    ]
  },
  {
    "id": 10,
    "name": "Star lord Helmet - 2",
    "Destination": "France",
    "rating": 4,
    "reviews": 34,
    "price": 180.00,
    "isFavorite": false,
    "fillingFast": true,
    "onlyFewLeft": false,
    "images": [
      { "imgSrc": "https://picsum.photos/310/340?random=1", "alt": "img1 for travel" },
      { "imgSrc": "https://picsum.photos/310/340?random=2", "alt": "img2 for travel" },
      { "imgSrc": "https://picsum.photos/310/340?random=3", "alt": "img3 for travel" },
      { "imgSrc": "https://picsum.photos/310/340?random=1", "alt": "img1 for travel" },
      { "imgSrc": "https://picsum.photos/310/340?random=2", "alt": "img2 for travel" },
      { "imgSrc": "https://picsum.photos/310/340?random=3", "alt": "img3 for travel" }
    ]
  },
  {
    "id": 12,
    "name": "Star lord Helmet - 3",
    "Destination": "Italy",
    "rating": 3.5,
    "reviews": 343,
    "price": 295.00,
    "isFavorite": true,
    "fillingFast": false,
    "onlyFewLeft": false,
    "images": [
      { "imgSrc": "https://picsum.photos/310/340?random=1", "alt": "img1 for travel" },
      { "imgSrc": "https://picsum.photos/310/340?random=2", "alt": "img2 for travel" },
      { "imgSrc": "https://picsum.photos/310/340?random=3", "alt": "img3 for travel" },
      { "imgSrc": "https://picsum.photos/310/340?random=1", "alt": "img1 for travel" },
      { "imgSrc": "https://picsum.photos/310/340?random=2", "alt": "img2 for travel" },
      { "imgSrc": "https://picsum.photos/310/340?random=3", "alt": "img3 for travel" }
    ]
  },
  {
    "id": 13,
    "name": "Star lord Helmet - 1",
    "Destination": "France",
    "rating": 3,
    "reviews": 233,
    "price": 205.00,
    "isFavorite": true,
    "fillingFast": false,
    "onlyFewLeft": false,
    "images": [
      { "imgSrc": "https://picsum.photos/310/340?random=1", "alt": "img1 for travel" },
      { "imgSrc": "https://picsum.photos/310/340?random=2", "alt": "img2 for travel" },
      { "imgSrc": "https://picsum.photos/310/340?random=3", "alt": "img3 for travel" },
      { "imgSrc": "https://picsum.photos/310/340?random=1", "alt": "img1 for travel" },
      { "imgSrc": "https://picsum.photos/310/340?random=2", "alt": "img2 for travel" },
      { "imgSrc": "https://picsum.photos/310/340?random=3", "alt": "img3 for travel" }
    ]
  }
];
