import './myWishlist.css';

import ReactLoading from 'react-loading';
import TitleBar from '../../components/titleBar/titleBar';
import TripStrap from '../../components/tripStrap/TripStrap';
import { useEffect, useState } from 'react';
import EmptyPage from '../../components/empty-page/emptyPage';
import myWishlistData from './myWishlistFun/myWishlistData';

export default function MyWishlist() {
    const [typeSel, setTypeSel] = useState('trip');
    const [wishlistData, setWishlistData] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchWishlistData = async () => {
            try {
                await myWishlistData(typeSel, setWishlistData, setLoading);
            } catch (err) {
                console.log('Failed to fetch search results');
            }
        };

        fetchWishlistData();
    }, [typeSel]);

    return (
        <div>
            {loading && (
                <div className="loadingBackground">
                    <ReactLoading
                        type="spin"
                        color="#006aff"
                        className="loadingSpin"
                    />
                </div>
            )}
            <TitleBar title="My Wishlist" />
            <div className="customContainer">
                {wishlistData?.length === 0 && !loading ? (
                    <EmptyPage />
                ) : (
                    wishlistData?.length > 0 && wishlistData[0][typeSel]?.map((item, index) => {
                        return (
                            <TripStrap
                                key={index}
                                page={'wishlist'}
                                data={item}
                            />
                        );
                    }))};
            </div>
        </div>
    );
}
