import HomeCarousel from '../../../components/homeCarousel/homeCarousel';
import MobileCarousel from '../../../components/mobileCarousel/mobileCarousel';
import { DestiationData } from '../../../dummyData/destinationData';
import './destination.css';

const DestinationSection = () => {
    return (
        <>
            <div className="destinationBg" />
            <div className="destinationSection customContainer" id='destinationSection'>
                <div className="alignCenter mobCenter">
                    <div>
                        <p className='pageCount'>02 Destinations</p>
                        <p className="homeTitle">Explore Real Adventure</p>
                    </div>
                </div>
                <div className='mobHidden'>
                    <HomeCarousel data={DestiationData} />
                </div>
                <div className="mobDisplay">
                    <MobileCarousel type='destination' data={DestiationData} />
                </div>
            </div>
        </>
    );
}

export default DestinationSection;