import https from '../init/https';

//INFO-01 : Get routes to fetch the details of a trip/ayur pack/guid pack/camp for user based on
//the id of the package
export async function getDetailsFun(type, packId) {
    const result = await https.get(`info/${type}/${packId}`);
    return result;
}

//INFO-02 : To get basic information of the organizer
export async function orgBasicFun(type, orgId) {
    const result = await https.get(`info/org/${type}/${orgId}`);
    return result;
}

//INFO-03 : To get the total count of different trip/ayur/guid
//packages for a particular organizer
export async function packCountOrgFun(type, orgId) {
    const result = await https.get(`info/pack/count/${type}/${orgId}`);
    return result;
}

//INFO-04 : To get basic information of the organizer
export async function orgPacksFun(type, orgId, limit, offset) {
    const result = await https.get(
        `info/pack/${type}/${orgId}?limit=${limit}&offset=${offset}`
    );
    return result;
}

//INFO-05 : To get trip Date details
export async function getDateDetailsFun(type, packId) {
    const result = await https.get(`info/date/${type}/${packId}`);
    return result;
}

//INFO-06 : To get Faq count details
export async function getFaqCountFun() {
    const result = await https.get('/info/faq/count');
    return result;
}

//INFO-07 : To get Faq details
export async function getFaqDetailsFun(limit, offset) {
    const result = await https.get(`/info/faq?limit=${limit}&offset=${offset}`);
    return result;
}

//INFO-08 : To get Offer details
export async function getOfferDetailsFun(offerCode) {
    const result = await https.get(`/info/offer/${offerCode}`);
    return result;
}

//INFO-09 : To get Filter details
export async function getFilterDetailsFun(type) {
    const result = await https.get(`/info/filter/dropdowns/${type}`);
    return result;
}
