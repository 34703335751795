import { ticketsInfo } from "../../../api/supportAPI";
import errorHandle from "../../../utils/errorHandle";

export const fetchTickets =
  async (setLoading, setTicketsData) => {
    setLoading(true);
    try {
      const tickets = await ticketsInfo();
      console.log(tickets);
      setTicketsData((prevState) => [...prevState, ...tickets?.data]);
      setLoading(false);
    } catch (error) {
      errorHandle(error);
      setLoading(false);
    }
  };
