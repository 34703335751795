import './dashboardTable.css';

import { Navigate } from 'react-router-dom';
import { useState } from 'react';
import Pagination from '../../../components/pagination/Pagination';

export default function DashboardTable({ tableData }) {
    const [rowClickId, setRowClickId] = useState(0);
    const tableHeader = [
        'Name',
        'Date',
        'Days/Nights',
        'Slots',
        'Start City and Price',
        'Difficulty'
    ];
    const [pageNumber, setPageNumber] = useState(1);
    const [offSet, setOffSet] = useState(0);
    const totalData = tableData.length - 1;

    if (rowClickId !== 0) {
        return <Navigate to={`/org/trip/date/${rowClickId}`} />;
    }

    const paginationClick = (number, offSetNumber) => {
        if (Math.ceil(totalData / 10) > 1) {
            setPageNumber(+number);
            setOffSet(+offSetNumber);
        }
    };

    const getStartCity = (row) => {
        const city = row.startCities.map((item) => {
            return `${item.city} - ₹${item.price}, `;
        });
        return city;
    };

    return (
        <>
            <div className="tableContainer">
                <table className="table">
                    <thead className="tableHead">
                        <tr>
                            {tableHeader.map((head, headIndex) => {
                                return <th key={headIndex}>{head}</th>;
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {tableData?.map((row, rowIndex) => {
                            return (
                                <tr
                                    key={rowIndex}
                                    className="tableBodyRow"
                                    onClick={() => setRowClickId(row._id)}
                                >
                                    <td>{row.name}</td>
                                    <td>{row.startDate}</td>
                                    <td>{`${row.days} days/${row.nights} nights`}</td>
                                    <td>{`${row.bookedSlots}/${row.totalSlots}`}</td>
                                    <td>{getStartCity(row)}</td>
                                    <td>{row.difficulty}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
                <div className="responsiveCardContainer">
                    {tableData?.map((row, rowIndex) => (
                        <div key={rowIndex} className='responsiveCard' onClick={() => setRowClickId(row._id)}>
                            <div className="cardContent">
                                <p><strong>{tableHeader[0]}</strong> {row.name}</p>
                                <p><strong>{tableHeader[1]}</strong> {row.startDate}</p>
                                <p><strong>{tableHeader[2]}</strong> {`${row.days} days / ${row.nights} nights`}</p>
                                <p><strong>{tableHeader[3]}</strong> {`${row.bookedSlots} / ${row.totalSlots}`}</p>
                                <p><strong>{tableHeader[4]}</strong> {getStartCity(row)}</p>
                                <p><strong>{tableHeader[5]}</strong> {row.difficulty}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            {tableData.length > 10 && (
                <Pagination
                    paginationClick={paginationClick}
                    numberOfRows={totalData}
                    offSet={offSet}
                    pageNumber={pageNumber}
                />
            )}
        </>
    );
}
