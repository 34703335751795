import errorHandle from '../../../utils/errorHandle';
import { orgTripBookedFun, tripOrgDateFun } from '../../../api/orgTripAPI';

export default async function fetchTripDates(
    selectedTrip,
    setTripDates,
    selectedType,
    setLoading
) {
    setLoading(true);
    try {
        const type = selectedType === 'upcoming' ? 'next' : 'previous';
        const table = await tripOrgDateFun(selectedTrip, type);
        setTripDates(table.data);
        setLoading(false);
    } catch (error) {
        setLoading(false);
        errorHandle(error);
    }
}
