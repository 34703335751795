import "./supTicketsDetails.css";
import detailedData from "../../../dummyData/tickets.json";
import { ReactComponent as Message } from "../../../assets/icons/message.svg";
import { ReactComponent as Refund } from "../../../assets/icons/refund.svg";
import { ReactComponent as CloseTicket } from "../../../assets/icons/closeTicket.svg";
import { ReactComponent as CancelBooking } from "../../../assets/icons/cancelBooking.svg";
import MoreItem from "./moreItem";

const SupTicketsDetails = () => {
    const data = detailedData.data[0];
    return (
        <div className="supportDetailsContainer">
            <h2>{data.customer}</h2>
            <div className="infoContainer">
                <div className="infoDetail">
                    <p className="infoLabel">Ticket ID:</p>
                    <p className="infoValue">{data.ticket_id}</p>
                </div>
                <div className="infoDetail">
                    <p className="infoLabel">Customer ID:</p>
                    <p className="infoValue">{data.customer_id}</p>
                </div>
                <div className="infoDetail">
                    <p className="infoLabel">Type:</p>
                    <p className="infoValue">{data.type}</p>
                </div>
                <div className="infoDetail">
                    <p className="infoLabel">Time of Booked:</p>
                    <p className="infoValue">{data.bookingTime}</p>
                </div>
                <div className="infoDetail">
                    <p className="infoLabel">Organizer Name:</p>
                    <p className="infoValue">{data.organizer}</p>
                </div>
            </div>
            <div className="supportDivider" />
            <div className="supportTicketDetails">
                <div>
                    <h4>Description</h4>
                    <p>{data.description}</p>
                    <div className="blogComments borderNone">
                        <h3>Add Comment</h3>
                        <div className="commentForm">
                            <textarea placeholder="Write your comment..." />
                            <button type="submit">Submit</button>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="btnContainer">
                        <button className="btnSupport message"><Message /> Send Message</button>
                        <button className="btnSupport refund"><Refund /> Refund</button>
                        <button className="btnSupport booking"><CancelBooking /> Cancel Booking</button>
                        <button className="btnSupport closeTicket"><CloseTicket /> Close Ticket</button>
                    </div>
                    <div className="moreDetails">
                        <MoreItem data={data.emailId} type='email' />
                        <MoreItem data={data.numberOfPeople} type='profile' />
                        <MoreItem data={data.phoneNumber} type='phone' />
                        <MoreItem data={data.location} type='location' />
                        <MoreItem data={data.bookingTime} type='calendar' />
                        <MoreItem data={data.price} type='price' />
                        <MoreItem data={data.placeName} type='place' />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SupTicketsDetails;

