import './dialogModal.css';
import { useEffect, useRef } from 'react';

const Dialog = ({ openModal, heading, children }) => {
    const ref = useRef();

    useEffect(() => {
        if (openModal) {
            ref.current?.showModal();
        } else {
            ref.current?.close();
        }
    }, [openModal]);

    return (
        <dialog ref={ref} className="dialog">
            <h4 className="dialogHeading">{heading}</h4>
            {children}
            {/* <button onClick={closeModal}>Close</button> */}
        </dialog>
    );
};

export default Dialog;
