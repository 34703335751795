import { toast } from 'react-toastify';
import errorHandle from '../../../../utils/errorHandle';
import { tripAvaFun } from '../../../../api/bookingAPI';

export default async function tripBookingClick(
    booking,
    setBookingData,
    setReviewPage,
    tripData,
    amount,
    setLoading,
    packDates
) {
    if (booking.transport === 'With Transport') {
        if (
            booking.date === '' ||
            booking.startCity === '' ||
            booking.pickupLocation === ''
        ) {
            toast.error('Please choose all the above options');
            return;
        }
    } else {
        if (booking.date === null || booking.transport === '') {
            toast.error('Please choose all the above options');
            return;
        }
    }
    setLoading(true);
    try {
        const packId = window.location.pathname.split('/')[3];
        const type = window.location.pathname.split('/')[1];
        let id;
        packDates.forEach((element) => {
            console.log(
                new Date(element.startDate).toISOString().split('T')[0],
                new Date(booking.date).toISOString().split('T')[0]
            );
            if (
                new Date(element.startDate).toISOString().split('T')[0] ===
                new Date(booking.date).toISOString().split('T')[0]
            ) {
                console.log('Inside');
                id = element._id;
            }
        });
        const checkAva = await tripAvaFun({
            tripDateId: id,
            slots: booking.people
        });
        if (checkAva.data.status === true) {
            setBookingData({
                ...booking,
                ...amount,
                title: tripData.title,
                packDateId: id,
                packId: packId,
                organizerId: tripData.organizerId,
                type: type,
                days: tripData.days,
                nights: tripData.nights
            });
            setReviewPage(true);
            setLoading(false);
        } else {
            setLoading(false);
            toast.error(
                'Only' + checkAva.data.remainingSlots + ' slots are available'
            );
        }
    } catch (error) {
        setLoading(false);
        errorHandle(error);
    }
}
