export default function handleNestedChange(info, setData, nest) {
  const userInfo = info.target.value;
  const element = info.target.name;
  console.log(element);
  setData(function (prevState) {
    return {
      ...prevState,
      [nest]: { ...prevState[nest], [element]: userInfo },
    };
  });
}
