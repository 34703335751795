const FeatureCard = ({ data }) => {
    return (
        <>
            {data.slice(0, -1).map((item) => (
                <div key={item.id} className="feature">
                    <img src={item.image} alt={item.title} />
                    <span className="featureCount">{item.count} Tours</span>
                    <span className="featureTitle">{item.title}</span>
                </div>
            ))}
        </>
    );
};

export default FeatureCard;
