import { orgTripDrop, orgTripReviews } from '../../../api/orgTripAPI';
import errorHandle from '../../../utils/errorHandle';

export const fetchOrgReviews = (setReviews, setLoading, offset, trip = '') => {
    setLoading(true);
    try {
        orgTripReviews(10, offset, trip).then((res) => {
            setReviews(res.data);
            setLoading(false);
        });
    } catch (error) {
        setLoading(false);
        errorHandle(error);
    }
};

export const getTrips = (setTrips, setLoading) => {
    setLoading(true);
    try {
        orgTripDrop().then((res) => {
            setTrips(res.data);
            setLoading(false);
        });
    } catch (error) {
        setLoading(false);
        errorHandle(error);
    }
};
