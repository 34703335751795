import { useState } from 'react';
import tabSelect from '../../utils/tabSelect';
import './detailsTabs.css';
import Overview from './overView/Overview';
import Itinerary from './itinerary/Itinerary';
import Included from './included/Included';
import Reviews from './reviews/Reviews';
import FAQ from './FAQ/FAQ';
// import tripDetailsData from '../../dummyData/tripDetailsData.json';
import { Rooms } from './rooms/Rooms';
import Dates from './Dates/Dates';
import TermsDis from './termsDis/TermsDis';

export default function DetailsTabs({ tripDetailsData }) {
    const [selectedTab, setSelectedTab] = useState('Overview');
    const tabs = [
        { heading: 'Rooms', value: 'Rooms', for: 'ayur' }, //amenities will be in the each room type like zostel
        { heading: 'Overview', value: 'Overview', for: 'both' },
        { heading: 'Itinerary', value: 'Itinerary', for: 'trip' },
        { heading: 'Inc/Exc', value: 'Including', for: 'both' },
        { heading: 'Reviews', value: 'Reviews', for: 'both' },
        { heading: 'FAQ', value: 'FAQ', for: 'both' }, //FAQ is frequently asked question
        { heading: 'Terms', value: 'Terms', for: 'both' },
        { heading: 'Location', value: 'Location', for: 'ayur' },
        { heading: 'Dates', value: 'Dates', for: 'org' }
    ];
    const whichType =
        window.location.pathname.split('/')[1] === 'org'
            ? window.location.pathname.split('/')[2]
            : window.location.pathname.split('/')[1];

    const whichSection = window.location.pathname.split('/')[1];

    return (
        <div>
            <div
                className={
                    whichSection === 'org'
                        ? 'orgTripDetailsTabs'
                        : 'tripDetailsTabs'
                }
            >
                {tabs.map(function (tab, tabIndex) {
                    return (
                        <button
                            key={tabIndex}
                            className={`${
                                selectedTab === tab.value
                                    ? 'tripDetailsTabButtonHi'
                                    : 'tripDetailsTabButton'
                            } ${
                                (whichType === 'trip' ||
                                    whichType === 'guid') &&
                                tab.for === 'ayur' &&
                                'tripHide'
                            }
              ${whichType === 'camp' && tab.for === 'trip' && 'tripHide'}
              ${whichSection !== 'org' && tab.for === 'org' && 'tripHide'}`}
                            onClick={() => tabSelect(tab.value, setSelectedTab)}
                        >
                            {tab.heading}
                        </button>
                    );
                })}
            </div>
            <div className="tripDetailsInfoDiv">
                {selectedTab === 'Overview' && (
                    <Overview data={tripDetailsData?.overview} />
                )}
                {selectedTab === 'Rooms' && (
                    <Rooms data={tripDetailsData?.overview} />
                )}
                {selectedTab === 'Itinerary' && (
                    <Itinerary data={tripDetailsData?.itinerary} />
                )}
                {selectedTab === 'Including' && (
                    <Included
                        dataInc={tripDetailsData?.included}
                        dataExc={tripDetailsData?.excluded}
                    />
                )}
                {selectedTab === 'Reviews' && (
                    <Reviews data={tripDetailsData?.reviews} />
                )}
                {selectedTab === 'FAQ' && <FAQ data={tripDetailsData?.FAQ} />}
                {/* //FAQ is frequently asked question */}
                {selectedTab === 'Terms' && (
                    <TermsDis data={tripDetailsData?.terms} />
                )}
                {selectedTab === 'Dates' && <Dates id={tripDetailsData?._id} />}
            </div>
        </div>
    );
}
