export default function addBoNumSlots(e, setMuBookData, muBookData) {
    const slots = e.target.value;
    let tempUser = muBookData.userDetails;
    //   if (+slots > +muBookData.totalSeats) {
    //     tempUser.push({});
    //   } else {
    //     tempUser = [];
    //     [...Array(slots)].forEach((element) => {
    //       tempUser.push(muBookData.userDetails(element - 1));
    //     });
    //   }
    while (muBookData.userDetails.length < slots) {
        // Add new object (for example, an empty object or with default values)
        tempUser.push({});
    }
    while (muBookData.userDetails.length > slots) {
        // Remove objects from the end of the array
        tempUser.pop();
    }
    setMuBookData((prevState) => {
        return {
            ...prevState,
            totalSeats: slots,
            userDetails: [...tempUser]
        };
    });
}
