export default function AccBank({ accDetails }) {
    return (
        <div>
            <h2 className="orgAccountH2">Bank Details</h2>
            <div className="orgAccountSecDiv">
                <div className="orgAccountGrid">
                    <div className="orgAccountInDiv">
                        <span className="orgAccountLabel">Bank Name</span>
                        <p className="orgAccountp">
                            {accDetails.bank.bankName}
                        </p>
                    </div>
                    <div className="orgAccountInDiv">
                        <span className="orgAccountLabel">Account Name</span>
                        <p className="orgAccountp">{accDetails.bank.accName}</p>
                    </div>
                </div>
                <div className="orgAccountGrid">
                    <div className="orgAccountInDiv">
                        <span className="orgAccountLabel">Branch Name</span>
                        <p className="orgAccountp">
                            {accDetails.bank.branchName}
                        </p>
                    </div>
                    <div className="orgAccountInDiv">
                        <span className="orgAccountLabel">Account Number</span>
                        <p className="orgAccountp">{accDetails.bank.accNum}</p>
                    </div>
                </div>
                <div className="orgAccountGrid">
                    <div className="orgAccountInDiv">
                        <span className="orgAccountLabel">IFSC</span>
                        <p className="orgAccountp">{accDetails.bank.ifsc}</p>
                    </div>
                </div>
            </div>
        </div>
    );
}
