import "./rooms.css";
import { RoomCard } from "../../roomCard/RoomCard";
import { DateRangePicker } from "react-dates";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import moment from "moment";
import { useState } from "react";

export function Rooms() {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [focusedInput, setFocusedInput] = useState(null);
  const roomsData = [{}];
  return (
    <div>
      <div className="roomsDateDiv">
        <div>
          <h3 className="roomsDateh3">Select your dates</h3>
          <span className="roomsDateText">Book your rooms</span>
        </div>

        <div className="dateRangeDiv">
          <DateRangePicker
            startDate={startDate} // momentPropTypes.momentObj or null,
            startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
            endDate={endDate} // momentPropTypes.momentObj or null,
            endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
            onDatesChange={({ startDate, endDate }) => {
              setStartDate(startDate);
              setEndDate(endDate);
            }} // PropTypes.func.isRequired,
            focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
            onFocusChange={(focusedInput) => setFocusedInput(focusedInput)} // PropTypes.func.isRequired,
            minimumNights={1} // Example to ensure at least 1 night
            isOutsideRange={(day) => day.isBefore(moment(), "day")} // Disallow past dates
          />
        </div>
      </div>
      {roomsData.map(function (room, roomIndex) {
        return <RoomCard key={roomIndex} />;
      })}
    </div>
  );
}
