import { useContext, useEffect, useRef, useState } from 'react';
import './reviewBooking.css';
import ReviewBookInfo from './reviewBookSection/reviewBookInfo/ReviewBookInfo';
import ReviewBookSumm from './reviewBookSection/reviewBookSumm/ReviewBookSumm';
import { BookingDataContext } from '../../context/BookingDataContext';
import errorHandle from '../../utils/errorHandle';
import { toast } from 'react-toastify';
import { tripAvaFun, tripReserveFun } from '../../api/bookingAPI';
import ReactLoading from 'react-loading';
import { Navigate } from 'react-router-dom';
import { gstTripResFun } from '../../api/gstBookAPI';
import emailValid from '../../utils/emailValid';
import phoneValid from '../../utils/phoneValid';
import TitleBar from '../../components/titleBar/titleBar';
import Payment from '../../components/payment/payment';

export default function ReviewBooking() {
    const { bookingData } = useContext(BookingDataContext);
    const [userDetails, setUserDetails] = useState(
        [...Array(+bookingData.people)].map((_) => {
            return {
                name: '',
                age: 0,
                sex: '',
                countryCode: '+91',
                phoneNumber: '',
                emailId: ''
            };
        })
    );
    const [nav, setNav] = useState(false);
    const [navHome, setNavHome] = useState(false);
    const [navDetails, setNavDetails] = useState(false);
    const [loading, setLoading] = useState(false);
    const [guest, setGuest] = useState(false);
    const [options, setOptions] = useState({
        amount: "",
        name: "",
        description: "",
        order_id: "",
        prefill: {
            name: "",
            email: "",
            contact: "",
        }
    });
    const paymentRef = useRef();

    console.log(bookingData, Object.keys(bookingData).length);

    //get the data from localStorage, if data is not available send the
    //   user back to home/pack deetails page
    //   const bookingData = window.localStorage.getItem(bookingData);

    function checkData() {
        if (userDetails.length === +bookingData.people) {
            //check if any object has an empty value for any key
            return userDetails.every((obj) =>
                Object.values(obj).every((value) => value !== '')
            );
        } else {
            return false;
        }
    }

    useEffect(() => {
        setOptions({
            amount: "500",
            name: "Trip to Goa",
            description: "Payment for Goa trip booking",
            // Generate order id and pass here
            order_id: "order_9A33XWu170gUtm",
            prefill: {
                name: "John Doe",
                email: "john.doe@example.com",
                contact: "9876543210",
            }
        });
    }, []);

    function checkEmailPho() {
        return userDetails.every((obj) => {
            return Object.keys(obj).every((key) => {
                return key === 'emailId'
                    ? emailValid(obj.emailId)
                    : key === 'phoneNumber'
                        ? phoneValid(obj.phoneNumber)
                        : true;
            });
        });
    }

    async function reviewConfirm() {
        if (checkData()) {
            console.log(checkEmailPho());
            if (checkEmailPho()) {
                setLoading(true);
                try {
                    const checkAva = await tripAvaFun({
                        tripDateId: bookingData.packDateId,
                        slots: bookingData.people
                    });
                    if (checkAva.data.status === true) {
                        //if guests is true, you need to call guest APIs else call normal APIs
                        if (!guest) {
                            await tripReserveFun({
                                tripDateId: bookingData.packDateId,
                                slots: bookingData.people
                            });
                        } else {
                            await gstTripResFun({
                                tripDateId: bookingData.packDateId,
                                slots: bookingData.people
                            });
                        }
                        paymentRef.current.initiatePayment();
                        setNav(true);
                    } else {
                        toast.error(
                            'Only' +
                            checkAva.data.remainingSlots +
                            ' slots are available'
                        );
                    }
                    setLoading(false);
                } catch (error) {
                    setLoading(false);
                    errorHandle(error);
                }
            } else {
                toast.error('Enter valid phone and email id');
            }
        } else {
            toast.error('Enter all the participations data');
        }
    }

    useEffect(function () {
        if (Object.keys(bookingData).length === 0) {
            return setNavHome(true);
        } else {
            //     console.log('indise review update');
            //     setUserDetails(
            //         [...Array(+bookingData.people)].map((_) => {
            //             return {
            //                 name: '',
            //                 age: 0,
            //                 sex: '',
            //                 countryCode: '+91',
            //                 phoneNumber: '',
            //                 emailId: ''
            //             };
            //         })
            //     );
        }
        //eslint-disable-next-line
    }, []);

    console.log(userDetails);

    if (navHome) {
        return <Navigate to="/" />;
    }

    if (navDetails) {
        return (
            <Navigate
                to={`/${bookingData.type}/details/${bookingData.packId}`}
            />
        );
    }

    if (nav) {
        <Navigate to="/payments" />;
    }

    return (
        <div>
            {loading && (
                <div className="loadingBackground">
                    <ReactLoading
                        type="spin"
                        color="#006aff"
                        className="loadingSpin"
                    />
                </div>
            )}
            <TitleBar title="Review Booking Details" />
            <div className="bookingReviewDetails">
                <ReviewBookInfo
                    userDetails={userDetails}
                    bookingData={bookingData}
                    setUserDetails={setUserDetails}
                    guest={guest}
                    setGuest={setGuest}
                />
                <div className="bookingReviewPriceDiv">
                    <ReviewBookSumm
                        bookingData={bookingData}
                        reviewConfirm={reviewConfirm}
                        setNavDetails={setNavDetails}
                    />
                </div>
                <Payment ref={paymentRef} options={options} />
            </div>
        </div>
    );
}
