import "./termsDis.css";

export default function TermsDis({ data }) {
  return (
    <div className="termsDisDiv">
      <div className="termsDisPoints">
        <ul>
          {data?.map((ter, terIndex) => {
            return (
              <li key={terIndex} className="termsDisText">➡️ {ter}</li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}
