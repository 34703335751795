import { useNavigate } from "react-router-dom";
import "./blogCard.css";

export default function BlogCard({ blogData }) {
  const navigate = useNavigate();
  return (
    <div className="blogCard" onClick={() => navigate('/blog/details')}>
      <img alt="blog" src={blogData?.image} className="blogImage" />
      <div className="blogCardTextDiv">
        <h3 className="blogCardName">{blogData?.name}</h3>
        <p className="blogCardDis">{blogData?.shortDescription}</p>
      </div>
    </div>
  );
}
