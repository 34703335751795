import "./supAccount.css";

export default function SupAccount() {
  return (
    <div>
      <h2>Wellcome Support Name</h2>
      <span>Name of the owners</span>
      <span>Contact Number</span>
      <span>Email</span>
      <span>Address</span>
      <button>Logout</button>
    </div>
  );
}
