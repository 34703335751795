import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { storage } from '../config/firebase';

//upload the files to firebase and get the URL
export default async function fileUpload(file, type, which) {
    let storageRef;

    if (which === 'create') {
        const orgId = localStorage.getItem(orgId) || 'org';
        storageRef = ref(
            storage,
            process.env.REACT_APP_TRIPS_FILE_PATH / type / orgId / file.name
        );
    } else if (which === 'logo') {
        storageRef = ref(
            storage,
            process.env.REACT_APP_TRIPS_FILE_PATH / type / 'logo' / file.name
        );
    }
    const snapshot = await uploadBytes(storageRef, file);
    const downloadURL = await getDownloadURL(snapshot.ref);
    return downloadURL;
}
