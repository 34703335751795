import handleChange from "../../utils/handleChange";
import handleNestedChange from "../../utils/handleNestedChange";
import CharCount from "../charCount/CharCount";
import "./textarea.css";

export default function Textarea({
  label,
  name,
  value,
  placeholder,
  setFormData,
  min,
  limit,
  nest,
}) {
  return (
    <div className="textareaDiv">
      <label className="textareaLabel">{label}</label>
      <div className="textareaInputDiv">
        <textarea
          value={value}
          name={name}
          onChange={
            nest
              ? (e) => handleNestedChange(e, setFormData, nest)
              : (e) => handleChange(e, setFormData)
          }
          className="textareaInput"
          placeholder={placeholder}
        ></textarea>
        <CharCount string={value ? value : ""} min={min} limit={limit} />
      </div>
    </div>
  );
}
