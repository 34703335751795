import { toast } from 'react-toastify';
import DirectInput from '../../../../components/directInput/DirectInput';
import emailValid from '../../../../utils/emailValid';
import phoneValid from '../../../../utils/phoneValid';
import errorHandle from '../../../../utils/errorHandle';

export default function RegOtp({
    formData,
    setFormData,
    setLoading,
    otpStatus,
    setOTPStatus
}) {
    async function genOTP() {
        //generate OTP for the email and phone nummber
        //check the phone number and email before submiting the data
        if (!phoneValid(formData.phoneNumber)) {
            toast.error('Enter a valid phone number');
            return;
        }
        if (!emailValid(formData.emailId)) {
            toast.error('Enter a valid phone number');
            return;
        }
        console.log('genOTP');
        try {
            // setLoading(true);
            // await orgGetOTPFun(formData.type, {
            //     emailId: formData.emailId,
            //     phoneNumber: formData.phoneNumber
            // });
            setLoading(false);
            setOTPStatus(true);
            toast.success(
                'OTP has been send to ' +
                formData.phoneNumber +
                ' and ' +
                formData.emailId
            );
        } catch (error) {
            setLoading(false);
            errorHandle(error);
        }
    }

    return (
        <div>
            <h2 className="orgRegisterH2">OTP</h2>
            <div className="orgRegisterSecDiv">
                <button
                    type="button"
                    className={
                        formData.phoneNumber && formData.emailId && !otpStatus
                            ? 'orgRegisterAddBtn blueBtn'
                            : ''
                    }
                    disabled={
                        formData.phoneNumber && formData.emailId && !otpStatus
                            ? false
                            : true
                    }
                    onClick={genOTP}
                >
                    Generate OTP
                </button>
                {formData.phoneNumber && formData.emailId && (
                    <p>
                        OTP will be sent to {formData.phoneNumber} and{' '}
                        {formData.emailId}
                    </p>
                )}

                {otpStatus && (
                    <>
                        <DirectInput
                            label="Phone OTP"
                            name={'phoneOTP'}
                            value={formData.phoneOTP}
                            type="number"
                            placeholder=""
                            setFormData={setFormData}
                            min={6}
                            limit={6}
                            nest=""
                        />
                        <DirectInput
                            label="Email OTP"
                            name={'emailOTP'}
                            value={formData.emailOTP}
                            type="number"
                            placeholder=""
                            setFormData={setFormData}
                            min={6}
                            limit={6}
                            nest=""
                        />
                    </>
                )}
            </div>
        </div>
    );
}
