import { useState } from 'react';
import './nestedTags.css';

export default function NestedTags({ packData, setPackData, index }) {
    const [tag, setTag] = useState('');

    function addPickup() {
        if (tag === '') {
            alert('Please enter the text');
        } else {
            setPackData(function (prevState) {
                const updatedArrya = prevState.price.map(function (
                    item,
                    itemKey
                ) {
                    if (index === itemKey) {
                        return {
                            ...item,
                            pickupLocation: [...item.pickupLocation, tag]
                        };
                    }
                    return item;
                });
                return { ...prevState, price: [...updatedArrya] };
            });
            setTag('');
        }
    }
    function removePickup(text) {
        // const tempArray = packData.price[index].pickupLocation;
        // let index = tempArray.indexOf(text);
        // tempArray.splice(index, 1);
        setPackData(function (prevState) {
            const updatedArrya = prevState.price.map(function (item, itemKey) {
                if (index === itemKey) {
                    const tempArray = item.pickupLocation;
                    const textIndex = tempArray.indexOf(text);
                    tempArray.splice(textIndex, 1);
                    return { ...item, pickupLocation: [...tempArray] };
                }
                return item;
            });
            return { ...prevState, price: [...updatedArrya] };
        });
    }

    return (
        <div>
            <div className="itineraryInDiv">
                <label className="itineraryLabel">Pickup Location</label>
                <div className="itineraryInputDiv">
                    <input
                        type="text"
                        onChange={(e) => setTag(e.target.value)}
                        placeholder="Ex- Indhrinagar Bus stop (9:00 PM), Enter and press Add Pickup to add"
                        value={tag}
                        className="nestedTagInput"
                    ></input>
                    <button
                        type="button"
                        onClick={addPickup}
                        className="nestedTagOkBtn blueBtn"
                    >
                        Add Pickup
                    </button>
                </div>
            </div>
            {packData.price[index].pickupLocation.length > 0 && (
                <div className="tagContainer">
                    <div className="tagOutDiv">
                        {packData.price[index].pickupLocation.map((text) => {
                            return (
                                <div className="tagDiv">
                                    <p className="tagP">{text}</p>
                                    <button
                                        className="tagButton"
                                        onClick={(e) => removePickup(text)}
                                    >
                                        X
                                    </button>
                                </div>
                            );
                        })}
                    </div>
                </div>
            )}
        </div>
    );
}
