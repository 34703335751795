import errorHandle from '../../../utils/errorHandle';
import { orgTripBookedFun } from '../../../api/orgTripAPI';

export default async function fetchTripDateTable(
    setTripDateDetails,
    setLoading
) {
    const dateId = window.location.pathname.split('/')[4];
    setLoading(true);
    try {
        const table = await orgTripBookedFun(dateId);
        setTripDateDetails(table.data);
        setLoading(false);
    } catch (error) {
        setLoading(false);
        errorHandle(error);
    }
}
