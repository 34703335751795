import { useState } from 'react';
import './faq.css';
import CharCount from '../../../../components/charCount/CharCount';

export default function FAQ({ packData, setPackData }) {
    const [queCount, setQueCount] = useState(1);

    function handleFAQ(e, index) {
        setPackData((prevState) => {
            const updatedArrya = prevState.FAQ.map(function (item, itemKey) {
                if (index === itemKey) {
                    return { ...item, [e.target.name]: e.target.value };
                }
                return item;
            });
            return {
                ...prevState,
                FAQ: updatedArrya
            };
        });
    }

    function addQuestion() {
        setPackData(function (prevState) {
            return {
                ...prevState,
                FAQ: [...prevState.FAQ, { question: '', answer: '' }]
            };
        });
        setQueCount(queCount + 1);
    }

    function deleteQuestion() {
        if (queCount > 1) {
            setPackData(function (prevState) {
                const updatedArrya = prevState.FAQ;
                updatedArrya.pop(); //pop should used here only, if use pop in the above line, it returns
                //the removed item which is not the expected outcome
                return {
                    ...prevState,
                    FAQ: [...updatedArrya]
                };
            });
            setQueCount(queCount - 1);
        }
    }

    return (
        <div>
            <h2 className="orgPackUpH2">FAQ</h2>
            <div className="orgPackUpSecDiv">
                {Array.apply(null, { length: queCount }).map(function (
                    _,
                    itnKey
                ) {
                    return (
                        <div key={itnKey}>
                            <p className="itineraryLabel">
                                Question {itnKey + 1}
                            </p>
                            <div className="itineraryInDiv">
                                <label className="itineraryLabel">
                                    Question
                                </label>
                                <div className="itineraryInputDiv">
                                    <textarea
                                        value={
                                            packData.FAQ[itnKey]
                                                ? packData.FAQ[itnKey].question
                                                : ''
                                        }
                                        name="question"
                                        onChange={(e) => handleFAQ(e, itnKey)}
                                        placeholder={`Question ${itnKey + 1}`}
                                        className="itineraryInput"
                                    ></textarea>
                                    <CharCount
                                        string={
                                            packData.FAQ[itnKey]
                                                ? packData.FAQ[itnKey].question
                                                : ''
                                        }
                                        min={5}
                                        limit={300}
                                    />
                                </div>
                            </div>
                            <div className="itineraryDiv">
                                <label className="itineraryLabel">Answer</label>
                                <div className="itineraryInputDiv">
                                    <textarea
                                        value={
                                            packData.FAQ[itnKey]
                                                ? packData.FAQ[itnKey].answer
                                                : ''
                                        }
                                        name="answer"
                                        onChange={(e) => handleFAQ(e, itnKey)}
                                        placeholder={`Answer ${itnKey + 1}`}
                                        className="itineraryInput"
                                    ></textarea>
                                    <CharCount
                                        string={
                                            packData.FAQ[itnKey]
                                                ? packData.FAQ[itnKey].answer
                                                : ''
                                        }
                                        min={2}
                                        limit={500}
                                    />
                                </div>
                            </div>
                        </div>
                    );
                })}
                <button
                    type="button"
                    onClick={addQuestion}
                    className="itineraryAddBtn blueBtn"
                >
                    Add Question
                </button>
                <button
                    type="button"
                    onClick={deleteQuestion}
                    className="itineraryDelBtn"
                    disabled={queCount === 1 ? true : false}
                >
                    Delete Question
                </button>
            </div>
        </div>
    );
}
