import { createContext, useEffect, useRef, useState } from 'react';
import randomString from '../utils/randomString';

export const SupAuthContext = createContext();

export function SupAuthProvider({ children }) {
    /* When we store something in local storage, the value gets converted to string hence hence when you 
      store any false value (null, undefined, NaN) gets converted to string and the string become truthy value.
      so convert it  back to original value before comparing */

    // const [isSupAuthenticated, setIsSupAuthenticated] = useState(function () {
    //     // Get initial state from local storage
    //     const savedValue = localStorage.getItem('sup');
    //     return savedValue ? savedValue : '';
    // });
    const [isSupAuthenticated, setIsSupAuthenticated] = useState(function () {
        // Get initial state from local storage
        const savedValue = localStorage.getItem('sup');
        return savedValue ? true : false;
    });
    // const firstRun = useRef(true);
    //   const [Sup, setSup] = useState({});

    // useEffect(() => {
    //     if (firstRun.current !== true) {
    //         // Save state to local storage whenever it changes
    //         console.log(isSupAuthenticated);
    //         localStorage.setItem('sup', isSupAuthenticated);
    //     }
    //     firstRun.current = false;
    // }, [isSupAuthenticated]);

    useEffect(
        () => {
            const sup = localStorage.getItem('sup');
            if (sup) {
                setIsSupAuthenticated(true);
            }
        },
        //eslint-disable-next-line
        []
    );

    const SupLoginFun = (userData) => {
        localStorage.setItem('supName', userData.name ? userData.name : '');
        localStorage.setItem('supId', userData.userId ? userData.userId : '');
        localStorage.setItem('supToken', userData.token ? userData.token : '');
        localStorage.setItem(
            'supExpiry',
            userData.expiry ? userData.expiry : ''
        );
        localStorage.setItem(
            'supAccess',
            userData.access ? userData.access : ''
        );
        localStorage.setItem('sup', randomString(30));
        //generate a random string based the userId and put it here
        // setIsSupAuthenticated(randomString(30));
        setIsSupAuthenticated(true);
        // setSup(userData);
        console.log(userData);
    };

    const SupLogoutFun = () => {
        // setIsSupAuthenticated(false);
        window.localStorage.clear();
        // return <Navigate to="/sup/login" />;
        return (window.location.href = '/sup/login');

        // setSup(null);
    };

    return (
        <SupAuthContext.Provider
            value={{ isSupAuthenticated, SupLoginFun, SupLogoutFun }}
        >
            {children}
        </SupAuthContext.Provider>
    );
}
