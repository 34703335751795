import { useEffect, useState } from 'react';
import './orgAccount.css';
import ReactLoading from 'react-loading';
import errorHandle from '../../utils/errorHandle';
import AccOwner from './orgAccSec/AccOwner';
import AccCompany from './orgAccSec/AccCompany';
import AccAddress from './orgAccSec/AccAddress';
import AccContactPer from './orgAccSec/AccContactPer';
import AccBank from './orgAccSec/AccBank';
import { FetchOrgAccount } from './orgAccountFun/fetchOrgAccount';
import OrgInfo from './orgAccSec/orgInfo';

export default function OrgAccount() {
    const [accDetails, setAccDetails] = useState({});
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        async function fetchAccData() {
            setLoading(true);
            try {
                await FetchOrgAccount(setAccDetails, setLoading);
            } catch (error) {
                setLoading(false);
                errorHandle(error);
            }
        }

        fetchAccData();
    }, []);

    return (
        <div className="orgAccountDiv">
            {loading && (
                <div className="loadingBackground">
                    <ReactLoading
                        type="spin"
                        color="#006aff"
                        className="loadingSpin"
                    />
                </div>
            )}
            {Object.keys(accDetails).length === 0 ? (
                <p className="orgAccountLabel">No data found</p>
            ) : (
                <>
                    <h1 className="orgAccountH1">
                        Welcome {accDetails.companyName}
                    </h1>
                    <button className="orgAccEditBtn blueBtn">Edit</button>
                    <OrgInfo accDetails={accDetails} />
                    <AccOwner accDetails={accDetails} />
                    <AccCompany accDetails={accDetails} />
                    <AccAddress accDetails={accDetails} />
                    <AccContactPer accDetails={accDetails} />
                    <AccBank accDetails={accDetails} />
                </>
            )}
        </div>
    );
}
