import { tripSearchFun } from "../../../api/searchAPI";
import errorHandle from "../../../utils/errorHandle";

export const fetchSearchResult =
  async (text, offset, limit, setLoading, setSearchData) => {
    setLoading(true);
    try {
      const search = await tripSearchFun(text, offset, limit);
      setSearchData((prevState) => [...prevState, ...search?.data]);
      setLoading(false);
    } catch (error) {
      errorHandle(error);
      setLoading(false);
    }
  };
