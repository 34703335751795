import errorHandle from '../../../utils/errorHandle';
import { CheckTrip } from '../../../api/userAPI';

export default async function fetchTripCheck(
    setCheckTrip,
    type,
    packId
) {
    try {
        const res = await CheckTrip(type, packId);
    } catch (error) {
        errorHandle(error);
    }
}
