import "./tripDetailsSubHeading.css"

export default function TripDetailsSubHeading({ text }) {
  return (
    <div className="detailSubHeading">
      <span className="detailSubHeadingSpan">
        {text}
      </span>
    </div>
  );
}
