export const FormContent = ({ label, answer, className, id }) => (
    <div className={`formContent ${className}`} id={id}>
        <div className="formLabel">{label}</div>
        <div className="formAnsLabel">{answer}</div>
    </div>
);

export const SummaryItem = ({ label, amount, isTotal = false }) => (
    <div
        className={`summaryInnerContent alignCenter ${
            isTotal ? 'borderNone' : ''
        }`}
    >
        <div className="summaryLabel">{label}</div>
        <div
            className="summaryAnsLabel"
            style={isTotal ? { fontSize: '26px' } : {}}
        >
            {amount}
        </div>
    </div>
);
