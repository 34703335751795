import './bookingConfirmationCard.css';

import ReviewStars from '../reviewStars/ReviewStars';

const BookingConfirmationCard = ({
    title,
    imgSrc,
    imgAlt,
    tripName,
    reviews,
    children,
}) => (
    <div className="card leftCard">
        <p className="title font24">{title}</p>
        <div className="innerContainer">
            <img src={imgSrc} alt={imgAlt} />
            <div className="contentArea">
                <p className="tripName">{tripName}</p>
                <div className="tripCardNumInfo">
                    <ReviewStars rating={reviews.rating} />
                    <span>{reviews.rating}</span>
                    <p style={{ margin: 0 }}>({reviews.count} Reviews)</p>
                </div>
            </div>
        </div>
        <div className="formArea">{children}</div>
    </div>
);

export default BookingConfirmationCard;
