import "./ayurBookingTab.css";
import handleChange from "../../utils/handleChange";
import { useState } from "react";

export default function AyurBookingTab({
  ayurBookingTabClick,
  actualPrice,
  offerPrice,
  offer,
  roomSelected,
}) {
  const [bookingData, setBookingData] = useState({
    people: 1,
    date: "",
    offerCode: "",
  });
  const [offerError, setOfferError] = useState("");
  async function handleApply() {
    console.log("Offer");
  }

  return (
    <div className="ayurBookingTab">
      <div className="ayurBookingPriceDiv">
        <p className="ayurBookingActualPrice">Rs. {actualPrice}/person</p>
        <p className="ayurBookingPrice">Rs. {offerPrice}/person</p>
        <p className="ayurBookingTaxInc">Including all taxes</p>
        <p className="ayurBookingOffer">{offer}%OFF</p>
      </div>
      <div className="ayurBookingSubTab">
        <div className="ayurBookDataInput">
          <label className="ayurBookDatalabelFirst">No. of People</label>
          <input
            // placeholder="People"
            type="number"
            value={bookingData.people}
            name="people"
            className="ayurBookInput"
            onChange={(e) => handleChange(e, setBookingData)}
          />
          <label className="tripBookDatalabel2">Offer Code</label>
          <div className="ayurBookOfferDiv">
            {" "}
            <input
              type="text"
              placeholder="Enter promo code"
              name="offerCode"
              value={bookingData.offerCode}
              className="ayurBookOfferIn"
              onChange={(e) => handleChange(e, setBookingData)}
            />
            <span>{offerError}</span>
            <button className="ayurBookOfferBtn" onClick={handleApply}>
              Apply
            </button>
          </div>
        </div>
        <div className="ayurBookPriceDiv">
          {roomSelected.map(function (room, roomIndex) {
            return (
              <div key={roomIndex} className="ayurBookroomDesDiv">
                <p className="ayurBookroomDes">Room type name x 2</p>
                <p className="ayurBookroomPrice">₹2000</p>
              </div>
            );
          })}
          <div className="ayurBookOfferDiv">
            <p className="ayurBookOfferText">Offer</p>
            <p className="ayurBookOfferText">₹{(actualPrice * 20) / 100}</p>
          </div>
          <div className="ayurBookTotalDiv">
            <p className="ayurBookTotalText">Total:</p>
            <p className="ayurBookTotalText">
              ₹{offerPrice * bookingData.people - (actualPrice * 20) / 100}
            </p>
          </div>
        </div>
        <button
          className="ayurBookingButton"
          onClick={() => ayurBookingTabClick(bookingData)}
        >
          Book Now
        </button>
      </div>
    </div>
  );
}
