import { tripOrgInfoFun } from '../../../api/orgTripAPI';

export const FetchOrgAccount = (setAccDetails, setLoading) => {
    setLoading(true);
    try {
        tripOrgInfoFun().then((item) => {
            setAccDetails(item.data);
            setLoading(false);
        });
    } catch (error) {
        setLoading(false);
    }
};
