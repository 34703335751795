import handleChange from "../../utils/handleChange";
import "./dropdown.css";

export default function Dropdown({
  label,
  name,
  options,
  value,
  req,
  setFormData,
  className
}) {
  return (
      <div className={`dropdownDiv ${className}`}>
      <label className="dropdownlabel">{label}</label>
      <select
        value={value}
        name={name}
        onChange={(e) => handleChange(e, setFormData)}
        required={req}
        className="dropdownSelect"
      >
        <option value="-1">Select</option>
        {options.map(function (val, typeIndex) {
          return (
            <option key={typeIndex} value={val}>
              {val}
            </option>
          );
        })}
      </select>
    </div>
  );
}
