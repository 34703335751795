import './titleBar.css';

import titleBannerImage from '../../assets/images/calendar.png';
import titleBannerTile from '../../assets/images/titleBanner.svg';

const TitleBar = ({ title, className = '' }) => {
    return (
        <div className={`titleBanner alignCenter ${className}`}>
            <p className="title font26">{title}</p>
            <img
                className="titleBannerPiece"
                src={titleBannerTile}
                alt="tile"
            />
            <img
                className='titleBannerImage'
                src={titleBannerImage}
                alt="calendar"
            />
        </div>
    );
};

export default TitleBar;
