import { useEffect, useState } from 'react';
import Pagination from '../../components/pagination/Pagination';
import './orgReviews.css';
import ReviewStars from '../../components/reviewStars/ReviewStars';
import { fetchOrgReviews, getTrips } from './orgReviewsFun/orgReviewsFun';
import ReactLoading from 'react-loading';
import EmptyPage from '../../components/empty-page/emptyPage';

const OrgReviews = () => {
    const [reviews, setReviews] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [offSet, setOffSet] = useState(0);
    const [trips, setTrips] = useState([]);
    const [selectedTrip, setSelectedTrip] = useState('');
    const [loading, setLoading] = useState(false);
    const totalData = reviews.length - 1;
    const tableHeader = ['Trip Name', 'User Name', 'Rating', 'Comments'];

    useEffect(() => {
        const fetchBookingData = async () => {
            fetchOrgReviews(setReviews, setLoading, offSet, selectedTrip);
            getTrips(setTrips, setLoading);
        };
        fetchBookingData();
    }, [selectedTrip]);

    const paginationClick = (number, offSetNumber) => {
        if (Math.ceil(totalData / 10) > 1) {
            setPageNumber(+number);
            setOffSet(+offSetNumber);
        }
    };

    const getReviewStars = (rating) => {
        return (
            <div className="org_tripCardNumInfo">
                <ReviewStars rating={rating} />
                <span>{rating}</span>
            </div>
        );
    };

    const getTripDetails = (row) => {
        return (
            <div className="bookingTitleArea">
                <div>{row.packId.title}</div>
                <div>
                    {'2024-10-01'} (
                    {`${row.packId.days}D/${row.packId.nights}N`})
                </div>
            </div>
        );
    };
    return (
        <>
            {loading && (
                <div className="loadingBackground">
                    <ReactLoading
                        type="spin"
                        color="#006aff"
                        className="loadingSpin"
                    />
                </div>
            )}
            <div className="alignCenter orgReviews">
                <h2 className="orgReviewsHeading">Reviews</h2>
                <select
                    name="date"
                    id="date"
                    className="select mr-2"
                    onChange={(e) => setSelectedTrip(e.target.value)}
                >
                    <option value="">Select Trip</option>
                    {trips.map((trip) => (
                        <option value={trip._id} key={trip._id}>
                            {trip.title}
                        </option>
                    ))}
                </select>
            </div>
            <div className="orgReviewsContainer">
                {reviews?.length === 0 && !loading ? (
                    <EmptyPage />
                ) : (
                    <div className="tableContainer">
                        <table className="table">
                            <thead className="tableHead">
                                <tr>
                                    {tableHeader.map((head, headIndex) => {
                                        return <th key={headIndex}>{head}</th>;
                                    })}
                                </tr>
                            </thead>
                            <tbody>
                                {reviews?.map((row, rowIndex) => {
                                    return (
                                        <tr
                                            key={rowIndex}
                                            className="tableBodyRow"
                                        >
                                            <td>{getTripDetails(row)}</td>
                                            <td>{row.userName}</td>
                                            <td>
                                                {getReviewStars(row.rating)}
                                            </td>
                                            <td className="reviewComments">
                                                {row.comment}
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                        <div className="responsiveCardContainer">
                            {reviews?.map((row, rowIndex) => (
                                <div key={rowIndex} className="responsiveCard">
                                    <div className="cardContent">
                                        <p>
                                            <strong>{tableHeader[0]}:</strong>{' '}
                                            {getTripDetails(row)}
                                        </p>
                                        <p>
                                            <strong>{tableHeader[1]}:</strong>{' '}
                                            {row.userName}
                                        </p>
                                        <p className="ratingCon">
                                            <strong>{tableHeader[2]}:</strong>{' '}
                                            {getReviewStars(row.rating)}
                                        </p>
                                        <p>
                                            <strong>{tableHeader[3]}:</strong>{' '}
                                            {row.comment}
                                        </p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                )}
                {reviews.length > 10 && (
                    <Pagination
                        paginationClick={paginationClick}
                        numberOfRows={totalData}
                        offSet={offSet}
                        pageNumber={pageNumber}
                    />
                )}
            </div>
        </>
    );
};

export default OrgReviews;
