import { useState } from "react";
import "./orgRoomUpload.css";
import DirectInput from "../../components/directInput/DirectInput";
import Dropdown from "../../components/dropdown/Dropdown";
import Facilities from "./orgRoomUpSection/facilities/Facilities";

export default function OrgRoomUpload() {
  const [roomData, setRoomData] = useState({
    title: "",
    price: 0,
    roomType: "",
    maxPeople: 0,
    facilities: [],
  });
  // State for options and selected items

  const roomTypeOpt = [
    "Single",
    "Two Sharing",
    "Three Sharing",
    "Four Sharing",
    "Others",
  ];

  return (
    <div>
      <DirectInput
        label="Title"
        name="title"
        value={roomData.title}
        type="text"
        placeholder="Title"
        setFormData={setRoomData}
        min={3}
        limit={40}
      />
      <DirectInput
        label="Price"
        name="price"
        value={roomData.price}
        type="number"
        placeholder="Price per night"
        setFormData={setRoomData}
        min={2}
        limit={8}
      />
      <div>
        <h2>Images</h2>
        <label>Wallpaper Images</label>
        <input type="file" />

        {/* small image upload div */}
        <label>Thumbnail Images</label>
        <input type="file" />
      </div>
      <Dropdown
        label="Room Type"
        name="roomType"
        options={roomTypeOpt}
        value={roomData.roomType}
        req={true}
        setFormData={setRoomData}
      />
      <DirectInput
        label="Max People"
        name="maxPeople"
        value={roomData.maxPeople}
        type="number"
        placeholder="Max number of people in each room"
        setFormData={setRoomData}
        min={1}
        limit={2}
      />
      <Facilities roomData={roomData} setRoomData={setRoomData} />
    </div>
  );
}
