import TitleBar from "../../components/titleBar/titleBar";

export default function Terms() {
  return (
    <>
      <TitleBar title="Terms of Service" className='customTitle' />
      <div className="customContainer privacy">
        <p>Welcome to TrvTo, your go-to platform for booking travel-related services such as holiday packages, guides, vehicles, camping and more. By accessing or using our platform, you agree to be bound by these Terms and Conditions, which form a legally binding agreement between you and TrvTo. Please read them carefully.</p>
        <p>Any individual ("User") who inquiries about or purchases any products or services offered by TrvTo through its website, mobile application, sales representatives, offices, call centers, branches, franchisees, agents, or other channels agrees to be bound by this User Agreement. The website and mobile application of TrvTo are collectively referred to as the 'Website.'</p>
        <b>Scope of Services</b>
        <p>TrvTo acts as an intermediary between users and service providers, such as tour operators, guides, and vehicle rental companies. The platform allows users to explore and book a variety of travel-related services. It is important to note that TrvTo itself does not provide any travel services directly. All services are delivered by independent third-party providers whose terms you must also agree to when making a booking.
        </p>
        <p>TrvTo reserves the right to cancel any services at any time without any prior notice. TrvTo is not liable for the gaps/mistakes/errors etc found in the services.
        </p>
        <p>TrvTo acts solely as an intermediary platform that connects users with various service providers, organizers, and venues for events, activities, and related services. TrvTo does not directly operate, manage, or control the services or events offered through the platform. As such, TrvTo makes no representations or warranties regarding the quality, safety, suitability, reliability, or availability of any service or event listed on its platform. Users understand that any interactions, bookings, or transactions they engage in are strictly between them and the respective service provider or organizer.
        </p>
        <p>TrvTo encourages users to conduct independent research, exercise due diligence, and review all relevant information provided by service providers before making commitments. TrvTo shall not be liable for any loss, dissatisfaction, or damages resulting from the quality, standards, or availability of services provided by third parties listed on the platform. By using the TrvTo platform, users acknowledge that TrvTo is not responsible for any disputes, issues, or liabilities that may arise from services booked or purchased through the platform.</p>

        <b>User Obligations</b>
        <p>By using the platform, you represent that you are at least 18 years old and possess the legal capacity to enter into this agreement. You agree to provide accurate and current information during the booking process. Any discrepancies in the information you provide may result in booking errors, and TrvTo shall not be liable for any consequences arising from inaccurate user information.</p>

        <b>Booking Process</b>
        <p>All bookings made on TrvTo are subject to availability and the terms and conditions of the third-party service providers. Upon booking, users will receive confirmation details, and it is your responsibility to verify all the information provided, including dates, pricing, and itineraries. Any modifications or cancellations will be governed by the policies of the respective service provider. TrvTo does not guarantee the availability of any service or product listed on the platform.
        </p>

        <b>Organizer Responsibilities</b>
        <p>The services provided by organizers, including but not limited to transportation, accommodation, tours, and guides, are the sole responsibility of the organizers. Any issues or disputes related to the quality of service, safety, or itinerary changes should be addressed directly with the respective organizer. TrvTo is not responsible for any acts, errors, or omissions made by the organizers.</p>
        <p>The organizer or venue is responsible for securing all required approvals, permissions, licenses, no-objection certificates, and clearances from relevant governmental or private authorities to lawfully conduct the event or activity and provide the associated services. Should they (organizer or venue or service provider) fail to obtain these necessary authorizations, they will be held liable for any resulting damages and may face punitive actions as applicable.</p>
        <b>Limitation of Liability</b>
        <p>TrvTo provides a platform for booking services but does not guarantee the quality or fulfilment of those services. By using the platform, users agree that any issues arising from organizer actions, service quality, or other aspects of the booking are solely between the user and the organizer.</p>
        <p>While every effort is made to ensure the smooth functioning of the website, TrvTo does not guarantee that the website will operate without interruptions, errors, or technical issues. Users acknowledge that any errors or technical issues that occur during the use of the website are not grounds for claims against TrvTo or its owners.</p>
        <b>Risk and Responsibility</b>
        <p>Users utilize TrvTo and engage with the services offered by organizers at their own discretion and risk. By proceeding with a booking, users understand that TrvTo acts as a facilitator and is not liable for any damages, losses, or inconveniences arising from the use of the platform or the services booked through it.</p>
        <p>The platform and its services are provided on an "as is" basis, and users assume all associated risks when using the site.</p>
        <b>No Warranty</b>
        <p>TrvTo makes no express or implied warranties regarding the availability, accuracy, reliability, or content of the platform. Any reliance on the platform's information or recommendations is made at the user's own risk.
        </p>
        <p>Under no circumstances shall TrvTo be held liable for any direct, indirect, punitive, incidental, special, or consequential damages resulting from below points or any other reason whatsoever:
        </p>
        <ul>
          <li>Illegal or unauthorized or unauthenticated access of any data on the website</li>
          <li>Damages caused by use of our services or any other matter relating to TrvTo services.</li>
        </ul>

        <b>Dispute Resolution</b>
        <p>In the event of disputes between users and organizers, TrvTo may assist in facilitating communication, but the platform is not obligated to mediate or resolve such disputes. Any legal claims related to the services provided by organizers should be made directly against the relevant organizer.
        </p>

        <b>Travel Documents</b>
        <p>Users are solely responsible for carrying valid travel documents, such as passports, visas, identification, and any required permits. TrvTo does not verify or guarantee the accuracy of information provided regarding documentation requirements and is not responsible for any issues arising from inadequate or invalid travel documents. Users must ensure they meet all requirements and obtain any necessary documentation to complete their travel.
        </p>

        <b>Hotel Stays and Camping</b>
        <p>For accommodation options such as hotels, camping sites, and similar stays booked through TrvTo, users are subject to the specific terms and conditions set by the service provider or venue. TrvTo is not liable for the quality, safety, or suitability of the accommodations, nor for any loss, damage, or inconvenience that may arise during the stay. Users should verify all accommodation details, including amenities, safety, and availability, directly with the provider before their stay.
        </p>
        <p>The amount paid by the user for the booking is solely for accommodation at the hotel. Some reservations may include breakfast and/or meals, as specified at the time of booking. Any additional services availed by the user at the hotel, such as laundry, room service, internet access, telephone calls, extra food, drinks, and beverages, must be paid directly to the hotel by the user.</p>
        <b>Payments</b>
        <p>TrvTo utilizes third-party payment services for processing transactions, and users acknowledge that TrvTo has no control over these services. TrvTo is not responsible for any issues, delays, or discrepancies arising from payments, refunds, chargebacks, or related matters handled by these third-party providers. Users agree to resolve any payment-related concerns directly with the payment provider and understand that TrvTo bears no liability for any losses, errors, or unauthorized transactions that may occur.</p>
        <p>By using TrvTo's services, users agree to pay all applicable fees, taxes, and charges associated with their bookings. Payment must be made through approved payment methods provided on the TrvTo platform, and users are responsible for ensuring the accuracy of payment details, including bank account or card information. TrvTo reserves the right to add, modify, or discontinue payment methods at any time without prior notice.</p>
        <p>All payments are subject to applicable taxes, fees, or charges as determined by government regulations. These taxes, where applicable, will be added to the total amount payable and may vary based on the user's location and the nature of the service. Users are responsible for reviewing the tax amounts before confirming their bookings.</p>
        <p>Refunds for canceled bookings or services are subject to the cancellation and refund policy associated with each specific service. TrvTo will only process refunds that have been authorized according to these policies and is not responsible for any additional costs, delays, or transaction fees that may be incurred in the refund process. Refunds will generally be issued to the original payment method, but TrvTo may offer alternate refund methods in certain cases.</p>
        <p>Users must ensure that all payment details provided are accurate and current, as TrvTo is not liable for payments or refunds that fail due to incorrect or outdated information. Users acknowledge and agree that any errors in bank or account information are their responsibility and may result in delays, fees, or cancellation of services.</p>
        <p>TrvTo reserves the right to impose additional fees or surcharges for certain services, activities, or features available on the platform. Any such charges will be clearly communicated to the user prior to confirmation of booking.
        </p>

        <b>Third-Party Links</b>
        <p>The TrvTo platform may contain links to third-party websites or resources for user convenience. TrvTo does not endorse, control, or guarantee the content, services, or accuracy of information provided on these external sites. Accessing or using third-party links is at the user's own risk, and TrvTo assumes no responsibility for any issues, loss, or damages arising from interactions with these third-party websites.</p>
        <b>Termination</b>
        <p>TrvTo reserves the right to suspend or terminate any organizer's or user's account, access, or use of the platform at its sole discretion, with or without notice, if it determines a breach of these terms or any applicable policies. Grounds for termination may include, but are not limited to, violations of TrvTo's guidelines, illegal activity, misuse of the platform, fraudulent behavior, or actions that may harm TrvTo's reputation or the safety of other users.</p>
        <p>In the event of termination, organizers or users may lose access to their accounts, bookings, or any stored data associated with the platform. TrvTo is not liable for any losses or inconvenience resulting from the termination of accounts.</p>
        <b>Offers and Promotions</b>
        <p>TrvTo may, at its sole discretion, provide special offers, discounts, or promotions on selected services, activities, or accommodations listed on the platform. All offers are subject to specific terms and conditions, including but not limited to validity periods, applicable destinations or services, eligibility criteria, and usage limits.</p>
        <p>Unless explicitly stated otherwise, offers cannot be combined with other discounts or promotions and are non-transferable. Any attempt to misuse or manipulate the offer terms may result in the revocation of the offer and the potential suspension of the user's account. TrvTo reserves the right to modify, extend, or terminate any offers at any time without prior notice.
        </p>
        <p>Users are responsible for reviewing the specific terms associated with each offer before making a booking, and they acknowledge that TrvTo is not liable for any discrepancies or misunderstandings related to the application of the offer.
        </p>

        <b>Advertisements</b>
        <p>TrvTo allows organizers, vendors, and other third parties to post advertisements and promotional content on its website. TrvTo does not endorse, verify, or assume any responsibility for the accuracy, quality, or legitimacy of these ads, products, services, or offers. Users are encouraged to independently verify all information provided in such advertisements before engaging with the advertiser or making any financial commitments. TrvTo shall not be liable for any loss, damage, or issues arising from interactions or transactions with advertisers or their representatives. Any actions taken by users in response to advertisements are done at their own risk.</p>

        <b>Changes to the Terms</b>
        <p>TrvTo reserves the right to modify these terms and conditions at any time without prior notice. Users are encouraged to review the terms regularly. Continued use of the platform after any changes indicates acceptance of the updated terms.</p>

        <b>Data</b>
        <p>All content provided through various sales channels, including but not limited to audio, images, software, text, icons, and similar materials ("Content"), is owned by TrvTo and protected by applicable intellectual property laws. TrvTo does not grant users any license, rights, or permission to use these data in any form. Any use of data will be considered as breaking of the law.</p>
        <p> TrvTo assumes no responsibility for the accuracy of information presented in digital formats such as content, photos, infographics, videos, or GIFs on the website/app related to any activities or events listed on the platform.</p>

        <b>Governing Law and Jurisdiction</b>
        <p>These terms and conditions are governed by and construed in accordance with the laws of India. Any disputes arising under these terms shall be subject to the exclusive jurisdiction of the courts located in Gangavathi/Bengaluru/Bangalore, India.</p>

        <b>Communication</b>
        <p>By using TrvTo's platform, users consent to receive communications from TrvTo via SMS, email, or other forms as necessary for service updates, booking confirmations, promotions, and other relevant information. Users are responsible for ensuring that contact details are accurate and up-to-date to receive timely notifications. TrvTo is not liable for any losses or issues arising from missed communications due to incorrect or outdated contact information. Users may opt out of promotional messages at any time, though service-related messages will continue as required.</p>

        <b>Overseas Packages</b>
        <p>TrvTo offers international travel packages and services. Users are advised to review all specific terms, conditions, and requirements associated with each overseas package, including payment terms, refund policies, and any travel restrictions applicable to the destination. TrvTo is not responsible for changes in local laws, entry restrictions, or additional fees imposed by foreign authorities.</p>

        <b>Obligation to Obtain Visa</b>
        <p>Users are solely responsible for obtaining valid travel visas, permits, and other necessary travel documents required for their destinations. TrvTo does not assist in or guarantee visa approvals, nor does it assume responsibility for any costs, delays, or disruptions caused by incomplete or invalid travel documentation. Users must ensure they meet all visa and travel requirements for their travel dates and destination.</p>

        <b>Force Majeure</b>
        <p>TrvTo shall not be liable for any failure to provide services due to events outside its control, including but not limited to natural disasters, government actions, strikes, pandemics, or other unforeseen circumstances ("force majeure"). In such cases, TrvTo may, at its discretion, reschedule, modify, or cancel affected bookings without liability for any resulting losses or damages. Users are encouraged to check individual service policies regarding refunds or rescheduling in force majeure situations.</p>

        <b>Right to Refuse Service</b>
        <p>TrvTo reserves the right to refuse to provide services to any user if it deems necessary, based on user behavior, suspected fraud, misuse of the platform, or any actions that compromise the safety, integrity, or reputation of TrvTo or other users. Such refusal may be made without prior notice and at TrvTo's sole discretion.</p>

        <b>Right to Cancel Services</b>
        <p>TrvTo retains the right to cancel or modify any services, bookings, or offers due to unforeseen circumstances, lack of availability, legal restrictions, or at its discretion. In the event of cancellation by TrvTo, users may be eligible for a refund per the applicable refund policy, but TrvTo is not liable for any other costs or inconveniences caused by the cancellation.</p>

        <b>Content Moderation</b>
        <p>TrvTo reserves the right to review, moderate, and, if necessary, remove any content posted on its platform by users, organizers, or third parties that violates the platform's policies, including inappropriate, offensive, or misleading content. TrvTo is not responsible for monitoring all content but will act on any content reported as inappropriate or in violation of applicable laws or policies. Users are encouraged to report any content that appears to breach TrvTo's guidelines.</p>
      </div>
    </>
  );
}