export const handleFilterChange = (data, setFilteredData) => (selectedFilters) => {
  
    let filteredResults = data;
  
    // Filter by checkbox selections
    Object.keys(selectedFilters).forEach((filterType) => {
      if (filterType === "priceRange") return;
      filteredResults = filteredResults.filter((item) =>
        selectedFilters[filterType].length === 0 ||
        selectedFilters[filterType].includes(item.quickView.where.charAt(0).toUpperCase() + item.quickView.where.slice(1))
      );
    });
  
    // Filter by range
    if (selectedFilters.priceRange) {
      filteredResults = filteredResults.filter((item) =>
          item.price[0].price >= selectedFilters.priceRange[0] &&
          item.price[0].price <= selectedFilters.priceRange[1]
      );
    }
  
    setFilteredData(filteredResults);
  };