import { useState } from "react";
import "./faq.css"

export default function FAQ({ data }) {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div>
      {data?.map((faqdata, faqIndex) => (
        <div key={faqIndex} className="faqItem">
          <div className="faqQuestion alignCenter" onClick={() => toggleAccordion(faqIndex)}>
            <span>
              {faqdata.question}
            </span>
            <span className="icon">
              {activeIndex === faqIndex ? "-" : "+"}
            </span>
          </div>
          <div className={`faqAnswer ${activeIndex === faqIndex ? "active" : ""}`}>
            {activeIndex === faqIndex && <p>{faqdata.answer}</p>}
          </div>
        </div>
      ))}
    </div>
  );
}
