// import { userBookingFun } from "../../../api/userAPI";
import { userBookingFun } from "../../../api/userAPI";
import errorHandle from "../../../utils/errorHandle";

export default async function myBookingData(
  typeSel,
  setBookingData,
  setLoading
) {
  //API call for fetching booking data of a particular user
  setLoading(true);
  try {
    const booking = await userBookingFun(typeSel);
    
    setBookingData(booking.data);
    setLoading(false);
  } catch (error) {
    errorHandle(error);
    setLoading(false);
  }
}
