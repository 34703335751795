import './App.css';
import { Route, Routes, Navigate } from 'react-router-dom';
import LoadingPage from './pages/loadingPage/LoadingPage';
import Load from './pages/loadingPage/Load';
import UserLayout from './layout/UserLayout';
import OrgLayout from './layout/OrgLayout';
import SupportLayout from './layout/SupportLayout';
import { OrgAuthProvider } from './context/OrgAuthContext';
import { SupAuthProvider } from './context/SupAuthContext';
import { ToastContainer } from 'react-toastify';
// import ResetPassword from "./components/login/resetPassword/ResetPassword";

function App() {
    return (
        // <Router>
        <>
            {/* <OrgAuthProvider> */}
            <Routes>
                <Route path="/*" element={<UserLayout />} />
                {/* <Route
          path="/reset-password/:type/:token"
          element={<ResetPassword />}
        /> */}
                <Route path="*" element={<Navigate to="/" />} />
                <Route
                    path="/org/*"
                    element={
                        <OrgAuthProvider>
                            <OrgLayout />
                        </OrgAuthProvider>
                    }
                />
                <Route
                    path="/support/*"
                    element={
                        <SupAuthProvider>
                            <SupportLayout />
                        </SupAuthProvider>
                    }
                />
                <Route path="/loading" element={<LoadingPage />} />
                <Route path="/load" element={<Load />} />
            </Routes>
            {/* </OrgAuthProvider> */}
            <p className="developer">Developed by Sagar Bazar</p>
            <ToastContainer
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </>
        // </Router>
    );
}

export default App;
