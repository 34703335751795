import { useContext, useState } from 'react';
import handleChange from '../../../utils/handleChange';
import './login.css';
import { genOTPFun, loginFun } from '../../../api/userLoginAPI';
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import { UserAuthContext } from '../../../context/UserAuthContext';
import ReactLoading from 'react-loading';
import { toast } from 'react-toastify';
import errorHandle from '../../../utils/errorHandle';
import OTPInput from '../../otpinput/otpinput';
import phoneValid from '../../../utils/phoneValid';

function Login({ setLogButton, setLoginSec }) {
    const [response, setResponse] = useState('');
    const [userData, setUserData] = useState({
        phoneNumber: '',
        otp: ''
    });
    const [loading, setLoading] = useState(false);
    const [phoneError, setPhoneError] = useState('');
    const [otpError, setOtpError] = useState('');

    const { login } = useContext(UserAuthContext);

    console.log(userData);

    async function getOTP() {
        if (!phoneValid(userData.phoneNumber)) {
            setPhoneError('Please enter a valid phone number');
            return;
        }
        setPhoneError('');
        setLoading(true);
        try {
            const getOTP = await genOTPFun({
                phoneNumber: userData.phoneNumber
            });
            console.log(getOTP);
            //End Loadin
            setLoading(false);
            //Set response to 200 telling OTP is sent
            setResponse('200');
            //Notifaction of OTP sent
            toast.success('OTP sent to your phone number');
        } catch (error) {
            errorHandle(error);
            //End loading animation
            setLoading(false);
        }
    }

    //check for valid email id and
    //send login information to backend for verification
    async function handleLogin() {
        if (userData.otp.length <= 5) {
            setOtpError('Please enter the complete OTP');
            console.log('Please enter the complete OTP');
            return;
        }
        setOtpError('');
        setLoading(true);

        try {
            const loginData = await loginFun(userData);
            console.log(loginData);
            //Show notifation of Login success
            toast.success('Login Successful');
            setLogButton(false);
            //End loading animation
            setLoading(false);
            login(loginData.data);
        } catch (error) {
            errorHandle(error);
            //End loading animation
            setLoading(false);
        }
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            response !== '200' ? getOTP() : handleLogin();
        }
    };

    return (
        <div className="login">
            {loading && (
                <div className="loadingBackgroundCustom">
                    <ReactLoading
                        type="spin"
                        color="#006aff"
                        className="loadingSpin"
                    />
                </div>
            )}
            <h1 className="loginH1">
                {response !== '200' ? 'Login' : 'OTP Verification'}
            </h1>
            <button
                type="button"
                onClick={() => setLogButton(false)}
                className="loginCancel"
            >
                <CloseIcon className="loginCloseIcon" />
            </button>
            {response !== '200' && (
                <div className="loginInputDiv">
                    <label>Phone Number</label>
                    <input
                        placeholder="Number"
                        type="text"
                        name="phoneNumber"
                        onChange={(e) => handleChange(e, setUserData)}
                        onKeyDown={handleKeyDown}
                        value={userData.phoneNumber}
                        className="regInput"
                    />
                    {phoneError && (
                        <span className="orgLoginError">{phoneError}</span>
                    )}
                    <div className="loginForgotReg">
                        {/* <h2
                            onClick={() => setLoginSec('forgot')}
                            className="loginForgot"
                        >
                            Forgot Password
                        </h2>
                        <p className="loginForgotP">/</p> */}
                        <h2
                            onClick={() => setLoginSec('register')}
                            className="loginForgot"
                        >
                            Sign Up
                        </h2>
                    </div>
                </div>
            )}
            {response === '200' && (
                <div className="loginInputDiv">
                    <p>Check text messages for your OTP</p>
                    <label>OTP</label>
                    <OTPInput
                        length={6}
                        setUserData={setUserData}
                        keyName={'otp'}
                        handleKeyDown={handleKeyDown}
                    />
                </div>
            )}
            {response === '200' && otpError && (
                <span className="orgLoginError">{otpError}</span>
            )}

            <button
                type="button"
                onClick={response !== '200' ? getOTP : handleLogin}
                className="loginBtn"
            >
                {response !== '200' ? 'Send One Time Password' : 'Login'}
            </button>
        </div>
    );
}

export default Login;
