import Axios from 'axios';
import { BASE_URL } from '../config/api';

//The below line is to make sure that you can use two interceptors in the same project
//Without this the API base URL will be taken only from one of the interceptor
// const Axios = Axios.create();
// Add a request interceptor

Axios.interceptors.request.use(
    function (config) {
        const token = localStorage.getItem('supToken');
        // Do something before request is sent
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        config.baseURL = BASE_URL;
        return config;
    },
    function (error) {
        // Do something with request error
        console.log('error', error.message);
        // toast.error(error.message);
        return Promise.reject(error);
    }
);

// Add a response interceptor
Axios.interceptors.response.use(
    function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response;
    },
    function (error) {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        if (error.response.data) {
            console.log('error', error.response.data.message);
            // console.log(error);
            // toast.error(error.response.data.message);
        }
        return Promise.reject(error);
    }
);

const supHttps = {
    get: Axios.get,
    post: Axios.post,
    put: Axios.put,
    delete: Axios.delete,
    patch: Axios.patch
};

export default supHttps;
