import "./contactUs.css";
import { ReactComponent as Contact } from "../../assets/images/contact.svg";
import { ReactComponent as Phone } from "../../assets/icons/phone.svg";
import { ReactComponent as Email } from "../../assets/icons/email.svg";
import { ReactComponent as Address } from "../../assets/icons/address.svg";
import TitleBar from "../../components/titleBar/titleBar";
import handleChange from "../../utils/handleChange";
import { useState } from "react";
import emailValid from "../../utils/emailValid";
import { toast } from "react-toastify";
import phoneValid from "../../utils/phoneValid";
import { contactUs } from "../../api/userAPI";

export default function ContactUs() {
  const [contactData, setContactData] = useState({
    name: '',
    emailId: '',
    phoneNumber: '',
    message: ''
  });

  const onSubmit = async (e) => {
    e.preventDefault();
    if (!emailValid(contactData.emailId)) {
      toast.error('Please enter a valid email address');
      return;
    } else if (!phoneValid(contactData.phoneNumber)) {
      toast.error('Please enter a valid phone number');
      return;
    } else if (!contactData.name || !contactData.message) {
      toast.error('Please fill all the fields');
      return;
    } else {
      await contactUs(contactData);
    }
  };

  return (
    <>
      <TitleBar title="Contact us" className='customTitle' />
      <div className="customContainer">
        <div className="contact-container">
          <div className="imgCon">
            <Contact className="conatct-img" />
          </div>
          <div className="contact-details">
            <div className="details-item">
              <div className="icon-con"><Phone /></div><a href="tel:9740665900">9740665900</a>
            </div>
            <div className="details-item">
              <div className="icon-con"><Email /></div> <a href="mailto:mr.sky.kj@gmail.com">mr.sky.kj@gmail.com</a>
            </div>
            <div className="details-item">
              <div className="icon-con"><Address /></div> <span>Lorem Ipsum is simply dummy text of the printing
                and typesetting industry. Lorem Ipsum has been the industry's standard
                dummy text ever since the 1500s</span>
            </div>
          </div>
        </div>
        <b className="hr"></b>
        <div className="conatact-form">
          <div className="cardCon">
            <form className="contact-card" onSubmit={(e) => onSubmit(e)}>
              <div className="regInputDiv">
                <label>Name</label>
                <input
                  placeholder="Enter name"
                  name="name"
                  className="regInput"
                  onChange={(e) => handleChange(e, setContactData)}
                />
              </div>
              <div className="regInputDiv">
                <label>Email</label>
                <input
                  placeholder="Enter email"
                  name="emailId"
                  className="regInput"
                  onChange={(e) => handleChange(e, setContactData)}
                />
              </div>
              <div className="regInputDiv">
                <label>Phone Number</label>
                <input
                  placeholder="Enter phone number"
                  name="phoneNumber"
                  className="regInput"
                  onChange={(e) => handleChange(e, setContactData)}
                />
              </div>
              <div className="regInputDiv">
                <label>Questions / Comments</label>
                <textarea
                  style={{ resize: 'none', minHeight: '80px' }}
                  placeholder="Please enter details"
                  name="message"
                  className="regInput"
                  onChange={(e) => handleChange(e, setContactData)}
                />
              </div>
              <div className="regInputDiv">
                <button>Submit</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
