import ReviewStars from "../reviewStars/ReviewStars";

const ReviewCard = ({ data }) => {
    return (
        <>
            {data.map((item, index) => (
                <div key={index} className="review-carousel-item">
                    <img src={item.image} alt={item.title} />
                    <div className="mobCenter">
                        <p className="reviewDetail">{item.description}</p>
                        <ReviewStars rating={item.rating} />
                        <p className="author">{item.author}</p>
                        <p className="designation">{item.designation}</p>
                    </div>
                </div>
            ))}
        </>
    );
};

export default ReviewCard;
