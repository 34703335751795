import { useRef, useState } from 'react';
import DirectInput from '../../../components/directInput/DirectInput';
import DropdownReg from '../../../components/dropdownReg/DropdownReg';
import './addBooking.css';
import addBoStartCity from '../orgTripDateFun/addBoStartCity';
import CharCount from '../../../components/charCount/CharCount';
import addBoNumSlots from '../orgTripDateFun/addBoNumSlots';
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import addBoMuBook from '../orgTripDateFun/addBoMuBook';

export default function AddBooking({ packData, setAddBo }) {
    const [muBookData, setMuBookData] = useState({
        totalSeats: 1,
        startCity: '',
        pickupLocation: '',
        userDetails: [{}, {}],
        manualUser: {},
        transport: '',
        bookedVia: 'manual',
        status: 'paid',
        bookedTrip: '',
        startDate: '',
        endDate: ''
    });
    const startCity = useRef(
        packData.price.map(function (item) {
            return item.startCity;
        })
    );
    const [index, setIndex] = useState(0);

    const slotsOptions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
    const trnOptions = ['With Transport', 'Without Transport'];

    function handleUser(e, index) {
        // setUserDetails((prevState) => {
        //   return prevState.map((obj, objIndex) => {
        //     return objIndex === index
        //       ? { ...obj, [e.target.name]: e.target.value }
        //       : obj;
        //   });
        // });
    }

    return (
        <div className="orgAddBoBack">
            <div className="orgAddBoDiv">
                <h3 className="orgAddBoH1">Add New Booking</h3>
                <button
                    onClick={() => setAddBo(false)}
                    className="orgAddBoCancel"
                >
                    <CloseIcon className="orgAddBoCloseIcon" />
                </button>
                <div className="orgAddBoSubDiv">
                    <div className="dropRegDiv">
                        <label className="dropRegLabel">Start City</label>
                        <div className="dropRegInputDiv">
                            <select
                                value={muBookData.startCity}
                                name="startCity"
                                onChange={(e) =>
                                    addBoStartCity(
                                        e,
                                        setMuBookData,
                                        startCity.current,
                                        setIndex
                                    )
                                }
                                required={true}
                                className="dropRegSelect"
                            >
                                <option value="-1">Select</option>
                                {startCity.current.map(function (
                                    val,
                                    typeIndex
                                ) {
                                    return (
                                        <option key={typeIndex} value={val}>
                                            {val}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>
                    </div>
                    <DropdownReg
                        label="Pickup Location"
                        name="pickupLocation"
                        options={packData.price[index].pickupLocation}
                        value={muBookData.pickupLocation}
                        req={true}
                        setFormData={setMuBookData}
                    />
                    <DropdownReg
                        label="Transport"
                        name="transport"
                        options={trnOptions}
                        value={muBookData.transport}
                        req={true}
                        setFormData={setMuBookData}
                    />
                    <div className="dropRegDiv">
                        <label className="dropRegLabel">No. of Slots</label>
                        <div className="dropRegInputDiv">
                            <select
                                value={muBookData.totalSeats}
                                name="totalSeats"
                                onChange={(e) =>
                                    addBoNumSlots(e, setMuBookData, muBookData)
                                }
                                required={true}
                                className="dropRegSelect"
                            >
                                <option value="-1">Select</option>
                                {slotsOptions.map(function (val, typeIndex) {
                                    return (
                                        <option key={typeIndex} value={val}>
                                            {val}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>
                    </div>
                    <div className="orgAddBoUserDiv">
                        {Array.apply(null, {
                            length: muBookData.totalSeats
                        }).map(function (_, peopleIndex) {
                            return (
                                <div
                                    key={peopleIndex}
                                    className="reviewBoInfoUserDiv"
                                >
                                    <div className="reviewBoInfoNameAge">
                                        <div className="reviewBoInfoName">
                                            <label className="reviewBoInfoLabel">
                                                Name
                                            </label>
                                            <input
                                                value={
                                                    muBookData.userDetails[
                                                        peopleIndex
                                                    ]
                                                        ? muBookData
                                                              .userDetails[
                                                              peopleIndex
                                                          ].name
                                                        : ''
                                                }
                                                name="name"
                                                onChange={(e) =>
                                                    handleUser(e, peopleIndex)
                                                }
                                                type="text"
                                                placeholder={`Person ${
                                                    peopleIndex + 1
                                                } name`}
                                                className="reviewBoInfoInput"
                                            ></input>
                                            <CharCount
                                                string={
                                                    muBookData.userDetails[
                                                        peopleIndex
                                                    ].name
                                                        ? muBookData
                                                              .userDetails[
                                                              peopleIndex
                                                          ].name
                                                        : ''
                                                }
                                                min={3}
                                                limit={30}
                                            />
                                        </div>
                                        <div className="reviewBoInfoName">
                                            <label className="reviewBoInfoLabel">
                                                Age
                                            </label>
                                            <input
                                                value={
                                                    muBookData.userDetails[
                                                        peopleIndex
                                                    ]?.age
                                                }
                                                name="age"
                                                onChange={(e) =>
                                                    handleUser(e, peopleIndex)
                                                }
                                                type="number"
                                                placeholder="Person's age"
                                                className="reviewBoInfoInput"
                                            ></input>
                                        </div>
                                    </div>
                                    <div className="reviewBoInfoNameAge">
                                        <div className="reviewBoInfoName">
                                            <label className="reviewBoInfoLabel">
                                                Sex
                                            </label>
                                            <select
                                                value={
                                                    muBookData.userDetails[
                                                        peopleIndex
                                                    ]?.sex
                                                }
                                                name="sex"
                                                onChange={(e) =>
                                                    handleUser(e, peopleIndex)
                                                }
                                                className="reviewBoInfoInput"
                                            >
                                                <option value="Select">
                                                    Select
                                                </option>
                                                <option value="Male">
                                                    Male
                                                </option>
                                                <option value="Female">
                                                    Female
                                                </option>
                                                <option value="Others">
                                                    Others
                                                </option>
                                            </select>
                                        </div>
                                        <div className="reviewBoInfoName">
                                            <label className="reviewBoInfoLabel">
                                                Email
                                            </label>
                                            <input
                                                value={
                                                    muBookData.userDetails[
                                                        peopleIndex
                                                    ]?.emailId
                                                }
                                                name="emailId"
                                                onChange={(e) =>
                                                    handleUser(e, peopleIndex)
                                                }
                                                type="text"
                                                placeholder={
                                                    'Email Id of person ' +
                                                    (peopleIndex + 1)
                                                }
                                                className="reviewBoInfoInput"
                                            ></input>
                                        </div>
                                    </div>
                                    <div className="reviewBoInfoNameAge">
                                        <div className="reviewBoInfoName">
                                            <label className="reviewBoInfoLabel">
                                                Country code
                                            </label>
                                            <select
                                                value={
                                                    muBookData.userDetails[
                                                        peopleIndex
                                                    ]?.countryCode
                                                }
                                                name="countryCode"
                                                onChange={(e) =>
                                                    handleUser(e, peopleIndex)
                                                }
                                                className="reviewBoInfoInput"
                                            >
                                                <option value="+91">+91</option>
                                            </select>
                                        </div>
                                        <div className="reviewBoInfoName">
                                            <label className="reviewBoInfoLabel">
                                                Phone Number
                                            </label>
                                            <input
                                                value={
                                                    muBookData.userDetails[
                                                        peopleIndex
                                                    ]?.phoneNumber
                                                }
                                                name="phoneNumber"
                                                onChange={(e) =>
                                                    handleUser(e, peopleIndex)
                                                }
                                                type="text"
                                                placeholder="Mobile Number"
                                                className="reviewBoInfoInput"
                                            ></input>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    <button
                        type="button"
                        name="ManualBookingSubmit"
                        className=" orgAddBoSubmit blueBtn"
                        onClick={addBoMuBook(muBookData, setAddBo)}
                    >
                        Submit
                    </button>
                </div>
            </div>
        </div>
    );
}
