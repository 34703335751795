import orgHttps from '../init/orgHttps';

//ORG-LOGIN-01 : Organizer Login
export async function orgLoginFunAPI(type, data) {
    const loginData = await orgHttps.post(`/org/login/${type}`, data);
    return loginData;
}

//ORG-LOGIN-02 : get opt to phone to login
export async function orgGetOTPFun(type, data) {
    const forData = await orgHttps.post(`/org/login/otp/${type}`, data);
    return forData;
}
