import { useState } from 'react';
import './price.css';
import NestedTags from './nestedTags/NestedTags';
import DirectInput from '../../../../components/directInput/DirectInput';
import CharCount from '../../../../components/charCount/CharCount';

export default function Price({ packData, setPackData }) {
    const [priceCount, setPriceCount] = useState(1);

    function handlePrice(e, index) {
        setPackData((prevState) => {
            const updatedArrya = prevState.price.map(function (item, itemKey) {
                if (index === itemKey) {
                    return { ...item, [e.target.name]: e.target.value };
                }
                return item;
            });
            return {
                ...prevState,
                price: updatedArrya
            };
        });
    }

    function addPrice() {
        setPackData(function (prevState) {
            return {
                ...prevState,
                price: [
                    ...prevState.price,
                    { price: 0, stratCity: '', pickupLocation: [] }
                ]
            };
        });
        setPriceCount(priceCount + 1);
    }

    function deletePrice() {
        if (priceCount > 1) {
            setPackData(function (prevState) {
                const updatedArrya = prevState.price;
                updatedArrya.pop(); //pop should used here only, if use pop in the above line, it returns
                //the removed item which is not the expected outcome
                return {
                    ...prevState,
                    price: [...updatedArrya]
                };
            });
            setPriceCount(priceCount - 1);
        }
    }

    return (
        <div>
            <h2 className="orgPackUpH2">Price</h2>
            <div className="orgPackUpSecDiv">
                {Array.apply(null, { length: priceCount }).map(function (
                    _,
                    itnKey
                ) {
                    return (
                        <div key={itnKey}>
                            <p className="itineraryLabel">City {itnKey + 1}</p>
                            <div className="itineraryInDiv">
                                <label className="itineraryLabel">Price</label>
                                <div className="itineraryInputDiv">
                                    <input
                                        value={
                                            packData.price[itnKey]
                                                ? packData.price[itnKey].price
                                                : 0
                                        }
                                        type="number"
                                        name="price"
                                        onChange={(e) => handlePrice(e, itnKey)}
                                        placeholder={`Price for city ${
                                            itnKey + 1
                                        } `}
                                        className="itineraryInput"
                                    ></input>
                                    <CharCount
                                        string={
                                            packData.price[itnKey]
                                                ? packData.price[itnKey].price
                                                : 0
                                        }
                                        min={2}
                                        limit={7}
                                    />
                                </div>
                            </div>
                            <div className="itineraryInDiv">
                                <label className="itineraryLabel">
                                    Start City
                                </label>
                                <div className="itineraryInputDiv">
                                    <input
                                        value={
                                            packData.price[itnKey]
                                                ? packData.price[itnKey]
                                                      .stratCity
                                                : ''
                                        }
                                        name="stratCity"
                                        onChange={(e) => handlePrice(e, itnKey)}
                                        placeholder={`Start city ${itnKey + 1}`}
                                        className="itineraryInput"
                                    ></input>
                                    <CharCount
                                        string={
                                            packData.price[itnKey]
                                                ? packData.price[itnKey]
                                                      .stratCity
                                                : ''
                                        }
                                        min={2}
                                        limit={30}
                                    />
                                </div>
                            </div>
                            <NestedTags
                                packData={packData}
                                setPackData={setPackData}
                                index={itnKey}
                            />
                        </div>
                    );
                })}
                <button
                    type="button"
                    onClick={addPrice}
                    className="itineraryAddBtn blueBtn"
                >
                    Add City
                </button>
                <button
                    type="button"
                    onClick={deletePrice}
                    className="itineraryDelBtn"
                    disabled={priceCount === 1 ? true : false}
                >
                    Delete City
                </button>
                <DirectInput
                    label="Without Transport Price"
                    name="withoutTransportPrice"
                    value={packData.withoutTransportPrice}
                    type="number"
                    placeholder="Without Transport Price"
                    setFormData={setPackData}
                    min={1}
                    limit={6}
                />
            </div>
        </div>
    );
}
