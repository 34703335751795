// import tripDetailsData from "../../../dummyData/tripDetailsData.json";
// import tripDateDetails from "../../../dummyData/orgDates.json";
import errorHandle from '../../../utils/errorHandle';
import { tripOrgDateFun, orgTripDetailsFun } from '../../../api/orgTripAPI';

export default async function fetchOrgTripData(
    setLoading,
    setPackData,
    setDates
) {
    setLoading(true);
    const packId = window.location.pathname.split('/')[4];
    try {
        // for getDateDetailsFun you get an array of objects with tripDate id, start and end date of each
        const allData = await Promise.all([
            await orgTripDetailsFun(packId),
            await tripOrgDateFun(packId, 'next')
        ]);
        setPackData(allData[0].data);
        setDates(allData[1].data);
        // setPackData(tripDetailsData.data);
        // setDates(tripDateDetails.data);
        setLoading(false);
    } catch (error) {
        setLoading(false);
        errorHandle(error);
    }
}
