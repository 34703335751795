import { Navigate, Route, Routes } from 'react-router-dom';
import SupportAssistance from '../pages/supportAssistance/SupportAssistance';
import SupHeader from '../webPageSections/supPageSection/supHeader/SupHeader';
import Footer from '../webPageSections/footer/Footer';
import BlogUpload from '../pages/blogUpload/BlogUpload';
import { useContext, useEffect } from 'react';
import { SupAuthContext } from '../context/SupAuthContext';
import SupLoginPage from '../pages/supLoginPage/SupLoginPage';
import SupAccount from '../pages/supAccount/SupAccount';
// import { toast } from 'react-toastify';
import SupLeftMenu from '../components/supLeftMenu/supLeftMenu';
import SupTickets from '../pages/supTickets/supTickets';
import SupTicketsDetails from '../pages/supTickets/details/supTicketsDetails';
import AddTickets from '../pages/supTickets/addTicket/addTickets';
import BlogList from '../pages/blog/BlogList';

// let supi = 0;
// let supTimeStampExpire;
// let supExpiryTime;

export default function SupportLayout() {
    const { isSupAuthenticated } = useContext(SupAuthContext);

    // supTimeStampExpire = window.localStorage.getItem('supExpiry');
    // if (window.location.pathname !== '/sup/login' && supi === 0) {
    //     supExpiryTime = supTimeStampExpire - Date.now();
    //     console.log(supExpiryTime, supi);
    //     supi = 1;
    //     if (supExpiryTime < 0) {
    //         toast.error('Session has expired. Login again to continue...');
    //         setTimeout(() => {
    //             window.localStorage.clear();
    //             window.location = '/sup/login';
    //         }, 3000);
    //     } else {
    //         setTimeout(() => {
    //             toast.error('Session has expired. Login again to continue...');
    //             setTimeout(() => {
    //                 window.localStorage.clear();
    //                 window.location = '/sup/login';
    //             }, 3000);
    //         }, supExpiryTime);
    //     }
    // }
    return (
        <>
            {isSupAuthenticated ? (
                <>
                    <SupHeader />
                    <div className="org-main-container">
                        <SupLeftMenu />
                        <main className="org-content">
                            <Routes>
                                {/* <Route
                                    path="/login"
                                    element={<SupLoginPage />}
                                /> */}
                                <Route
                                    path="/*"
                                    element={<Navigate to="/support/login" />}
                                />
                                <Route
                                    path="/assistance"
                                    element={<SupportAssistance />}
                                />
                                <Route
                                    path="/tickets"
                                    element={<SupTickets />}
                                />
                                <Route
                                    path="/addTickets"
                                    element={<AddTickets />}
                                />
                                <Route
                                    path="/tickets/:id"
                                    element={<SupTicketsDetails />}
                                />
                                <Route
                                    path="/account"
                                    element={<SupAccount />}
                                />
                                <Route
                                    path="/blogs"
                                    element={<BlogList />}
                                />
                                <Route
                                    path="/blog/upload"
                                    element={<BlogUpload />}
                                />
                            </Routes>
                        </main>
                        <Footer />
                    </div>
                </>
            ) : (
                <Routes>
                    <Route path="/login" element={<SupLoginPage />} />
                    <Route path="/*" element={<Navigate to="/login" />} />
                </Routes>
            )}
        </>
    );
}
