import { useState } from "react";

export default function Facilities({ roomData, setRoomData }) {
  const initialOptions = [
    "Air-Conditioning",
    "Activities",
    "Balcony",
    "Breakfast",
    "Card Payments",
    "Charging Points",
    "Common Hangout Area",
    "Hot Water",
    "Laundry",
    "Lockers",
    "Restaurant",
    "Television",
    "Towels",
    "Towels On Rent",
    "UPI Payments",
    "Water Dispenser",
    "Wifi",
  ];
  const [options, setOptions] = useState(initialOptions);

  // Handle selection of an item
  const handleSelect = (e) => {
    const selectedItem = e.target.value;

    // Add the selected item to the array and remove it from the dropdown list
    setRoomData(function (prevState) {
      return {
        ...prevState,
        facilities: [...prevState.facilities, selectedItem],
      };
    });
    setOptions(options.filter((option) => option !== selectedItem));
  };

  function removeTag(text) {
    //remove the item from facilities and add it into options
    // const tempArray = roomData.facilities;
    // let index = tempArray.indexOf(text);
    // tempArray.splice(index, 1);
    setRoomData(function (prevState) {
      return {
        ...prevState,
        facilities: [...roomData.facilities.filter((item) => item !== text)],
      };
    });
    const newOptions = [...options, text].sort(
      (a, b) => initialOptions.indexOf(a) - initialOptions.indexOf(b)
    );

    setOptions([...newOptions]);
  }

  return (
    <div>
      <select onChange={handleSelect}>
        <option value="" disabled selected>
          Select an option
        </option>
        {options.map((option, index) => (
          <option key={index} value={option}>
            {option}
          </option>
        ))}
      </select>
      <div className="tagOutDiv">
        {roomData.facilities.map((text) => {
          return (
            <div className="tagDiv">
              <p className="tagP">{text}</p>
              <button className="tagButton" onClick={(e) => removeTag(text)}>
                X
              </button>
            </div>
          );
        })}
      </div>
    </div>
  );
}
