import { useState } from "react";

const AddTickets = () => {
    const [data, setData] = useState({
        userId: "",
        packId: "",
        tripDateId: "",
        bookingId: "",
        type: "",
        status: "",
        descritpion: "",
        comments: [
            {
                comment: "",
                name: "",
                by: "",
                at: ""
            }
        ],
        assignedTo: ""
    });

    return (
        <div>
            <h1>Add Ticket</h1>

            <div>
                <button type="button">Save</button>
            </div>
        </div>
    );
}

export default AddTickets;
