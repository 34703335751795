import './orgPackList.css';
import Filters from '../../components/filters/Filters';
import OrgTripStrap from '../../components/orgTripStrap/OrgTripStrap';
import { useEffect, useState } from 'react';
import handleTextChange from '../../utils/handleTextChange';
import orgPackSearch from './orgPackListFun/orgPackSearch';
import ReactLoading from 'react-loading';
import errorHandle from '../../utils/errorHandle';
import { Navigate, useNavigate } from 'react-router-dom';
import { FetchPackList } from './orgPackListFun/fetchPackList';

export default function OrgPackList() {
    const navigate = useNavigate();
    const [searchText, setSearchText] = useState('');
    const [searchRe, setSearchRe] = useState('');
    const [loading, setLoading] = useState(false);
    const [nav, setNav] = useState(false);
    const [tripData, setTripData] = useState([]);
    const [selectedPack, setSelectedPack] = useState('');

    useEffect(() => {
        async function fetchPackList() {
            setLoading(true);
            try {
                await FetchPackList(setTripData, setLoading);
            } catch (error) {
                setLoading(false);
                errorHandle(error);
            }
        }
        fetchPackList();
    }, []);

    if (nav && selectedPack) {
        return <Navigate to={`/org/trip/details/${selectedPack}`} />;
    }

    return (
        <div>
            {loading && (
                <div className="loadingBackground">
                    <ReactLoading
                        type="spin"
                        color="#006aff"
                        className="loadingSpin"
                    />
                </div>
            )}
            <div className="orgPack">
                {/* discuss on whether we need filters in mybooking */}
                {/* <Filters filterData={filterDummy?.data} /> */}
                {/* <Filters filterData={[]} /> */}
                <div className="orgPackTripStrap">
                    <div className="orgPackHeadSeaDiv alignCenter">
                        <h1 className="orgPackH1">My Packages</h1>
                        {/* <div className="orgPackSearchDiv">
                            <input
                                placeholder="Search your packages"
                                name="orgSearch"
                                value={searchText}
                                className="orgPackSearch"
                                onChange={(sea) =>
                                    handleTextChange(sea, setSearchText)
                                }
                            />
                            <button
                                type="button"
                                onClick={() =>
                                    orgPackSearch(searchText, setSearchRe)
                                }
                                className="orgPackSearchBtn"
                            >
                                Search
                            </button>
                        </div> */}
                        <button
                            className="orgPackCreateBtn"
                            onClick={() => navigate('/org/create/pack')}
                        >
                            Create Package
                        </button>
                    </div>

                    {searchRe && <p>Search result for {searchRe}</p>}
                    {tripData.data?.length === 0 && !loading ? (
                        <p>No Data</p>
                    ) : (
                        <OrgTripStrap
                            page={'orgPackList'}
                            setNav={setNav}
                            data={tripData.data}
                            setSelectedPack={setSelectedPack}
                        />
                    )}
                </div>
            </div>
        </div>
    );
}
