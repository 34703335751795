import './supHeader.css';
import Logo from '../../../assets/images/logo.svg';
import MenuIcon from '../../../assets/icons/downArrow.svg';
import UserIcon from '../../../assets/icons/user.svg';

export default function SupHeader() {
    return (
        <>
            <div className="supHeaderHomeDiv">
                <div className="logoContainer">
                    <img alt="travel logo" src={Logo} className="logo" />
                </div>
                <div className="supTabsEndDiv">
                    <button
                        className="supTabsHeading menu"
                    // onClick={() => {
                    //     setHeadDrop(true);
                    // }}
                    >
                        <img
                            alt="menu-icon"
                            src={UserIcon}
                            width={16}
                            height={16}
                        />
                        {'Akash'}
                        <img
                            alt="menu-icon"
                            src={MenuIcon}
                            className="supHeaderMenuIcon"
                        />
                    </button>
                </div>
                {/* {headDrop && (
                <div
                    className="overlay"
                    onClick={() => setHeadDrop(false)}
                ></div>
            )}
            {headDrop && <OrgHeadDrop linkClick={linkClick} />} */}
            </div>
        </>
    );
}
