import DirectInput from "../../../../components/directInput/DirectInput";
import Textarea from "../../../../components/textarea/Textarea";

export default function Basic({ packData, setPackData }) {
  return (
    <div>
      <h1 className="orgPackUpH1">Create New Package</h1>
      <div className="orgPackUpSecDiv">
        <DirectInput
          label="Title"
          name="title"
          value={packData.title}
          type="text"
          placeholder="Title"
          setFormData={setPackData}
          min={3}
          limit={50}
        />
        <Textarea
          label="Overview"
          name="overview"
          value={packData.overview}
          placeholder="Overview"
          setFormData={setPackData}
          min={50}
          limit={4000}
        />
        {/* //example -  For weekend trip which starts at friday night and
    //return on sunday or monday morning is two days. */}
        <DirectInput
          label="Days"
          name="days"
          value={packData.days}
          type="number"
          placeholder="days"
          setFormData={setPackData}
          min={1}
          limit={2}
        />
        <DirectInput
          label="Nights"
          name="nights"
          value={packData.nights}
          type="number"
          placeholder="nights"
          setFormData={setPackData}
          min={1}
          limit={2}
        />
      </div>
    </div>
  );
}
