import { createContext, useEffect, useRef, useState } from 'react';
import randomString from '../utils/randomString';

export const OrgAuthContext = createContext();

export function OrgAuthProvider({ children }) {
    /* When we store something in local storage, the value gets converted to string hence hence when you 
      store any false value (null, undefined, NaN) gets converted to string and the string become truthy value.
      so convert it  back to original value before comparing */

    // const [isOrgAuthenticated, setIsOrgAuthenticated] = useState(function () {
    //     // Get initial state from local storage
    //     const savedValue = localStorage.getItem('org');
    //     return savedValue ? savedValue : '';
    // });
    const [isOrgAuthenticated, setIsOrgAuthenticated] = useState(function () {
        // Get initial state from local storage
        const savedValue = localStorage.getItem('org');
        return savedValue ? true : false;
    });
    // const firstRun = useRef(true);
    //   const [org, setOrg] = useState({});
    useEffect(() => {
        // if (firstRun.current !== true) {
        // Save state to local storage whenever it changes
        // localStorage.setItem('org', isOrgAuthenticated);
        // }
        // firstRun.current = false;

        const org = localStorage.getItem('org');
        if (org) {
            setIsOrgAuthenticated(true);
        }
    }, []);

    const orgLoginFun = (userData) => {
        localStorage.setItem('orgName', userData.name ? userData.name : '');
        localStorage.setItem('orgId', userData.userId ? userData.userId : '');
        localStorage.setItem('orgToken', userData.token ? userData.token : '');
        localStorage.setItem(
            'orgExpiry',
            userData.expiry ? userData.expiry : ''
        );
        localStorage.setItem('type', userData.type ? userData.type : '');
        localStorage.setItem('org', randomString(30));
        //generate a random string based the userId and put it here
        setIsOrgAuthenticated(true);
        // setOrg(userData);
    };

    const orgLogoutFun = () => {
        // setIsOrgAuthenticated(false);
        window.localStorage.clear();
        // return <Navigate to="/org/login" />;
        return (window.location.href = '/org/login');

        // setOrg(null);
    };

    return (
        <OrgAuthContext.Provider
            value={{ isOrgAuthenticated, orgLoginFun, orgLogoutFun }}
        >
            {children}
        </OrgAuthContext.Provider>
    );
}
