import DirectInput from "../../../../components/directInput/DirectInput";
import handleTextChange from "../../../../utils/handleTextChange";

export default function BankCompany({
  formData,
  setFormData,
  accRe,
  setAccRe,
}) {
  return (
    <div>
      <h2 className="orgRegisterH2">Bank Details</h2>
      <div className="orgRegisterSecDiv">
        <DirectInput
          label="Bank Name"
          name={"bankName"}
          value={formData.bank.bankName}
          type="text"
          placeholder="Axis, HDFC etc"
          setFormData={setFormData}
          min={3}
          limit={30}
          nest="bank"
        />
        <DirectInput
          label="Account Name"
          name={"accName"}
          value={formData.bank.accName}
          type="text"
          placeholder="Account Name"
          setFormData={setFormData}
          min={3}
          limit={30}
          nest="bank"
        />
        <DirectInput
          label="Branch Name"
          name={"branchName"}
          value={formData.bank.branchName}
          type="text"
          placeholder=""
          setFormData={setFormData}
          min={3}
          limit={30}
          nest="bank"
        />
        <DirectInput
          label="Account Number"
          name={"accNum"}
          value={formData.bank.accNum}
          type="text"
          placeholder=""
          setFormData={setFormData}
          min={3}
          limit={30}
          nest="bank"
        />
        <div className="orgRegisterInDiv">
          <label className="orgRegisterLabel">Re-enter Account Number</label>
          <div className="orgRegisterInputDiv">
            <input
              type="text"
              name="accNumAgain"
              value={accRe}
              placeholder="Re-enter Account Number"
              onChange={(e) => handleTextChange(e, setAccRe)}
              className="orgRegisterInput"
            />
          </div>
        </div>
        <DirectInput
          label="IFSC Code"
          name={"ifsc"}
          value={formData.bank.ifsc}
          type="text"
          placeholder=""
          setFormData={setFormData}
          min={3}
          limit={30}
          nest="bank"
        />
      </div>
    </div>
  );
}
