import { SeoLinks } from '../../../dummyData/homePage';
import './seo.css';

const SeoSection = () => {
    return (
        <div className="seoBg mobHidden">
            <div className="customContainer justifyCenter">
                {SeoLinks.map((item, index) => {
                    return (
                        <button key={index} className="seoBtn">{item.name}<span /></button>
                    );
                })}
            </div>
        </div>
    );
}

export default SeoSection;