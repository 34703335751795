import React, { useState, useEffect } from "react";
import "./load.css"; // Import CSS file for loading animation

const Loading = () => (
  //   <div className="loading-shimmer">
  //     <p>dkfjdlsk</p>
  //   </div>
  <div className="load-container">
    <div className="load-bar">
      <p>kfkf</p>
    </div>
  </div>
);

const Load = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate data fetching with setTimeout
    const fetchData = setTimeout(() => {
      setLoading(false); // Set loading state to false after data fetching
    }, 20000); // Simulate 2 seconds of data fetching

    return () => clearTimeout(fetchData); // Cleanup function
  }, []);

  return (
    <div className="content-container">
      {loading ? (
        <Loading /> // Render loading animation when loading state is true
      ) : (
        <div>
          <h1>Data Loaded Successfully</h1>
          {/* Render your content once loading is complete */}
        </div>
      )}
    </div>
  );
};

export default Load;
