import "./blog.css";
import BannerCarousel from "../../components/bannerCarousel/BannerCarousel";
import BannerImageData from "../../dummyData/bannerCarouselData.json";
import BlogCard from "../../components/blogCard/BlogCard";
import BlogRelated from "../../components/blogRelated/BlogRelated";
import { BlogData } from "../../dummyData/blogPage";
import EmptyPage from "../../components/empty-page/emptyPage";
import fetchBlogsData from "./fetchBlogsFun/fetchBlogsData";
import { useEffect, useState } from "react";

export default function Blog() {

  const [blogData, setBlogData] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    fetchBlogsData(setBlogData, setLoading);
  }, []);
  return (
    <div>
      <BannerCarousel className="blogCarousel" imageData={BannerImageData?.data} />
      <div className="customContainer blogBody">
        <div className="blogsGrid">
          {blogData?.length === 0 ? (
            <EmptyPage />
          ) : (
            blogData?.map((blog, blogIndex) => {
              return <BlogCard blogData={blog} key={blogIndex} />;
            }))}
        </div>
        {/* <BlogRelated /> */}
      </div>
    </div>
  );
}
