import ShowerIcon from '../../../assets/amenities/Shower.svg';
import FreeWifiIcon from '../../../assets/amenities/Free_Wifi.svg';
import ExtraBedIcon from '../../../assets/amenities/Extra_Bed_On_Request.svg';
import AirConditionIcon from '../../../assets/amenities/Air_Conditioning.svg';
import Lockers from '../../../assets/amenities/Lockers.svg';

export const getAmenities = (amenities) => {
    const amenitiesIcons = {
        Shower: ShowerIcon,
        Free_Wifi: FreeWifiIcon,
        Extra_Bed_On_Request: ExtraBedIcon,
        Air_Conditioning: AirConditionIcon,
        Lockers
    };
    return (
        <>
            {amenities.map((amenity) => {
                const Icon = amenitiesIcons[amenity];
                return Icon ? (
                    <div key={amenity}>
                        <img
                            title={amenity}
                            src={Icon}
                            alt={amenity}
                            width={24}
                            height={24}
                        />
                    </div>
                ) : null;
            })}
        </>
    );
};
