import https from '../init/https';

//USER-LOGIN-01 : User Login
export async function loginFun(data) {
    const loginData = await https.post(`/login`, data);
    return loginData;
}

//USER-LOGIN-02 : User generate OTP for phone number
export async function genOTPFun(data) {
    const genData = await https.post(`/otp`, data);
    return genData;
}

//USER-LOGIN-03 : Get email and phone OTP for registration
export async function regOTPFun(data) {
    const regData = await https.post(`/register/otp`, data);
    return regData;
}

//USER_LOGIN-04 : Register user with user details
export async function registerFun(data) {
    const reg = await https.post(`/register`, data);
    return reg;
}
