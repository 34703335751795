import './tabs.css';

const Tab = ({ label, activeTab, setActiveTab, disabled }) => {
  const isActive = activeTab === label;

  const handleClick = () => {
    setActiveTab(label);
  };
  return (
    <div className="button-wrapper">
      <button
        disabled={disabled}
        onClick={handleClick}
        className={`tab ${isActive ? 'active' : ''}`}
      >
        {label}
      </button>
      {disabled && <span className="coming-soon-tag">Coming Soon</span>}
    </div>
  );
};

export default Tab;
