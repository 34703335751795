import "./blogDetails.css";
import BannerCarousel from "../../components/bannerCarousel/BannerCarousel";
import BannerImageData from "../../dummyData/bannerCarouselData.json";
import BlogRelated from "../../components/blogRelated/BlogRelated";
import TitleBar from "../../components/titleBar/titleBar";
import { ReactComponent as UserImg } from "../../assets/icons/avatar.svg";
import { BlogData } from "../../dummyData/blogPage";
import BlogCard from "../../components/blogCard/BlogCard";

export default function BlogDetails() {
  return (
    <div>
      <BannerCarousel className="blogCarousel" imageData={BannerImageData?.data} />
      <TitleBar title="Headline of the Blog" className='customTitle' />
      <div className="customContainer blogDetailsBody">
        <div className="blogDetailsContent">
          <div className="blogPostContent">
            <h2>Introduction</h2>
            <p>Here you can introduce the topic of the blog post with a brief summary.</p>

            <h2>Subheading 1</h2>
            <p>Content related to the first subheading goes here. You can include more text, images, or even video embeds here.</p>
            <img src="https://picsum.photos/1240/300?random=1" alt="blog-banner" />
            <h2>Subheading 2</h2>
            <p>Additional details with examples, data, or graphics to make the content more engaging.</p>
            <img src="https://picsum.photos/1240/300?random=2" alt="blog-banner" />
          </div>

          <div className="blogAuthorInfo">
            <UserImg className="authorImage" />
            <div className="authorDetails">
              <h3>Akash KJ</h3>
              <p>Brief bio about the author and their expertise in the topic.</p>
            </div>
          </div>

          <div>
            <h3>Related Posts</h3>
            <div className="relatedPosts">
              {BlogData.slice(0, 3).map((blog, blogIndex) => {
                return <BlogCard blogData={blog} key={blogIndex} />;
              })}
            </div>
          </div>

          <div className="blogComments">
            <h3>Comments</h3>
            <div className="commentForm">
              <textarea placeholder="Write your comment..." />
              <button type="submit">Post Comment</button>
            </div>
          </div>
        </div>
        {/* <BlogRelated /> */}
      </div>
    </div>
  );
}
