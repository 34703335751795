import { DateRangePicker } from 'react-dates';
import './camp.css';
import { useRef, useState } from 'react';
import Navigation from '../../../assets/icons/navigation.png';
import Guest from '../../../assets/icons/guest.png';
import { ReactComponent as DateCalendar } from '../../../assets/icons/dateCalendar.svg';
import TypingAnimation from '../../../components/typingAnimation/TypingAni';

const Camp = ({ className }) => {
    const [focusedInput, setFocusedInput] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const inputFo = useRef();
    const [spanDis, setSpanDis] = useState(true);
    const tripText = ["Search", "Browse", "Explore"];

    const calendarIcon = <span role="img" aria-label="calendar" className="calendar-icon"><DateCalendar /></span>;
    const headerTypingAniStyle = {
        searchContainer: {
            top: 0,
            left: 0
        }
    };
    function handleSpan() {
        setSpanDis(false);
        inputFo.current.focus();
    }
    return (
        <div className={`campSearchWrapper ${className}`}>
            <div className='alignCenter'>
                <img src={Navigation} alt='navigation' />
                <div className='relative'>
                    <input ref={inputFo} onBlur={() => setSpanDis(true)} onClick={handleSpan} className="campSearch" />
                    {spanDis && (
                        <TypingAnimation
                            texts={tripText}
                            handleSpan={handleSpan}
                            seConStyle={headerTypingAniStyle.searchContainer}
                        />
                    )}
                </div>
            </div>
            <DateRangePicker
                startDate={startDate}
                startDateId='campStartDate'
                endDate={endDate}
                endDateId='campEndDate'
                focusedInput={focusedInput}
                onFocusChange={(focusedInput) => setFocusedInput(focusedInput)}
                onDatesChange={({ startDate, endDate }) => {
                    setStartDate(startDate);
                    setEndDate(endDate);
                }}
                noBorder
                showDefaultInputIcon
                customArrowIcon={calendarIcon}
            />
            <div className='alignCenter guestWrapper'>
                <img src={Guest} alt='guest' />
                <input placeholder='Guests' className='guestInput' />
            </div>
            <button type="button" className="hoilidayrSearchBtn campSearchBtn">Book Now</button>
        </div>
    );
};

export default Camp;
