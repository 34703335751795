import { blogFun } from "../../../api/blogAPI";
import errorHandle from "../../../utils/errorHandle";

export default async function fetchBlogsData(setBlogData, setLoading) {
  setLoading(true);
  try {
    const blogs = await blogFun();
    setBlogData(blogs.data);
    setLoading(false);
  } catch (error) {
    errorHandle(error);
    setLoading(false);
  }
}
