import "./reviewBookSumm.css";

export default function ReviewBookSumm({
  bookingData,
  reviewConfirm,
  setNavDetails,
}) {
  return (
    <div className="tripBookingTab">
      <div className="tripBookingPriceDiv">
        <h2 className="reviewBoSumH1">Price Summary</h2>
      </div>

      <div className="tripBookingSubTab">
        <div className="tripBookDataInput">
          <div className="tripPriceCal">
            <p className="tripBookPrice">
              ₹{bookingData.actualPrice} x {bookingData.people}
            </p>
            <p className="tripBookPrice">₹{bookingData.peopleAmo}</p>
          </div>
          {bookingData.offerCode && (
            <div className="tripBookOfferDiv">
              <p className="tripBookOfferText">Offer</p>
              <p className="tripBookOfferText">₹{bookingData.offerAmo}</p>
            </div>
          )}
          <div className="tripBookTotalDiv">
            <p className="tripBookTotalText">Total:</p>
            <p className="tripBookTotalText">₹{bookingData.totalAmo}</p>
          </div>
          <span></span>
          <button
            type="button"
            className="reviewBoSumChangeBtn grayBtn"
            onClick={() => setNavDetails(true)}
          >
            Change Details
          </button>
        </div>
        <div className="tripBookBtnDiv">
          <p className="reviewBoSumCondition">
            By clicking on the Confirm button, You accept our Terms &
            conditions, Cancellation Policy, and Privacy Policy.
          </p>
          <button
            className="reviewBoSumConfirm"
            type="button"
            onClick={reviewConfirm}
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
}
