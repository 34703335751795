import { useEffect, useState } from "react";
import DirectInput from "../../components/directInput/DirectInput";
import Textarea from "../../components/textarea/Textarea";
import "./blogUpload.css";
import Tags from "../../components/tags/Tags";
import blogSubmit from "./blogUploadFun/blogSubmit";
import { toast } from "react-toastify";
import ReactLoading from "react-loading";
import { useLocation } from "react-router-dom";

export default function BlogUpload() {
  const location = useLocation();
  const { blogData } = location.state || {};
  console.log(blogData);
  const userId = window.localStorage.userId;
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [blog, setBlog] = useState({
    name: "",
    shortDescription: "",
    description: [
      {
        subHeader: "",
        description: [""],
        image: null
      },
    ],
    image: null,
    tags: [],
    author: "",
    imageRefId: "",
  });

  useEffect(() => {
    if (blogData) {
      setBlog({
        name: blogData.name || "",
        shortDescription: blogData.shortDescription || "",
        description: blogData.description || [
          {
            subHeader: "",
            description: [""],
            image: null
          },
        ],
        image: blogData.image || null,
        tags: blogData.tags || [],
        author: blogData.author || "",
        imageRefId: blogData.imageRefId || "",
      });
      setFile(blogData.image || null);
    }
  }, [blogData]);

  function handleBlogImage(e) {
    if (e.target.files[0]) {
      if (e.target.files[0].size > 20e6) {
        toast.error("File should be less than 5Mb");
        return;
      }
      setFile(e.target.files[0]);
    }
  }

  function addSection() {
    setBlog((prev) => ({
      ...prev,
      description: [
        ...prev.description,
        { subHeader: "", description: [""], image: null }
      ],
    }));
  }

  function updateSection(index, key, value) {
    const updatedSections = [...blog.description];
    updatedSections[index][key] = value;
    setBlog({ ...blog, description: updatedSections });
  }

  function updateParagraph(index, paragraphIndex, value) {
    const updatedSections = [...blog.description];
    updatedSections[index].description[paragraphIndex] = value;
    setBlog({ ...blog, description: updatedSections });
  }

  function addParagraph(index) {
    const updatedSections = [...blog.description];
    updatedSections[index].description.push("");
    setBlog({ ...blog, description: updatedSections });
  }

  return (
    <div className="blogUploadDiv">
      <h1 className="blogUpHead">Blog Upload</h1>
      <div>
        <div className="inputCharDiv">
          <DirectInput
            label="Name"
            name="name"
            value={blog.name}
            type="text"
            placeholder="Enter the name of the blog"
            setFormData={setBlog}
            min={20}
            limit={60}
          />
        </div>
        <div className="inputCharDiv">
          <Textarea
            label="Short Description"
            name="shortDescription"
            value={blog.shortDescription}
            setFormData={setBlog}
            min={50}
            limit={150}
          />
        </div>
        <div className="flex">
          <label className="directInLabel">Image</label>
          <input
            type="file"
            accept=".png, .jpeg, .jpg"
            onChange={(e) => handleBlogImage(e)}
          />
        </div>

        {/* Sections */}
        {blog.description.map((section, index) => (
          <div className="inputCharDiv" key={index}>
            <div className="flex">
              <label className="directInLabel">Sub Header</label>
              <input
                className="directInInput"
                type="text"
                name={`subHeader_${index}`}
                value={section.subHeader}
                placeholder="Enter the sub header"
                onChange={(e) =>
                  updateSection(index, "subHeader", e.target.value)
                }
              />
            </div>
            <div className="flex">
              <label className="directInLabel">Section Image</label>
              <input
                type="file"
                accept=".png, .jpeg, .jpg"
                onChange={(e) =>
                  updateSection(index, "image", e.target.files[0])
                }
              />
            </div>
            {section.description.map((paragraph, paragraphIndex) => (
              <div className="flex" key={paragraphIndex}>
                <label className="directInLabel">Paragraph {paragraphIndex + 1}</label>
                <textarea
                  className="textareaInput"
                  name={`description_${index}_${paragraphIndex}`}
                  value={paragraph}
                  placeholder="Enter the paragraph"
                  onChange={(e) =>
                    updateParagraph(index, paragraphIndex, e.target.value)
                  }
                />
              </div>
            ))}
            <button
              type="button"
              onClick={() => addParagraph(index)}
              className="nestedTagOkBtn blueBtn"
            >
              Add Paragraph
            </button>
          </div>
        ))
        }

        <button
          type="button"
          className="nestedTagOkBtn blueBtn"
          onClick={addSection}
        >
          Add Section
        </button>

        <Tags data={blog} setData={setBlog} />
      </div >
      <div className="btnContainer">
        <button
          type="button"
          className="submitBtn"
          onClick={() => blogSubmit(blog, file, userId, setLoading)}
        >
          Save
        </button>
      </div>
      {loading && (
        <div className="loadingBackground">
          <ReactLoading type="spin" color="#006aff" className="loadingSpin" />
        </div>
      )}
    </div >
  );
}
