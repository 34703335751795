import './mobileCarousel.css';
import { ReactComponent as LeftInActive } from '../../assets/home/leftInActive.svg';
import { ReactComponent as RightActive } from '../../assets/home/RightActive.svg';
import { useEffect, useRef, useState } from "react";
import FeatureCard from './featureCard';
import HomeCarouselCard from '../homeCarousel/homeCarouselCard';
import DestinationCard from './destinationCard';
import ReviewCard from './reviewCard';
import BlogCard from './blogCard';

const MobileCarousel = ({ type, data }) => {
    const carouselRef = useRef(null);
    const [slideWidth, setSlideWidth] = useState(0);
    const cardsPerSlide = 1;

    useEffect(() => {
        if (carouselRef.current) {
            const containerWidth = carouselRef.current.scrollWidth;
            const calculatedSlideWidth = containerWidth / Math.ceil(carouselRef.current.children.length / cardsPerSlide);
            setSlideWidth(calculatedSlideWidth);
        }
    }, [carouselRef.current]);

    const scrollLeft = () => {
        if (carouselRef.current) {
            carouselRef.current.scrollBy({
                left: -slideWidth,
                behavior: 'smooth',
            });
        }
    };

    const scrollRight = () => {
        if (carouselRef.current) {
            carouselRef.current.scrollBy({
                left: slideWidth,
                behavior: 'smooth',
            });
        }
    };

    const getCardByType = (type) => {
        switch (type) {
            case 'feature':
                return <FeatureCard data={data} />
            case 'destination':
                return <DestinationCard data={data} />
            case 'review':
                return <ReviewCard data={data} />
            case 'blog':
                return <BlogCard data={data} />
            default:
                break;
        }
    };

    return (
        <>
            <div className="destination-carousel-container" ref={carouselRef}>
                {getCardByType(type)}
            </div>
            <div className="carouselBtnWrapper">
                <button className="carouselBtn" onClick={scrollLeft}><LeftInActive /></button>
                <button className="carouselBtn active" onClick={scrollRight}><RightActive /></button>
            </div>
        </>
    );
};

export default MobileCarousel;
