import { useState } from "react";
// import { BookingDataContext } from "../../context/BookingDataContext";
import "./pay.css";
import { Navigate } from "react-router-dom";

export default function Pay() {
  const [navHome, setNavHome] = useState(false);
  // const { bookingData } = useContext(BookingDataContext);
  //   useEffect(function () {
  //     if (Object.keys(bookingData).length === 0) {
  //       return setNavHome(true);
  //     }
  //     //eslint-disable-next-line
  //   }, []);

  if (navHome) {
    return <Navigate to="/" />;
  }
  return <div></div>;
}
