import { toast } from 'react-toastify';
import errorHandle from '../../../utils/errorHandle';
import { orgTripRegisterFun } from '../../../api/orgTripAPI';
import phoneValid from '../../../utils/phoneValid';
import emailValid from '../../../utils/emailValid';
import fileUpload from '../../../utils/fileUpload';

export default async function orgRegSubmit(
    formData,
    setLoading,
    // conPass,
    accRe,
    logoData
) {
    //check if all the data is filled properly, if not show error
    //later send data to backend for registration
    if (formData.type === 'Select') {
        toast.error('Please selecte Type');
        return;
    } else if (3 > formData.name.length || formData.name.length > 70) {
        toast.error('Error in Name');
        return;
    }
    const checkOwner = formData.owner.every((item) => {
        return Object.values(item).every((ele) => ele === '');
    });
    if (checkOwner) {
        toast.error('Error in Owner Details');
        return;
    }
    if (formData.companyType === 'Select') {
        toast.error('Please selecte Company Type');
        return;
    }
    if (50 > formData.about.length || formData.about.length > 1000) {
        toast.error('Error in about us');
        return;
    }
    if (!logoData) {
        toast.error('Please select a logo');
    }
    if (formData.gst === '') {
        toast.error('Error in GST');
        return;
    }
    if (formData.cin === '') {
        toast.error('Error in CIN');
        return;
    }
    if (formData.countryCode === '') {
        toast.error('Error in Country Code');
        return;
    }
    if (!phoneValid(formData.phoneNumber)) {
        toast.error('Enter a valid phone number');
        return;
    }
    if (!emailValid(formData.emailId)) {
        toast.error('Enter a valid phone number');
        return;
    }
    // if (formData.password !== conPass) {
    //   toast.error("Passwords dont match");
    //   return;
    // }
    const checkAdd = Object.values(formData.address).every(
        (item) => item === ''
    );
    if (checkAdd) {
        toast.error('Error in Address');
        return;
    }
    const checkContact = Object.values(formData.contactPerson).every(
        (item) => item === ''
    );
    if (checkContact) {
        toast.error('Error in Contact Person');
        return;
    }
    if (formData.contactPerson.phoneNumber.length !== 10) {
        toast.error('Error in Contact Persons Phone Number');
        return;
    }
    const checkBank = Object.values(formData.bank).every((item) => item === '');
    if (checkBank) {
        toast.error('Error in Bank Details');
        return;
    }
    if (formData.bank.accNum === accRe) {
        toast.error('Account Numbers Dont Match');
        return;
    }
    if (!formData.phoneOTP) {
        toast.error('Enter phone OTP');
        return;
    }
    if (!formData.emailOTP) {
        toast.error('Enter email OTP');
        return;
    }

    setLoading(true);
    try {
        let data = formData;
        let type =
            formData.type === 'Trip'
                ? 'trip'
                : formData.type === 'Guide'
                ? 'guid'
                : formData.type === 'Camp'
                ? 'camp'
                : 'trip';
        data.logo = await fileUpload(logoData, type, 'logo');
        await orgTripRegisterFun(data);
        toast.success('Organizer Registred Successfully, Please login');
        setLoading(false);
    } catch (error) {
        setLoading(false);
        errorHandle(error);
    }
}
