import { orgTripdashFun, orgTripDashStats } from '../../../api/orgTripAPI';

export const getTableData = (selected, setTableData, setLoading) => {
    setLoading(true);
    try {
        orgTripdashFun(selected).then((item) => {
            const data = item.data.map((item) => {
                return {
                    _id: item._id,
                    name: item.contentId.title,
                    startDate: item.startDate.split('T')[0],
                    days: item.contentId.days,
                    nights: item.contentId.nights,
                    bookedSlots: item.bookedSlots,
                    totalSlots: item.totalSlots,
                    startCities: item.contentId.price.map((city) => {
                        return {
                            city: city.startCity,
                            price: city.price
                        };
                    }),
                    difficulty: item.contentId.quickView.difficulty
                };
            });
            setTableData(data);
            setLoading(false);
        });
    } catch (error) {
        setLoading(false);
    }
};

export const getKpiData = (setKpiData, setLoading) => {
    setLoading(true);
    try {
        orgTripDashStats().then((res) => {
            const result = Object.entries(res.data).map(([name, count]) => ({
                name,
                count
            }));
            setKpiData(result);
            setLoading(false);
        });
    } catch (error) {
        setLoading(false);
    }
};
