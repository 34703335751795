import { createContext, useState } from "react";

export const BookingDataContext = createContext();

export function BookingDataProvider({ children }) {
  const [bookingData, setBookingData] = useState({});

  console.log(bookingData);

  return (
    <BookingDataContext.Provider value={{ bookingData, setBookingData }}>
      {children}
    </BookingDataContext.Provider>
  );
}
