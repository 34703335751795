import https from "../init/https";

//GST-BOOK-01 : Reserve the number of slots for 10 mins to complete a payment for guest.
export async function gstTripResFun(data) {
  const result = await https.post(`/booking/guest/trip/reserve`, data);
  return result;
}

//GST-BOOK-02 : Booking for trip for guest
export async function gstTripBookFun(data) {
  const result = await https.post(`/booking/guest/trip`, data);
  return result;
}
