export default function AccOwner({ accDetails }) {
    // const ownerNum = [
    //     'One',
    //     'Two',
    //     'Three',
    //     'Four',
    //     'Five',
    //     'Six',
    //     'Seven',
    //     'Eight'
    // ];

    return (
        <div>
            <h2 className="orgAccountH2">Owner</h2>
            <div className="orgAccountSecDiv">
                {accDetails.owner.map((item, ownerKey) => {
                    return (
                        <div key={ownerKey} className="flex">
                            <div className="mt-2">
                                <p className="orgAccountLabel">
                                    {ownerKey + 1}.
                                </p>
                            </div>
                            <div className="w-full">
                                <div className="orgAccountGrid">
                                    <div className="orgAccountInDiv">
                                        <span className="orgAccountLabel">
                                            Owner Name
                                        </span>
                                        <p className="orgAccountp">
                                            {item.name}
                                        </p>
                                    </div>
                                    <div className="orgAccountInDiv">
                                        <span className="orgAccountLabel">
                                            Designation
                                        </span>
                                        <p className="orgAccountp">
                                            {item.designation}
                                        </p>
                                    </div>
                                </div>
                                <div className="orgAccountGrid">
                                    <div className="orgAccountInDiv">
                                        <span className="orgAccountLabel">
                                            Country Code
                                        </span>
                                        <p className="orgAccountp">
                                            {item.countryCode}
                                        </p>
                                    </div>
                                    <div className="orgAccountInDiv">
                                        <span className="orgAccountLabel">
                                            Phone Number
                                        </span>
                                        <p className="orgAccountp">
                                            {item.phoneNumber}
                                        </p>
                                    </div>
                                </div>
                                <div className="orgAccountGrid">
                                    <div className="orgAccountInDiv">
                                        <span className="orgAccountLabel">
                                            Email
                                        </span>
                                        <p className="orgAccountp">
                                            {item.emailId}
                                        </p>
                                    </div>
                                    <div className="orgAccountInDiv">
                                        <span className="orgAccountLabel">
                                            Identity Proof
                                        </span>
                                        <p className="orgAccountp">
                                            {item.identityProof}
                                        </p>
                                    </div>
                                </div>
                                <div className="orgAccountGrid">
                                    <div className="orgAccountInDiv">
                                        <span className="orgAccountLabel">
                                            Identity Proof Num
                                        </span>
                                        <p className="orgAccountp">
                                            {item.identityProofNum}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}
