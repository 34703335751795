export default function AccContactPer({ accDetails }) {
    return (
        <div>
            <h2 className="orgAccountH2">Contact Person</h2>
            <div className="orgAccountSecDiv">
                <div className="orgAccountGrid">
                    <div className="orgAccountInDiv">
                        <span className="orgAccountLabel">Owner Name</span>
                        <p className="orgAccountp">
                            {accDetails.contactPerson.name}
                        </p>
                    </div>
                    <div className="orgAccountInDiv">
                        <span className="orgAccountLabel">Email Id</span>
                        <p className="orgAccountp">
                            {accDetails.contactPerson.emailId}
                        </p>
                    </div>
                </div>
                <div className="orgAccountGrid">
                    <div className="orgAccountInDiv">
                        <span className="orgAccountLabel">Country Code</span>
                        <p className="orgAccountp">
                            {accDetails.contactPerson.countryCode}
                        </p>
                    </div>
                    <div className="orgAccountInDiv">
                        <span className="orgAccountLabel">Phone Number</span>
                        <p className="orgAccountp">
                            {accDetails.contactPerson.phoneNumber}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}
