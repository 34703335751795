import AdvOne from '../assets/home/AdvOne.png';
import AdvTwo from '../assets/home/AdvTwo.png';
import AdvThree from '../assets/home/AdvThree.png';
import AdvFour from '../assets/home/AdvFour.png';

export const DestiationData = [
    {
        id: 1,
        image: AdvOne,
        title: 'Malesu Aenean Uhtrices',
        url: 'https://www.google.com'
    },
    {
        id: 2,
        image: AdvTwo,
        title: 'Quiue Cnsequat Rhonc Facibu',
        url: 'https://www.google.com'
    },
    {
        id: 3,
        image: AdvThree,
        title: 'Curs Veneatis Pellentesque',
        url: 'https://www.google.com'
    },
    {
        id: 4,
        image: AdvFour,
        title: 'Euismo Hestas Ordino',
        url: 'https://www.google.com'
    }
];