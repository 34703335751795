import { useState } from 'react';
import './itinerary.css';
import CharCount from '../../../../components/charCount/CharCount';

export default function Itinerary({ packData, setPackData }) {
    const [itnCount, setItnCount] = useState(1);

    function handleItinerary(e, index) {
        setPackData((prevState) => {
            const updatedArray = prevState.itinerary.map(function (
                item,
                itemKey
            ) {
                if (index === itemKey) {
                    return { ...item, [e.target.name]: e.target.value };
                }
                return item;
            });
            return {
                ...prevState,
                itinerary: updatedArray
            };
        });
    }

    function addDay() {
        setPackData(function (prevState) {
            return {
                ...prevState,
                itinerary: [
                    ...prevState.itinerary,
                    { dayHeading: '', dayDescription: '' }
                ]
            };
        });
        setItnCount(itnCount + 1);
    }

    function deleteDay() {
        if (itnCount > 1) {
            setPackData(function (prevState) {
                const updatedArray = prevState.itinerary;
                updatedArray.pop(); //pop should used here only, if use pop in the above line, it returns
                //the removed item which is not the expected outcome
                return {
                    ...prevState,
                    itinerary: [...updatedArray]
                };
            });
            setItnCount(itnCount - 1);
        }
    }

    return (
        <div>
            <h2 className="orgPackUpH2">Itinerary</h2>
            <div className="orgPackUpSecDiv">
                {Array.apply(null, { length: itnCount }).map(function (
                    _,
                    itnKey
                ) {
                    return (
                        <div key={itnKey}>
                            <p className="itineraryLabel">Day {itnKey + 1}</p>
                            <div className="itineraryInDiv">
                                <label className="itineraryLabel">
                                    Day Heading
                                </label>
                                <div className="itineraryInputDiv">
                                    <input
                                        value={
                                            packData.itinerary[itnKey]
                                                ? packData.itinerary[itnKey]
                                                      .dayHeading
                                                : ''
                                        }
                                        type="text"
                                        name="dayHeading"
                                        onChange={(e) =>
                                            handleItinerary(e, itnKey)
                                        }
                                        placeholder={`Day ${
                                            itnKey + 1
                                        } Heading`}
                                        className="itineraryInput"
                                    ></input>
                                    <CharCount
                                        string={
                                            packData.itinerary[itnKey]
                                                ? packData.itinerary[itnKey]
                                                      .dayHeading
                                                : ''
                                        }
                                        min={3}
                                        limit={60}
                                    />
                                </div>
                            </div>
                            <div className="itineraryDiv">
                                <label className="itineraryLabel">
                                    Day Description
                                </label>
                                <div className="itineraryInputDiv">
                                    <textarea
                                        value={
                                            packData.itinerary[itnKey]
                                                ? packData.itinerary[itnKey]
                                                      .dayDescription
                                                : ''
                                        }
                                        name="dayDescription"
                                        onChange={(e) =>
                                            handleItinerary(e, itnKey)
                                        }
                                        placeholder={`Day ${
                                            itnKey + 1
                                        } Description`}
                                        className="itineraryInput"
                                    ></textarea>
                                    <CharCount
                                        string={
                                            packData.itinerary[itnKey]
                                                ? packData.itinerary[itnKey]
                                                      .dayDescription
                                                : ''
                                        }
                                        min={20}
                                        limit={1500}
                                    />
                                </div>
                            </div>
                        </div>
                    );
                })}
                <button
                    type="button"
                    onClick={addDay}
                    className="itineraryAddBtn blueBtn"
                >
                    Add Day
                </button>
                <button
                    type="button"
                    onClick={deleteDay}
                    className="itineraryDelBtn"
                    disabled={itnCount === 1 ? true : false}
                >
                    Delete day
                </button>
            </div>
        </div>
    );
}
