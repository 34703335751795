import './reviewBookInfo.css';
import { useContext } from 'react';
import CharCount from '../../../../components/charCount/CharCount';
import { UserLoginSecContext } from '../../../../context/UserLoginSecContext';
import { UserAuthContext } from '../../../../context/UserAuthContext';

export default function ReviewBookInfo({
    userDetails,
    bookingData,
    setUserDetails,
    guest,
    setGuest
}) {
    const { setLogButton } = useContext(UserLoginSecContext);
    const { isAuthenticated } = useContext(UserAuthContext);

    function handleUser(e, index) {
        console.log(userDetails);
        setUserDetails((prevState) => {
            return prevState.map((obj, objIndex) => {
                return objIndex === index
                    ? { ...obj, [e.target.name]: e.target.value }
                    : obj;
            });
        });
    }
    return (
        <div className="reviewBoInfoDiv">
            {Object.keys(bookingData).length !== 0 && (
                <div className="reviewBoInfoDetail">
                    <h3 className="reviewBoInfoPackName">
                        {bookingData.title}
                    </h3>

                    <div className="reviewBoInfoTextDiv">
                        <span className="reviewBoInfotext">
                            {bookingData.date.format('DD/MM/YYYY')}
                        </span>
                        <span className="reviewBoInfoSpace"></span>
                        <span className="reviewBoInfotext">
                            {bookingData.days + 'D/' + bookingData.nights + 'N'}
                        </span>
                        <span className="reviewBoInfoSpace"></span>
                        <span className="reviewBoInfotext">
                            {bookingData.people}{' '}
                            {bookingData.people === 1 ? 'Ticket' : 'Tickets'}
                        </span>
                        <span className="reviewBoInfotext">
                            {bookingData.startCity}
                        </span>
                        <span className="reviewBoInfoSpace"></span>
                        <span className="reviewBoInfotext">
                            {bookingData.pickupLocation}
                        </span>
                        <span className="reviewBoInfoSpace"></span>
                        <span className="reviewBoInfotext">
                            {bookingData.transport === 'With transport'
                                ? 'Transport included'
                                : 'Transport not included'}
                        </span>
                    </div>
                </div>
            )}
            {isAuthenticated.length === 0 && (
                <p className="reviewBoInfoNoteP">
                    Note - If you are continuing as guest, The first user
                    details will be used for communication for this booking.
                </p>
            )}
            {isAuthenticated.length === 0 && !guest && (
                <div className="reviewBoInfoBtnDivLog">
                    <button
                        onClick={function () {
                            setLogButton(true);
                            console.log(true);
                        }}
                        className="reviewBoInfoLoginBtn blueBtn"
                        type="button"
                    >
                        Login
                    </button>
                    <div className="reviewBoInfoBtnGueDiv">
                        <p>Or Continue as a</p>
                        <button
                            type="button"
                            className="reviewBoInfoGuestBtn grayBtn"
                            onClick={() => setGuest(true)}
                        >
                            Guest
                        </button>
                    </div>
                </div>
            )}
            {guest && (
                // <div className="reviewBoInfoBtnDivLog">
                <p className="reviewBoInfoNoteP">Continuing as guest.</p>
            )}
            {(isAuthenticated.length !== 0 || guest) && (
                <div>
                    {Array.apply(null, { length: bookingData.people }).map(
                        function (_, peopleIndex) {
                            return (
                                <div
                                    key={peopleIndex}
                                    className="reviewBoInfoUserDiv"
                                >
                                    <div className="reviewBoInfoNameAge">
                                        <div className="reviewBoInfoName">
                                            <label className="reviewBoInfoLabel">
                                                Name
                                            </label>
                                            <input
                                                value={
                                                    userDetails[peopleIndex]
                                                        .name
                                                }
                                                name="name"
                                                onChange={(e) =>
                                                    handleUser(e, peopleIndex)
                                                }
                                                type="text"
                                                placeholder={`Person ${peopleIndex + 1
                                                    } name`}
                                                className="reviewBoInfoInput"
                                            ></input>
                                            <CharCount
                                                string={
                                                    userDetails[peopleIndex]
                                                        .name
                                                        ? userDetails[
                                                            peopleIndex
                                                        ].name
                                                        : ''
                                                }
                                                min={3}
                                                limit={30}
                                            />
                                        </div>
                                        <div className="reviewBoInfoName">
                                            <label className="reviewBoInfoLabel">
                                                Age
                                            </label>
                                            <input
                                                value={
                                                    userDetails[peopleIndex].age
                                                }
                                                name="age"
                                                onChange={(e) =>
                                                    handleUser(e, peopleIndex)
                                                }
                                                type="number"
                                                placeholder="Person's age"
                                                className="reviewBoInfoInput"
                                            ></input>
                                        </div>
                                    </div>
                                    <div className="reviewBoInfoNameAge">
                                        <div className="reviewBoInfoName">
                                            <label className="reviewBoInfoLabel">
                                                Sex
                                            </label>
                                            <select
                                                value={
                                                    userDetails[peopleIndex].sex
                                                }
                                                name="sex"
                                                onChange={(e) =>
                                                    handleUser(e, peopleIndex)
                                                }
                                                className="reviewBoInfoInput"
                                            >
                                                <option value="Select">
                                                    Select
                                                </option>
                                                <option value="Male">
                                                    Male
                                                </option>
                                                <option value="Female">
                                                    Female
                                                </option>
                                                <option value="Others">
                                                    Others
                                                </option>
                                            </select>
                                        </div>
                                        <div className="reviewBoInfoName">
                                            <label className="reviewBoInfoLabel">
                                                Email
                                            </label>
                                            <input
                                                value={
                                                    userDetails[peopleIndex]
                                                        .emailId
                                                }
                                                name="emailId"
                                                onChange={(e) =>
                                                    handleUser(e, peopleIndex)
                                                }
                                                type="text"
                                                placeholder={
                                                    'Email Id of person ' +
                                                    (peopleIndex + 1)
                                                }
                                                className="reviewBoInfoInput"
                                            ></input>
                                        </div>
                                    </div>
                                    <div className="reviewBoInfoNameAge">
                                        <div className="reviewBoInfoName">
                                            <label className="reviewBoInfoLabel">
                                                Country code
                                            </label>
                                            <select
                                                value={
                                                    userDetails[peopleIndex]
                                                        .countryCode
                                                }
                                                name="countryCode"
                                                onChange={(e) =>
                                                    handleUser(e, peopleIndex)
                                                }
                                                className="reviewBoInfoInput"
                                            >
                                                <option value="+91">+91</option>
                                            </select>
                                        </div>
                                        <div className="reviewBoInfoName">
                                            <label className="reviewBoInfoLabel">
                                                Phone Number
                                            </label>
                                            <input
                                                value={
                                                    userDetails[peopleIndex]
                                                        .phoneNumber
                                                }
                                                name="phoneNumber"
                                                onChange={(e) =>
                                                    handleUser(e, peopleIndex)
                                                }
                                                type="text"
                                                placeholder="Mobile Number"
                                                className="reviewBoInfoInput"
                                            ></input>
                                        </div>
                                    </div>
                                </div>
                            );
                        }
                    )}
                </div>
            )}
        </div>
    );
}
