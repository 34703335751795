import { useEffect, useState } from "react";
import FAQ from "../../components/detailsTabs/FAQ/FAQ";
import Pagination from "../../components/pagination/Pagination";
import TitleBar from "../../components/titleBar/titleBar";
import getFaqs from "./faqFunction/faq";
import ReactLoading from "react-loading";

export default function Faq() {
  const [faqData, setFaqData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [offSet, setOffSet] = useState(0);
  const [limit, setLimit] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);

  useEffect(() => {
    const fetchFaqData = async () => {
      try {
        await getFaqs(setFaqData, setLoading, limit, offSet);
      } catch (err) {
        console.log('Failed to fetch faq');
      }
    };

    fetchFaqData();
  }, []);

  const paginationClick = (number, offSetNumber) => {
    if (Math.ceil((faqData.length - 1) / 10) > 1) {
      setPageNumber(+number);
      setOffSet(+offSetNumber);
    }
  };

  return (
    <>
      {loading && (
        <div className="loadingBackground">
          <ReactLoading type="spin" color="#006aff" className="loadingSpin" />
        </div>
      )}
      <TitleBar title="FAQ" className='customTitle' />
      <div className="customContainer privacy">
        <FAQ data={faqData} />
        {faqData.length > 10 && (
          <Pagination
            paginationClick={paginationClick}
            numberOfRows={faqData.length}
            offSet={offSet}
            pageNumber={pageNumber}
          />
        )}
      </div>
    </>
  );
}