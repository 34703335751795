import supHttps from '../init/supHttps';

//SUP-INFO-01 : Get all the data required for support
export async function supInfoFun(phoneNumber) {
    const sup = await supHttps.get(`/support/${phoneNumber}`);
    return sup;
}

//SUP-INFO-04 : Get all the tickets
export async function ticketsInfo() {
    const tickets = await supHttps.get('/support/tickets');
    return tickets;
}
