import { toast } from 'react-toastify';
import CharCount from '../../../../components/charCount/CharCount';
import DirectInput from '../../../../components/directInput/DirectInput';
import DropdownReg from '../../../../components/dropdownReg/DropdownReg';
import handleChange from '../../../../utils/handleChange';

export default function CompanyDetails(props) {
    const { formData, setFormData, logoData, setLogoData } = props;
    const typeCompany = [
        'Private limited',
        'Partnership',
        'Sole proprietorship',
        'One person company',
        'LLP',
        'Other'
    ];
    function handleLogo(e) {
        if (e.target.files[0]) {
            if (e.target.files[0].size > 50e5) {
                toast.error('File should be less than 500kb');
            } else {
                setLogoData(e.target.files[0]);
            }
        } else {
            setLogoData();
        }
    }
    return (
        <div>
            <h2 className="orgRegisterH2">Company Details</h2>
            <div className="orgRegisterSecDiv">
                <DropdownReg
                    label="Company Type"
                    name="typeOfCompany"
                    options={typeCompany}
                    value={formData.typeOfCompany}
                    req={true}
                    setFormData={setFormData}
                />
                <div className="orgRegisterTextDiv">
                    <label className="orgRegisterLabel">About</label>
                    <div className="orgRegisterInputDiv">
                        <textarea
                            name="about"
                            value={formData.about}
                            onChange={(e) => handleChange(e, setFormData)}
                            className="orgRegisterInput"
                        />
                        <CharCount
                            string={formData.about}
                            min={50}
                            limit={1000}
                        />
                    </div>
                </div>
                <div className="orgRegisterInDiv">
                    <label className="orgRegisterLabel">Logo</label>
                    <div className="orgRegisterInputDiv">
                        <input
                            type="file"
                            accept=".png, .jpeg, .jpg"
                            onChange={(e) => handleLogo(e)}
                            className="orgRegisterInput"
                        />
                    </div>
                </div>
                {logoData && (
                    <div className="imgDisplayDiv">
                        {/* //to display the image convert it to URL */}
                        <img
                            src={URL.createObjectURL(logoData)}
                            alt="package"
                            className="wallImgDisp"
                        />
                    </div>
                )}
                <DirectInput
                    label="GST"
                    name={'gst'}
                    value={formData.gst}
                    setFormData={setFormData}
                    type="text"
                />
                <DirectInput
                    label="CIN"
                    name={'cin'}
                    value={formData.cin}
                    setFormData={setFormData}
                    type="text"
                />
                <DirectInput
                    label="Country Code"
                    name={'countryCode'}
                    value={formData.countryCode}
                    setFormData={setFormData}
                    type="text"
                />
                <DirectInput
                    label="Phone Number"
                    placeholder="This phone number will be used to login and access the account."
                    name={'phoneNumber'}
                    value={formData.phoneNumber}
                    setFormData={setFormData}
                    type="text"
                />
                {/* //This emailID will used to login and access the account. this email will be used for all the email communications */}

                <DirectInput
                    label="Email Id"
                    placeholder="This email Id will be used to login and access the account."
                    name={'emailId'}
                    value={formData.emailId}
                    setFormData={setFormData}
                    type="email"
                />
                {/* <div className="orgRegisterInDiv">
          <label className="orgRegisterLabel">Password</label>
          <div className="orgRegisterInputDiv">
            <input
              placeholder="Password"
              type="password"
              name="password"
              onChange={(e) => handleChange(e, setFormData)}
              value={formData.password}
              className="orgRegisterInput"
            />
          </div>
        </div>
        <div className="orgRegisterInDiv">
          <label className="orgRegisterLabel">Confirm Password</label>
          <div className="orgRegisterInputDiv">
            <input
              placeholder="Confirm Password"
              type="password"
              name="confirmPassword"
              onChange={(e) => setConPass(e.target.value)}
              value={conPass}
              className="orgRegisterInput"
            />
          </div> 
        </div> */}
            </div>
        </div>
    );
}
