import React, { useEffect, useRef, useState } from 'react';

const Counter = ({ targetNumber, duration = 2000, className }) => {
    const [count, setCount] = useState(0);
    const [isInView, setIsInView] = useState(false);
    const counterRef = useRef(null);

    useEffect(() => {
        const handleIntersection = (entries) => {
            const [entry] = entries;
            if (entry.isIntersecting) {
                setIsInView(true);
                observer.disconnect();
            }
        };

        const observer = new IntersectionObserver(handleIntersection, {
            threshold: 0.1
        });

        if (counterRef.current) {
            observer.observe(counterRef.current);
        }

        return () => {
            if (counterRef.current) {
                observer.unobserve(counterRef.current);
            }
        };
    }, []);

    useEffect(() => {
        if (isInView) {
            const stepTime = Math.abs(Math.floor(duration / targetNumber));

            const timer = setInterval(() => {
                setCount((prevCount) => {
                    if (prevCount < targetNumber) {
                        return prevCount + 1;
                    } else {
                        clearInterval(timer);
                        return prevCount;
                    }
                });
            }, stepTime);

            return () => clearInterval(timer);
        }
    }, [isInView, targetNumber, duration]);

    return (
        <p ref={counterRef} className={className}>{count} +</p>
    );
};

export default Counter;
