import "./campDetails.css";
import imageDataObject from "../../dummyData/tripDetailsImageData.json";
import DetailsImageDisplay from "../../components/detailsImageDisplay/DetailsImageDisplay";
import AyurBookingTab from "../../components/ayurBookingTab/AyurBookingTab";
import DetailsTabs from "../../components/detailsTabs/DetailsTabs";
import { useState } from "react";

export default function CampDetails() {
  const [roomSelected, setRoomSelected] = useState([{}]);

  function campBookingTabClick(bookingData) {
    console.log(bookingData);
  }
  // const whichType = window.location.pathname.split("/")[1];

  return (
    <div>
      <div className="campDetailsHeadingDiv">
        <h1 className="detailsImageDisplayName">
          Name of The Ayurveda package
        </h1>
        <div>
          <span className="campDetailsSubDetails">2D/2N</span>
          <span className="campDetailsDot"></span>
          <span className="campDetailsSubDetails">Organiser Name</span>
          <span className="campDetailsDot"></span>
          <span className="campDetailsSubDetails">Review</span>
        </div>
      </div>
      <div className="campDetails">
        <DetailsImageDisplay imageData={imageDataObject.data} />
        <div className="campDetailsTextBooking">
          <div className="campDetailsText">
            <DetailsTabs />
          </div>
          <div className="campDetailsBooking">
            <AyurBookingTab
              campBookingTabClick={campBookingTabClick}
              actualPrice={4000}
              offerPrice={3200}
              offer={20}
              roomSelected={roomSelected}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
