export default function AccAddress({ accDetails }) {
    return (
        <div>
            <h2 className="orgAccountH2">Address</h2>
            <div className="orgAccountSecDiv">
                <div className="orgAccountGrid">
                    <div className="orgAccountInDiv">
                        <span className="orgAccountLabel">Owner Name</span>
                        <p className="orgAccountp">{accDetails.address.name}</p>
                    </div>
                    <div className="orgAccountInDiv">
                        <span className="orgAccountLabel">Address Line 1</span>
                        <p className="orgAccountp">
                            {accDetails.address.addressLine1}
                        </p>
                    </div>
                </div>
                <div className="orgAccountGrid">
                    <div className="orgAccountInDiv">
                        <span className="orgAccountLabel">Address Line 2</span>
                        <p className="orgAccountp">
                            {accDetails.address.addressLine2 || 'N/A'}
                        </p>
                    </div>
                    <div className="orgAccountInDiv">
                        <span className="orgAccountLabel">City</span>
                        <p className="orgAccountp">{accDetails.address.city}</p>
                    </div>
                </div>
                <div className="orgAccountGrid">
                    <div className="orgAccountInDiv">
                        <span className="orgAccountLabel">Pincode</span>
                        <p className="orgAccountp">
                            {accDetails.address.pincode}
                        </p>
                    </div>
                    <div className="orgAccountInDiv">
                        <span className="orgAccountLabel">State</span>
                        <p className="orgAccountp">
                            {accDetails.address.state}
                        </p>
                    </div>
                </div>
                <div className="orgAccountGrid">
                    <div className="orgAccountInDiv">
                        <span className="orgAccountLabel">Country</span>
                        <p className="orgAccountp">
                            {accDetails.address.country}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}
