import { createContext, useState } from "react";

export const UserLoginSecContext = createContext();

export function UserLoginSecProvider({ children }) {
  const [loginSec, setLoginSec] = useState("login");
  const [logButton, setLogButton] = useState(false);

  return (
    <UserLoginSecContext.Provider
      value={{ logButton, setLogButton, loginSec, setLoginSec }}
    >
      {children}
    </UserLoginSecContext.Provider>
  );
}
