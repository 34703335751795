import { useCallback, useEffect, useState } from "react";
import "./filter.css";
import StyledRange from "../range";
import { priceRangeValue } from "../../dummyData/config";

export default function Filters({ filterData, onFilterChange, className }) {
  const [openIndex, setOpenIndex] = useState(null);
  const [selectedFilters, setSelectedFilters] = useState({});
  const [rangeValues, setRangeValues] = useState([priceRangeValue[0], priceRangeValue[1]]);

  const toggleAccordion = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const handleCheckboxChange = (type, value) => {
    setSelectedFilters((prev) => {
      const updated = { ...prev };
      if (!updated[type]) {
        updated[type] = [];
      }
      if (updated[type].includes(value)) {
        updated[type] = updated[type].filter((item) => item !== value);
      } else {
        updated[type].push(value);
      }
      return updated;
    });
  };

  const handleRangeChange = useCallback((values) => {
    setRangeValues(values);
    setSelectedFilters((prev) => ({
      ...prev,
      priceRange: values,
    }));
  }, []);

  const handleClearFilters = () => {
    setSelectedFilters({});
    setRangeValues([priceRangeValue[0], priceRangeValue[1]]);
    onFilterChange({});
  };

  useEffect(() => {
    if (Object.keys(selectedFilters).length > 0) {
      onFilterChange(selectedFilters);
    }
  }, [selectedFilters, rangeValues, onFilterChange]);

  return (
    <div className={`filterDiv ${className}`}>
      <div className='alignCenter'>
        <h2 className="filter">FILTERS</h2>
        <button className="btn anchorButton" onClick={handleClearFilters}>
          Clear
        </button>
      </div>
      <div style={{ marginTop: '18px' }}>
        {filterData?.map((filter, filterIndex) => (
          <div key={filterIndex}>
            <div className="accordion-header" onClick={() => toggleAccordion(filterIndex)}>
              <p className="filterTypeHeading alignCenter">
                {filter.heading}
                <span className="accordion-icon">
                  {openIndex === filterIndex ? '-' : '+'}
                </span>
              </p>
            </div>
            <div style={{ display: openIndex === filterIndex ? 'block' : 'none', paddingBottom: '20px' }}>
              {filter.type === 'checkbox' && filter.subType?.map((type, typeIndex) => (
                <div key={typeIndex} className="filterSubTypeDiv">
                  <input className="filterSubTypeCheck" type="checkbox" onChange={() => handleCheckboxChange(filter.heading, type)}
                    checked={selectedFilters[filter.heading]?.includes(type) || false} />
                  <p className="filterSubTypeName">{type}</p>
                </div>
              ))}
              {filter.type === 'range' && (
                <div style={{ margin: "0 8px" }}>
                  <StyledRange step={1} min={priceRangeValue[0]} max={priceRangeValue[1]} onChange={handleRangeChange} />
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
