import "./charCount.css";

export default function CharCount({ string, min, limit }) {
  // console.log(string);
  const leng = string.length;
  return (
    <span
      className={
        leng === 0
          ? "charCountDivHid"
          : min > leng || leng > limit
          ? "charCountDivRed"
          : "charCountDiv"
      }
    >
      {leng}/{limit}
    </span>
  );
}
