import https from '../init/https';

//USER-INFO-01 : User account information
export async function userInfoFun() {
    const userData = await https.get(`/user`);
    return userData;
}

//USER-INFO-02 : List of user booking
export async function userBookingFun(type) {
    const booking = await https.get(`/user/booking/${type}`);
    return booking;
}

//USER-INFO-03 : List of user wishlist
export async function userWishlistFun(type) {
    const wishlist = await https.get(`/user/wishlist/${type}`);
    return wishlist;
}

//USER-INFO-04 : add rating to a trip which user has been to
export async function userReview(type, data) {
    const review = await https.post(`/user/rate/${type}`, data);
    return review;
}

//USER-INFO-05 : add a paticalar package to wishlist of user
export async function addWishlistFun(type, data) {
    const wishlist = await https.post(`user/wishlist/${type}`, data);
    return wishlist;
}

//USER-INFO-06 : cancel a booking
export async function cancelFun(type, bookingId) {
    const cancelData = await https.post(`/user/cancel/${type}/${bookingId}`);
    return cancelData;
}

//USER-INFO-07 : To edit the information of the customer by info sent in body
export async function userEditFun(data) {
    const edit = await https.patch(`/user`, data);
    return edit;
}

//USER-INFO-08 : User can edit the rating of a particular review
export async function editRatingFun(type, reviewId, data) {
    const edit = await https.patch(`/user/rate/${type}/${reviewId}`, data);
    return edit;
}

//USER-INFO-09 : To delete a praticular item from the wishlist
export async function delWishFun(type, packId) {
    const edit = await https.delete(`/user/wishlist/${type}/${packId}`);
    return edit;
}

//USER-INFO-10 : Delete a particular review.
export async function deleteReviewFun(type, reviewId) {
    const edit = await https.delete(`/rate/${type}/${reviewId}`);
    return edit;
}

//USER-INFO-11 : Contact us.
export async function contactUs() {
    const contactData = await https.post(`/user/contact-us/email`);
    return contactData;
}

//USER-INFO-12 : Check user has been to trip.
export async function CheckTrip(type, packId) {
    const checkTrip = await https.get(`/user/check/${type}/${packId}`);
    return checkTrip;
}
