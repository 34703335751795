import Analytics from '../../assets/icons/analytics.svg';
import Billing from '../../assets/icons/billing.svg';
import Bookings from '../../assets/icons/bookings.svg';
import Dashboard from '../../assets/icons/dashboard.svg';
import Insights from '../../assets/icons/insights.svg';
import MyPackages from '../../assets/icons/my_packages.svg';
import MyRooms from '../../assets/icons/my_rooms.svg';
import Reviews from '../../assets/icons/reviews.svg';
import DasboardActive from '../../assets/icons/dashboardActive.svg';
import MyPackagesActive from '../../assets/icons/myPackagesActive.svg';
import AnalyticsActive from '../../assets/icons/analyticsActive.svg';
import MyRoomsActive from '../../assets/icons/myRoomsActive.svg';
import ReviewActive from '../../assets/icons/ReviewActive.svg';
import BookingActive from '../../assets/icons/BookingActive.svg';
import InsightsActive from '../../assets/icons/insightsActive.svg';
import BillingActive from '../../assets/icons/billingActive.svg';

export const LeftMenuData = [
    {
        title: 'Dashboard',
        path: '/org/dashboard',
        icon: Dashboard,
        activeIcon: DasboardActive
    },
    {
        title: 'My Packages',
        path: '/org/my-pack',
        icon: MyPackages,
        activeIcon: MyPackagesActive
    },
    {
        title: 'Billing',
        path: '/org/billing',
        icon: Billing,
        activeIcon: BillingActive
    },
    // {
    //     title: 'My Rooms',
    //     path: '/org/bookings',
    //     icon: MyRooms,
    //     activeIcon: MyRoomsActive
    // },
    {
        title: 'Bookings',
        path: '/org/bookings',
        icon: Bookings,
        activeIcon: BookingActive
    },
    {
        title: 'Review',
        path: '/org/reviews',
        icon: Reviews,
        activeIcon: ReviewActive
    },
    {
        title: 'Insights',
        path: '/org/bookings',
        icon: Insights,
        activeIcon: InsightsActive
    },
    {
        title: 'Analytics',
        path: '/org/bookings',
        icon: Analytics,
        activeIcon: AnalyticsActive
    }
];
