import "./orgRoomsList.css";
import TripStrap from "../../components/tripStrap/TripStrap";

export default function OrgRoomsList() {
  return (
    <div>
      <div className="orgRoomsTripStrap">
        <div className="orgRoomsHeadSeaDiv">
          <h1 className="orgRoomsH1">My Rooms</h1>
          <button type="button" className="orgRoomsCreateBtn">
            Create New Room
          </button>
        </div>

        {Array.apply(null, { length: 10 }).map((_, tripStrapIndex) => {
          return <TripStrap key={tripStrapIndex} />;
        })}
      </div>
    </div>
  );
}
