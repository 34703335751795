import "./guidDetails.css";
import imageDataObject from "../../dummyData/tripDetailsImageData.json";
import DetailsImageDisplay from "../../components/detailsImageDisplay/DetailsImageDisplay";
import TripBookingTab from "../tripDetails/tripBookingTab/TripBookingTab";
import DetailsTabs from "../../components/detailsTabs/DetailsTabs";
// import AyurBookingTab from "../../components/ayurBookingTab/AyurBookingTab";

export default function GuidDetails() {
  function guidBookingTabClick(bookingData) {
    console.log(bookingData);
  }
  // const whichType = window.location.pathname.split("/")[1];

  return (
    <div>
      <div className="guidDetailsHeadingDiv">
        <h1 className="detailsImageDisplayName">Name of The Trip</h1>
        <div>
          {/* {whichType === "camp" ? (
            <span className="guidDetailsSubDetails">City Name</span>
          ) : ( */}
          <span className="guidDetailsSubDetails">2D/2N</span>
          {/* )} */}
          <span className="guidDetailsDot"></span>
          <span className="guidDetailsSubDetails">Organiser Name</span>
          <span className="guidDetailsDot"></span>
          <span className="guidDetailsSubDetails">Review</span>
        </div>
      </div>
      <div className="guidDetails">
        <DetailsImageDisplay imageData={imageDataObject.data} />
        <div className="guidDetailsTextBooking">
          <div className="guidDetailsText">
            <DetailsTabs />
          </div>
          <div className="guidDetailsBooking">
            <TripBookingTab
              guidBookingTabClick={guidBookingTabClick}
              actualPrice={4000}
              offerPrice={3200}
              offer={20}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
