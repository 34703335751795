import React, { createContext, useEffect, useRef, useState } from "react";
import randomString from "../utils/randomString";

//create a context for authentication
export const UserAuthContext = createContext();

export function UserAuthProvider({ children }) {
  const [isAuthenticated, setIsAuthenticated] = useState(function () {
    // Get initial state from local storage
    const savedValue = localStorage.getItem("user");
    return savedValue ? savedValue : "";
  });
  const firstRun = useRef(true);
  // const [user, setUser] = useState({});

  useEffect(() => {
    if (firstRun.current !== true) {
      // Save state to local storage whenever it changes
      console.log(isAuthenticated);
      localStorage.setItem("user", isAuthenticated);
    }
    firstRun.current = false;
  }, [isAuthenticated]);

  function login(userData) {
    localStorage.setItem("name", userData.name ? userData.name : "");
    localStorage.setItem("userId", userData.userId ? userData.userId : "");
    localStorage.setItem("token", userData.token ? userData.token : "");
    //store the phoneVerifed value only if its not verifed
    if (userData.phoneVerified === false) {
      localStorage.setItem("phoneVerified", userData.phoneVerified);
    }
    //store the emailVerifed value only if its not verifed
    if (userData.phoneVerified === false) {
      localStorage.setItem("emailVerified", userData.emailVerified);
    }
    localStorage.setItem("expiry", userData.expiry ? userData.expiry : "");
    //generate a random string based the userId and put it here
    setIsAuthenticated(randomString(30));
    // setUser(userData);
  }

  const logout = () => {
    // setIsAuthenticated(false);
    // setUser(null);
    window.localStorage.clear();
    return (window.location.href = "/");
  };
  return (
    <UserAuthContext.Provider value={{ isAuthenticated, login, logout }}>
      {children}
    </UserAuthContext.Provider>
  );
}
