import TitleBar from "../../components/titleBar/titleBar";

export default function Cancellation() {
  return (
    <>
      <TitleBar title="Cancellation Policy" className='customTitle' />
      <div className="customContainer privacy">
        <p>
          If the upcoming service/trip is cancelled for whatsoever the reason. Following are the cancellation charges.
        </p>
        <b>Cancellation for domestic trips/services</b>
        <table>
          <thead>
            <tr>
              <th>Cancellation Time Period</th>
              <th>Cancellation Charges</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>30 days before the service date</td>
              <td>10% of total cost + payment gateway charges</td>
            </tr>
            <tr>
              <td>30 to 20 days before the service date</td>
              <td>30% of total cost + payment gateway charges</td>
            </tr>
            <tr>
              <td>20 to 15 days before the service date</td>
              <td>50% of total cost + payment gateway charges</td>
            </tr>
            <tr>
              <td>15 to 10 days before the service date</td>
              <td>75% of total cost + payment gateway charges</td>
            </tr>
            <tr>
              <td>Below 10 days before the service date</td>
              <td>100% of total cost</td>
            </tr>
          </tbody>
        </table>
        <b>Cancellation for International trips/services</b>
        <table>
          <thead>
            <tr>
              <th>Cancellation Time Period</th>
              <th>Cancellation Charges</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>45 days before the service date</td>
              <td>10% of total cost + payment gateway charges</td>
            </tr>
            <tr>
              <td>30 to 20 days before the service date</td>
              <td>50% of total cost + payment gateway charges</td>
            </tr>
            <tr>
              <td>Below 20 days before the service date</td>
              <td>100% of total cost</td>
            </tr>
          </tbody>
        </table>
        <b>Cancellation Requests</b>
        <p>Users who wish to cancel their bookings must submit a cancellation request through the TrvTo platform or via the customer support contact provided. </p>
        <b>Special Offers or Promotions</b>
        <p>Cancellations for bookings made under special offers or promotions may have different conditions. Users are advised to review the specific cancellation terms associated with the offer at the time of booking.</p>
        <p>If a transaction is declined or unsuccessful but the payment has still been deducted from the account, the refund process will begin upon notification. The deducted amount will be refunded to the original account used for the payment attempt.</p>
        <b>Refund Process</b>
        <p>Refunds for eligible cancellations will be processed to the original payment method within [15 days] after the cancellation is confirmed. Please note that it may take additional time for the refund to reflect in the user's account, depending on the bank or payment provider.
        </p>
        <b>Non-Refundable Bookings</b>
        <p>Certain bookings may be designated as non-refundable. Users are encouraged to carefully review the terms associated with their reservation at the time of booking. No refunds will be issued for non-refundable bookings.
        </p>
        <b>Changes to Bookings</b>
        <p>Requests to change a booking, including date changes or service modifications, must be made in accordance with the cancellation policy and are subject to availability. Any changes may also be subject to additional fees.</p>
        <b>Force Majeure</b>
        <p>In the event of a force majeure situation, such as natural disasters, government restrictions, or other unforeseen circumstances, TrvTo reserves the right to modify the cancellation policy as necessary. Users affected by such events will be notified promptly and provided with options as appropriate.</p>
        <b>Right to Cancel</b>
        <p>TrvTo reserves the right to cancel any booking if deemed necessary, including but not limited to circumstances involving fraudulent activity or violation of terms. In such cases, users may be eligible for a refund per the applicable policy.</p>
      </div>
    </>
  );
}