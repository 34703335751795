import { useState } from 'react';
import './supLoginPage.css';
import SupLogin from '../../components/supLogin/supLogin/SupLogin';

export default function SupLoginPage() {
    const [loginSec, setLoginSec] = useState('login');
    return (
        <div>
            {loginSec === 'login' ? (
                <SupLogin setLoginSec={setLoginSec} />
            ) : //: loginSec === "forgot" ? (
            //<SupForgotPass setLoginSec={setLoginSec} />)
            null}
        </div>
    );
}
