import { ReactComponent as Arrow } from '../../assets/home/arrow.svg';

const HomeCarouselCard = ({ data }) => {
    const handleClick = (url) => {
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    return (
        <div className="carousel-item">
            <img src={data.image} alt={data.title} />
            <div className="carouselInnerCard">
                <div className='innerBorder' />
                <div className="alignCenter">
                    <p>{data.title}</p>
                    <button className='carouselCardInnerBtn' onClick={() => handleClick(data.url)}><Arrow /></button>
                </div>
            </div>
        </div>
    );
}

export default HomeCarouselCard;
