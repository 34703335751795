import './dashboard.css';
import DashboardTable from './dashboardTable/DashboardTable';
import table from '../../dummyData/dashboardTable.json';
import { useEffect, useState } from 'react';
import errorHandle from '../../utils/errorHandle';
import ReactLoading from 'react-loading';
import { Link } from 'react-router-dom';
import kpiData from '../../dummyData/orgKpiData.json';
import { getKpiData, getTableData } from './dashboardFun/fetchDashboardData';
import { toPascalCase } from '../../utils/pascalCase';
import EmptyPage from '../../components/empty-page/emptyPage';

export default function Dashboard() {
    const [tableData, setTableData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selected, setSelected] = useState('next');
    const [kpiData, setKpiData] = useState([]);

    useEffect(() => {
        async function fetchDashborad() {
            try {
                await getTableData(selected, setTableData, setLoading);
                getKpiData(setKpiData, setLoading);
                // setTableData(table.data);
            } catch (error) {
                setLoading(false);
                errorHandle(error);
            }
        }
        fetchDashborad();

        //eslint-disable-next-line
    }, [selected]);

    return (
        <div className="dashboard">
            {loading && (
                <div className="loadingBackground">
                    <ReactLoading
                        type="spin"
                        color="#006aff"
                        className="loadingSpin"
                    />
                </div>
            )}
            <div className="kpi-container">
                {kpiData.map((item, index) => (
                    <div
                        className={`kpi-card kpi-card-${index + 1}`}
                        key={index}
                    >
                        <h1>{item.count}</h1>
                        <h4>{toPascalCase(item.name)}</h4>
                    </div>
                ))}
            </div>
            <div className="DashListOfUpcoming">
                <select
                    name="date"
                    id="date"
                    className="select"
                    onChange={(e) => setSelected(e.target.value)}
                >
                    <option value="next">Upcoming</option>
                    <option value="today">Today</option>
                    <option value="previous">Completed</option>
                </select>
                {tableData?.length === 0 && !loading ? (
                    <EmptyPage />
                ) : (
                    <DashboardTable tableData={tableData} />
                )}
            </div>
        </div>
    );
}
