import "./detailsImageDisplay.css";

function DetailsImageDisplay({ imageData }) {
  return (
    <div className="tripDetailsDiv">
      <div className="tripDetailsImages">
        <div className="tripDetailsMainImage">
          <img alt="nature" src={imageData[0]} className="tripDetailsMainImg" />
        </div>
        <div className="tripDetailsOtherImagesFirst">
          <img alt="nature" src={imageData[1]} className="tripDetailsOtherImg1" />
          <img alt="nature" src={imageData[2]} className="tripDetailsOtherImg2" />
        </div>
        <div className="tripDetailsOtherImages">
          <img alt="nature" src={imageData[3]} className="tripDetailsOtherImg3" />
          <img alt="nature" src={imageData[4]} className="tripDetailsOtherImg4" />
        </div>
      </div>
      <button className="detailsImageDisplayMoreButton">Explore More</button>
    </div>
  );
}

export default DetailsImageDisplay;
