export default function AccCompany({ accDetails }) {
    return (
        <div>
            <h2 className="orgAccountH2">Company Details</h2>
            <div className="orgAccountSecDiv">
                <div className="orgAccountGrid">
                    <div className="orgAccountInDiv">
                        <span className="orgAccountLabel">Company Type</span>
                        <p className="orgAccountp">
                            {accDetails.typeofCompany}
                        </p>
                    </div>
                    <div className="orgAccountInDiv">
                        <span className="orgAccountLabel">About</span>
                        <p className="orgAccountp">{accDetails.about}</p>
                    </div>
                </div>
                <div className="orgAccountGrid">
                    <div className="orgAccountInDiv">
                        <span className="orgAccountLabel">GST</span>
                        <p className="orgAccountp">{accDetails.gst}</p>
                    </div>
                    <div className="orgAccountInDiv">
                        <span className="orgAccountLabel">CIN</span>
                        <p className="orgAccountp">{accDetails.cin}</p>
                    </div>
                </div>
                <div className="orgAccountGrid">
                    <div className="orgAccountInDiv">
                        <span className="orgAccountLabel">Country Code</span>
                        <p className="orgAccountp">{accDetails.countryCode}</p>
                    </div>
                    <div className="orgAccountInDiv">
                        <span className="orgAccountLabel">Phone Number</span>
                        <p className="orgAccountp">{accDetails.phoneNumber}</p>
                    </div>
                </div>
            </div>
        </div>
    );
}
