export const BlogData = [
    {
      "blogName": "Travel and it's benifits",
      "blogShortDis": "Travel and it's benifits Travel and it's benifitsTravel and it's benifitsTravel and it's benifits Travel and it's benifits Travel and it's benifits Travel and it's benifitsTravel and it's benifitsTravel and it's benifits Travel and it's benifits",
      "blogImage": "https://picsum.photos/310/340?random=1"
    },
    {
      "blogName": "Travel and it's benifits",
      "blogShortDis": "Travel and it's benifits Travel and it's benifitsTravel and it's benifitsTravel and it's benifits Travel and it's benifits",
      "blogImage": "https://picsum.photos/310/340?random=2"
    },
    {
      "blogName": "Travel and it's benifits",
      "blogShortDis": "Travel and it's benifits Travel and it's benifitsTravel and it's benifitsTravel and it's benifits Travel and it's benifits",
      "blogImage": "https://picsum.photos/310/340?random=3"
    },
    {
      "blogName": "Travel and it's benifits",
      "blogShortDis": "Travel and it's benifits Travel and it's benifitsTravel and it's benifitsTravel and it's benifits Travel and it's benifits",
      "blogImage": "https://picsum.photos/310/340?random=4"
    }
  ];
