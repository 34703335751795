import { useEffect, useState } from "react";
import { Range } from "react-range";
import './index.css';

export default function StyledRange({ onChange, step = 1, min = 0, max = 100 }) {
    const [values, setValues] = useState([min, max]);
    useEffect(() => {
        if (onChange) {
            onChange(values);
        }
    }, [values, onChange]);
    return (
        <Range
            label="Select your value"
            step={step}
            min={min}
            max={max}
            values={values}
            onChange={setValues}
            renderTrack={({ props, children }) => {
                const [minValue, maxValue] = values;
                
                const trackWidth = `${((maxValue - minValue) / (max - min)) * 100}%`;
                const trackLeft = `${(minValue / (max - min)) * 100}%`;

                return (
                    <div {...props} className="rangeBar" style={{ ...props.style, position: 'relative' }}>
                        <div className="rangeIndicator" style={{ width: trackWidth, left: trackLeft }} />
                        {children}
                    </div>
                );
            }}
            renderThumb={({ index, props }) => (
                <div {...props} key={props.key} className="rangeThumb" style={{ ...props.style }}>
                    <div className="rangeValue">
                        {values[index]}
                    </div>
                </div>
            )}
        />
    );
};