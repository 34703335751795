import { toast } from 'react-toastify';
import { tripCreateFun } from '../../../api/orgTripAPI';
import errorHandle from '../../../utils/errorHandle';
import fileUpload from '../../../utils/fileUpload';

export default async function orgPackUpSubmit(
    packData,
    tempData,
    imgData,
    setLoading
) {
    if (3 > packData.title.length || packData.title.length > 50) {
        toast.error('Title error');
        return;
    } else if (
        50 > packData.overview.length ||
        packData.overview.length > 4000
    ) {
        toast.error('Overview error');
        return;
    } else if (1 > packData.days || packData.days > 99) {
        toast.error('Days error');
        return;
    }
    //As a trip can be for one day only.
    else if (packData.nights > 99) {
        toast.error('Nights error');
        return;
    }
    //check each key value pair in quickview for empty string
    const checkQuick = Object.values(packData.quickView).every(
        (item) => item.trim() !== ''
    );
    if (!checkQuick) {
        toast.error('Quick view error');
        return;
    }
    // .every return a boolean value. We check the condition and if its false
    //thers is some problem in itinerary.
    //for loop and forEach will not work as they cant return the outer function
    //they only return out from the loop.
    const checkIti = packData.itinerary.every((item) => {
        return (
            3 > item.dayHeading.length ||
            item.dayHeading.length > 60 ||
            20 > item.dayDescription.length ||
            item.dayDescription.length > 1500
        );
    });
    //so we show error if the checkIti is true
    if (checkIti) {
        toast.error('Itinerary error');
        return;
    }
    if (imgData.wallImg.length === 0) {
        toast.error('Error in Wallpaper Images');
        return;
    }
    if (imgData.thumbImg.length === 0) {
        toast.error('Error in Thumbnail Images');
        return;
    }
    if (20 > tempData.included.length || tempData.included.length > 800) {
        toast.error('Included error');
        return;
    }
    if (20 > tempData.excluded.length || tempData.excluded.length > 800) {
        toast.error('Excluded error');
        return;
    }

    const checkFAQ = packData.FAQ.every((item) => {
        return (
            5 > item.question.length ||
            item.question.length > 300 ||
            2 > item.answer.length ||
            item.answer.length > 500
        );
    });
    if (checkFAQ) {
        toast.error('FAQ error');
        return;
    }
    if (20 > tempData.terms.length || tempData.terms.length > 1500) {
        toast.error('Terms error');
        return;
    }
    const checkPrice = packData.price((item) => {
        return (
            2 > item.price.length ||
            item.price.length > 7 ||
            2 > item.baseCity.length ||
            item.baseCity.length > 30 ||
            item.pickupLocation.length < 1
        );
    });
    if (checkPrice) {
        toast.error('Price error');
        return;
    }
    if (packData.tags.length < 5) {
        toast.error('You should add minimum of 5 tags');
        return;
    }
    const checkTags = packData.tags.every((item) => {
        return item.length > 50;
    });
    if (checkTags) {
        toast.error('One of the tags length is greater than 50');
        return;
    }
    setLoading(true);
    try {
        //insted of appending, upload the images to firebase and store lins in the backend
        //User blow three lines for each file upload, You have loop and use Promise.all
        //create a function and return a downloadURL at last, Put all three lines in that function
        //upload the files to firebase and get the URL

        let data = packData;
        let type = localStorage.getItem('type');
        data.images = await Promise.all(
            imgData.wallImg.map((file) => {
                return fileUpload(file, type, 'create');
            })
        );

        data.cardImages = await Promise.all(
            imgData.thumbImg.map((file) => {
                return fileUpload(file, type, 'create');
            })
        );

        // const paData = new FormData();
        // imgData.wallImg.forEach((file) => {
        //     paData.append('images', file);
        // });
        // imgData.thumbImg.forEach((file) => {
        //     paData.append('cardImages', file);
        // });
        // for (const key in packData) {
        //     paData.append(key, packData[key]);
        // }
        //store the included/excluded in array of string, each string is a new line
        // Split the included/excluded by newlines to get new lines
        data.included = tempData.included
            .split('\n')
            .filter((line) => line.trim() !== '');
        data.excluded = tempData.excluded
            .split('\n')
            .filter((line) => line.trim() !== '');
        data.terms = tempData.terms
            .split('\n')
            .filter((line) => line.trim() !== '');
        //convert Included and excluded into array from different lines
        //and add that to pack data
        await tripCreateFun(data);
        toast.success('Package created successfully');
        setLoading(false);
    } catch (error) {
        setLoading(false);
        errorHandle(error);
    }
}
