import { useContext } from 'react';
import './orgHeadDrop.css';
import { OrgAuthContext } from '../../../../context/OrgAuthContext';
import { Link, useNavigate } from 'react-router-dom';

export default function OrgHeadDrop({ linkClick }) {
    const navigate = useNavigate();
    const { orgLogoutFun } = useContext(OrgAuthContext);
    return (
        <div className="orgHeadDropDiv" onClick={(e) => e.stopPropagation()}>
            {/* <Link
                className="orgTabsHeading add-btn"
                to="/org/create/room"
                onClick={() => linkClick('create-room')}
            >
                Create Room
            </Link> */}
            <Link
                className="orgTabsHeading add-btn"
                to="/org/create/pack"
                onClick={() => linkClick('create-package')}
            >
                Create Package
            </Link>
            <button
                type="button"
                onClick={() => navigate('/org/account')}
                name="account"
                className="orgHeadDropBtn"
            >
                Account
            </button>
            <button
                type="button"
                name="logOut"
                className="orgHeadDropBtn"
                onClick={orgLogoutFun}
            >
                Log Out
            </button>
            <button type="button" name="contactUs" className="orgHeadDropBtn">
                Contact Us
            </button>
        </div>
    );
}
