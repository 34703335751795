import OrgLogin from '../../components/orgLogin/orgLogin/OrgLogin';
// import OrgForgotPass from "../../components/orgLogin/orgForgot/OrgForgotPass";
import { useState } from 'react';

export default function OrgLoginPage() {
    const [loginSec, setLoginSec] = useState('login');
    return (
        <div className="orgLoginHeaderPop">
            {loginSec === 'login' ? (
                <OrgLogin setLoginSec={setLoginSec} />
            ) : // ) : loginSec === "forgot" ? (
                //   <OrgForgotPass setLoginSec={setLoginSec} />
                null}
        </div>
    );
}
