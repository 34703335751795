import handleChange from "../../utils/handleChange";
import handleNestedChange from "../../utils/handleNestedChange";
import CharCount from "../charCount/CharCount";
import "./directInput.css";

export default function DirectInput({
  label,
  name,
  value,
  type,
  placeholder,
  setFormData,
  min,
  limit,
  nest,
}) {
  return (
    <div className="directInDiv">
      <label className="directInLabel">{label}</label>
      <div className="directInInputDiv">
        <input
          value={value}
          name={name}
          onChange={
            nest
              ? (e) => handleNestedChange(e, setFormData, nest)
              : (e) => handleChange(e, setFormData)
          }
          type={type}
          // style={inputCss}
          className="directInInput"
          placeholder={placeholder}
        ></input>
        <CharCount string={value ? value : ""} min={min} limit={limit} />
      </div>
    </div>
  );
}
