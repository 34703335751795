import orgHttps from '../init/orgHttps';

//ORG-TRIP-01 : To populate the information on trip organizer dashboard
export async function orgTripdashFun(date) {
    const result = await orgHttps.get(`/org/trip?date=${date}`);
    return result;
}

//ORG-TRIP-02 : To get complete content of trip for organizer
export async function orgTripDetailsFun(tripId) {
    const result = await orgHttps.get(`/org/trip/packs/${tripId}`);
    return result;
}

//ORG-TRIP-03 : Gives the list of booked customers for given trip
//trip complete booking details for particular date
export async function orgTripBookedFun(tripDateId) {
    const result = await orgHttps.get(`/org/trip/date/booked/${tripDateId}`);
    return result;
}

//ORG-TRIP-04 : Search in organizer page
export async function orgTripSearchFun(text) {
    const result = await orgHttps.get(`/org/trip/search?name=${text}`);
    return result;
}

//ORG-TRIP-05 : Complete details of an organizer
export async function tripOrgInfoFun() {
    const result = await orgHttps.get(`/org/trip/orgInfo`);
    return result;
}

//ORG-TRIP-12 : To get list of pacakages for a particular organizer
export async function tripOrgPackListFun() {
    const result = await orgHttps.get(`/org/trip/packs`);
    return result;
}

//ORG-TRIP-13 : Getting trip Dates for package
export async function tripOrgDateFun(packId, dateType) {
    const result = await orgHttps.get(
        `/org/trip/date/${packId}?date=${dateType}`
    );
    return result;
}

//ORG-TRIP-06 : Create new trip organizer
export async function orgTripRegisterFun(data) {
    const result = await orgHttps.post(`org/trip/register`, data);
    return result;
}

//ORG-TRIP-07 : To add new trip details
export async function tripCreateFun(data) {
    const result = await orgHttps.post(`org/trip/create`, data);
    return result;
}

//ORG-TRIP-08 : To add dates to a trip
export async function tripDateCreateFun(tripId, data) {
    const result = await orgHttps.post(`org/trip/date/${tripId}`, data);
    return result;
}

//ORG-TRIP-09 : To add new booking manually
export async function tripDateMnFun(tripDateId, data) {
    const result = await orgHttps.post(`org/trip/manual/${tripDateId}`, data);
    return result;
}

//ORG-TRIP-10 : To delete the trip with dates and contents
//dont let the trip to be deleted even if it has single booking //it has to be cancalled
//dont let the trip to be delted after completion
export async function tripDeleteFun(tripId) {
    const result = await orgHttps.delete(`org/trip/${tripId}`);
    return result;
}

//ORG-TRIP-11 : To delete a particular dates of the particualr trip
//dont let the trip to be deleted even if it has single booking //it has to be cancalled
//dont let the trip to be delted after completion
export async function tripDateDelete(tripDateId) {
    const result = await orgHttps.delete(`org/trip/trip-date/${tripDateId}`);
    return result;
}

//The missing 12, 13, 14 are below 02, 03

//ORG-TRIP-15 List of booking for particular organizer,
//Complete list of booking,
//filter will be based on limit, offset, tridId
export async function orgTripBookList(limit, offset, packId, dateId) {
    const result = await orgHttps.get(
        `org/trip/booked/list?limit=${limit}&offset=${offset}&packId=${packId}&trip_date_id=${dateId}`
    );
    return result;
}

//ORG-TRIP-16 List of reviews for particular organizer
//filter will be based on limits, offset, tripId
export async function orgTripReviews(limit, offset, packId) {
    const result = await orgHttps.get(
        `org/trip/reviews/list?limit=${limit}&offset=${offset}&packId=${packId}`
    );
    return result;
}

//ORG-TRIP-17 Dropdowns for trip list in page bookings and Reviews
export async function orgTripDrop() {
    const result = await orgHttps.get(`org/trip/packs/list/dropdown`);
    return result;
}

//ORG-TRIP-18 booking done today
export async function orgTripBookToday() {
    const result = await orgHttps.get(`org/trip/booked/today`);
    return result;
}

//ORG-TRIP-19 dashboard numbers/stats
export async function orgTripDashStats() {
    const result = await orgHttps.get(`org/trip/dashboard/stats`);
    return result;
}
