import { tripOrgPackListFun } from '../../../api/orgTripAPI';

export const FetchPackList = (setTripData, setLoading) => {
    setLoading(true);
    try {
        tripOrgPackListFun().then((res) => {
            setTripData(res);
            setLoading(false);
        });
    } catch (error) {
        setLoading(false);
    }
};
