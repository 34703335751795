import { userWishlistFun } from "../../../api/userAPI";
import errorHandle from "../../../utils/errorHandle";

export default async function myWishlistData(
  typeSel,
  setWishlistData,
  setLoading
) {
  //API call for fetching wishlist data of a particular user
  //Start loading animation
  setLoading(true);
  try {
    const wishlist = await userWishlistFun(typeSel);
    setWishlistData(wishlist.data);
    //End loading animation
    setLoading(false);
  } catch (error) {
    errorHandle(error);
    //End loading animation
    setLoading(false);
  }
}
