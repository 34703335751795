import DirectInput from "../../../../components/directInput/DirectInput";
import "./quickView.css";

export default function QuickView({ packData, setPackData }) {
  return (
    <div>
      <h2 className="orgPackUpH2">Quick View</h2>
      <div className="orgPackUpSecDiv">
        <DirectInput
          label="Type"
          name="type"
          value={packData.quickView.type}
          type="text"
          placeholder="Ex - Trek or Sightseeing"
          setFormData={setPackData}
          min={5}
          limit={30}
          nest="quickView"
        />
        <DirectInput
          label="Difficulty"
          name="difficulty"
          value={packData.quickView.difficulty}
          type="text"
          placeholder="Easy or medium or difficult"
          setFormData={setPackData}
          min={4}
          limit={9}
          nest="quickView"
        />
        <DirectInput
          label="Start Time"
          name="startTime"
          value={packData.quickView.startTime}
          type="text"
          placeholder="Ex - 9:00 PM"
          setFormData={setPackData}
          min={6}
          limit={9}
          nest="quickView"
        />
        <DirectInput
          label="End Time"
          name="endTime"
          value={packData.quickView.endTime}
          type="text"
          placeholder="Ex - 9:00 PM"
          setFormData={setPackData}
          min={6}
          limit={9}
          nest="quickView"
        />
        <DirectInput
          label="Duration"
          name="duration"
          value={packData.quickView.duration}
          type="text"
          placeholder="2D/1N"
          setFormData={setPackData}
          min={6}
          limit={9}
          nest="quickView"
        />
        <DirectInput
          label="Stay"
          name="stay"
          value={packData.quickView.stay}
          type="text"
          placeholder="Tent, Sharing rooms"
          setFormData={setPackData}
          min={6}
          limit={30}
          nest="quickView"
        />
        <DirectInput
          label="Where"
          name="where"
          value={packData.quickView.where}
          type="text"
          placeholder="Destinotion palce name"
          setFormData={setPackData}
          min={6}
          limit={30}
          nest="quickView"
        />
        <DirectInput
          label="Food"
          name="food"
          value={packData.quickView.food}
          type="text"
          placeholder="Veg, Non-veg"
          setFormData={setPackData}
          min={6}
          limit={30}
          nest="quickView"
        />
        <DirectInput
          label="Trek Distance"
          name="trekDistance"
          value={packData.quickView.trekDistance}
          type="text"
          placeholder="Trek distance in KM"
          setFormData={setPackData}
          min={3}
          limit={8}
          nest="quickView"
        />
        <DirectInput
          label="Base Cities"
          name="baseCities"
          value={packData.quickView.baseCities}
          type="text"
          placeholder="Starting cities (ex - Bengaluru, Mysuru"
          setFormData={setPackData}
          min={3}
          limit={40}
          nest="quickView"
        />
      </div>
    </div>
  );
}
