import "./ayurDetails.css";
import imageDataObject from "../../dummyData/tripDetailsImageData.json";
import DetailsImageDisplay from "../../components/detailsImageDisplay/DetailsImageDisplay";
import AyurBookingTab from "../../components/ayurBookingTab/AyurBookingTab";
import DetailsTabs from "../../components/detailsTabs/DetailsTabs";
import { useState } from "react";

export default function AyurDetails() {
  const [roomSelected, setRoomSelected] = useState([{}]);

  function ayurBookingTabClick(bookingData) {
    console.log(bookingData);
  }
  // const whichType = window.location.pathname.split("/")[1];

  return (
    <div>
      <div className="ayurDetailsHeadingDiv">
        <h1 className="detailsImageDisplayName">
          Name of The Ayurveda package
        </h1>
        <div>
          <span className="ayurDetailsSubDetails">2D/2N</span>
          <span className="ayurDetailsDot"></span>
          <span className="ayurDetailsSubDetails">Organiser Name</span>
          <span className="ayurDetailsDot"></span>
          <span className="ayurDetailsSubDetails">Review</span>
        </div>
      </div>
      <div className="ayurDetails">
        <DetailsImageDisplay imageData={imageDataObject.data} />
        <div className="ayurDetailsTextBooking">
          <div className="ayurDetailsText">
            <DetailsTabs />
          </div>
          <div className="ayurDetailsBooking">
            <AyurBookingTab
              ayurBookingTabClick={ayurBookingTabClick}
              actualPrice={4000}
              offerPrice={3200}
              offer={20}
              roomSelected={roomSelected}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
