import './searchResult.css';
import BannerCarousel from '../../components/bannerCarousel/BannerCarousel';
import TripCard from '../../components/tripCard/TripCard';
import Filters from '../../components/filters/Filters';
import filterDummy from '../../dummyData/typeOfFilters.json';
import ReactLoading from 'react-loading';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { fetchSearchResult } from './searchResultFun/fetchSearchResult';
import { banners } from '../../dummyData/searchResults';
import Dropdown from '../../components/dropdown/Dropdown';
import { sortTypes } from '../../dummyData/config.js';
import { handleFilterChange } from './searchResultFun/filterChange.js';
import MobileFilter from './mobileFilter.jsx';
import { useLocation, useParams } from 'react-router-dom';
import { userWishlistFun } from '../../api/userAPI.js';
import EmptyPage from '../../components/empty-page/emptyPage.jsx';

export default function SerachResult() {
  const [searchData, setSearchData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [filteredData, setFilteredData] = useState([]);
  const [sortData, setSortData] = useState({ sortBy: '' });
  const [offset, setOffset] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const { text } = useParams();
  const location = useLocation();
  const { limit } = location.state;
  const [localLimit, setLocalLimit] = useState(limit || 9);

  const getQueryParams = () => {
    const searchParams = new URLSearchParams(location.search);
    const offSet = searchParams.get('offset') || 0;
    const limit = searchParams.get('limit') || 10;
    return { offSet, limit };
  };

  useMemo(() => {
    if (!text) return;
    setLocalLimit(limit);
  }, [text]);

  useEffect(() => {
    // const { offSet, limit } = getQueryParams();

    const fetchSearchResults = async () => {
      try {
        setSearchData([]);
        await fetchSearchResult(
          text,
          offset,
          localLimit,
          setLoading,
          setSearchData
        );
      } catch (err) {
        console.log('Failed to fetch search results');
      }
    };

    fetchSearchResults();
  }, [text, location.search, localLimit]);

  //Implement Infinite Scrolling
  //To detect when the user has scrolled to the bottom of the page
  const handleScroll = useCallback(() => {
    if (
      window.innerHeight + document.documentElement.scrollTop !==
      document.documentElement.offsetHeight ||
      loading ||
      !hasMore
    ) {
      return;
    }
    setOffset((prevOffset) => prevOffset + limit);
  }, [loading, hasMore, limit]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  // Filter and sort data
  useEffect(() => {
    let updatedData = [...searchData];

    // // Apply filters
    // if (filteredData.length) {
    //   updatedData = filteredData;
    // }

    // Apply sorting
    if (sortData.sortBy === 'Low to High') {
      updatedData.sort((a, b) => a.price[0].price - b.price[0].price);
    } else if (sortData.sortBy === 'High to Low') {
      updatedData.sort((a, b) => b.price[0].price - a.price[0].price);
    }

    setFilteredData(updatedData);
  }, [sortData.sortBy, searchData]);

  const handleLoadMore = () => {
    setLocalLimit(localLimit + 9);
  };

  const handleFilterChangeCallback = useCallback(
    (selectedFilters) =>
      handleFilterChange(searchData, setFilteredData)(selectedFilters),
    [searchData]
  );

  const handleMobileFilter = () => {
    setShowModal(!showModal);
  };

  console.log(window.location.pathname.split('/')[3]);

  return (
    <div>
      {loading && (
        <div className="loadingBackground">
          <ReactLoading
            type="spin"
            color="#006aff"
            className="loadingSpin"
          />
        </div>
      )}
      <BannerCarousel imageData={banners} className="searchResults" />
      <div className="searchReDiv customContainer">
        <Filters
          className="desktopFilterVersion"
          filterData={filterDummy?.data}
          onFilterChange={handleFilterChangeCallback}
        />
        <div className="searchResult">
          <div className="alignCenter searchResultSpacing">
            <div className="searchResultTitleArea">
              {/* Take the text from URl and replace %20 with space */}
              <p>
                {window.location.pathname
                  .split('/')[3]
                  .replace(/%20/g, ' ')}
              </p>
              <p>Showing {filteredData?.length} results</p>
            </div>
            <div>
              <Dropdown
                label="Sort By:"
                name="sortBy"
                options={sortTypes}
                value={sortData.sortBy}
                setFormData={setSortData}
                className="sortBy row alignCenter"
              />
            </div>
          </div>
          <button
            className="filterButton mobileFilter"
            onClick={handleMobileFilter}
          >
            Filter
          </button>
          <div className="searchResultContentArea">
            <TripCard imageData={filteredData} />
          </div>
          <div className="loadMoreContianer">
            <button onClick={handleLoadMore}>Load More...</button>
          </div>
          {filteredData?.length === 0 && <EmptyPage />}
          {showModal && (
            <MobileFilter
              filterData={filterDummy?.data}
              onFilterChange={handleFilterChangeCallback}
              handleClose={handleMobileFilter}
              showModal={showModal}
            />
          )}
        </div>
      </div>
    </div>
  );
}