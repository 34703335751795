import { getFaqDetailsFun } from "../../../api/infoAPI";
import { userBookingFun } from "../../../api/userAPI";
import errorHandle from "../../../utils/errorHandle";

export default async function getFaqs(
  setFaqData,
  setLoading,
  limit,
  offset
) {
  setLoading(true);
  try {
    const faq = await getFaqDetailsFun(limit, offset);
    
    setFaqData(faq.data);
    setLoading(false);
  } catch (error) {
    errorHandle(error);
    setLoading(false);
  }
}
