import './footer.css';

import {
    legal,
    paymentDetails,
    quickLinks,
    socialIcons,
    whoWeAre
} from './footerFunctions/menuItems';

import FooterItem from './footerFunctions/footerItem';
import Logo from '../../assets/images/logo.png';

export default function Footer() {
    const SocialIcons = () => {
        return (
            <>
                {socialIcons.map((icon, index) => {
                    return (
                        <a className='socialLink' key={index} href={icon.link} target="_blank" rel="noopener noreferrer">
                            <img src={icon.icon} alt={icon.name} />
                        </a>
                    );
                })}
            </>
        );
    };

    const year = new Date().getFullYear();

    return (
        <div className="footerWrapper customContainer">
            <div className="footer">
                <div>
                    <img src={Logo} alt="trvTo" />
                    <p>
                        Our mission is to offer our customers raw, authentic, and safe adventure experiences. We understand that true adventure lies in genuine exploration, and we strive to provide that while ensuring the highest standards of safety. At TrvTo, your safety and enjoyment are our top priorities. Our team of experts rigorously checks and verifies all experiences to provide you with peace of mind as you explore the unknown
                    </p>
                    <div className="socialIcons">
                        <SocialIcons />
                    </div>
                </div>
                <div>
                    <p className="subHeader">Quick Links</p>
                    {quickLinks.map((link, index) => {
                        return <FooterItem name={link} key={index} />;
                    })}
                </div>
                <div className="footerSubChild">
                    <div className="footerSubChildAlignment">
                        <div className="width50">
                            <p className="subHeader">Who We Are</p>
                            {whoWeAre.map((link, index) => {
                                return <FooterItem name={link} key={index} />;
                            })}
                        </div>
                        <div className="width50">
                            <p className="subHeader">Legal</p>
                            {legal.map((link, index) => {
                                return <FooterItem name={link} key={index} />;
                            })}
                        </div>
                    </div>
                    <div className="alignPaymentDetails">
                        {paymentDetails.map((icon, index) => {
                            return <img src={icon.icon} alt={icon.name} key={index} />;
                        })}
                    </div>
                    <div className="socialIcons mobileVersion">
                        <SocialIcons />
                    </div>
                </div>
            </div>
            <div className="footerDivider">Copyright © {year} All rights Reserved. TrvTo</div>
        </div>
    );
}
