import errorHandle from '../../../utils/errorHandle';
import { getDateDetailsFun, getDetailsFun } from '../../../api/infoAPI';

export default async function fetchTripData(
    type,
    packId,
    setLoading,
    setPackData,
    setPackDates
) {
    setLoading(true);
    console.log(type, packId);
    try {
        // for getDateDetailsFun you get an array of objects with tripDate id, start and end date of each
        const allData = await Promise.all([
            await getDetailsFun(type, packId),
            await getDateDetailsFun(type, packId)
        ]);
        console.log(allData[0].data);
        console.log(allData[1].data);
        setPackData(allData[0].data);
        setPackDates(allData[1].data);
        // setPackData(tripDetailsData.data);
        // setPackDates(tripDateDetails.data);
        setLoading(false);
    } catch (error) {
        setLoading(false);
        errorHandle(error);
    }
}
