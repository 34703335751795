import https from '../init/https';

//BOOK-01 : Check real time availability of the solts
export async function tripAvaFun(data) {
    console.log(data);
    const result = await https.post(`/booking/trip/check-availability`, data);
    return result;
}

//BOOK-02 : Reserve the number of slots for 10 mins to complete a payment.
export async function tripReserveFun(data) {
    const result = await https.post(`/booking/trip/reserve`, data);
    return result;
}
//BOOK-13 : Trip reserve for guest
export async function tripReserveGuestFun(data) {
    const result = await https.post(`/booking/trip/reserve/guest`, data);
    return result;
}

//BOOK-03 : Booking for trip
export async function tripBookingFun(data) {
    const result = await https.post(`/booking/trip`, data);
    return result;
}

//BOOK-14 : Trip booking for guest
export async function tripBookingGuestFun(data) {
    const result = await https.post(`/booking/trip/guest`, data);
    return result;
}

//BOOK-04 : Check real time availability of the solts
export async function ayurAvaFun(data) {
    const result = await https.post(`/booking/ayur/check-availability`, data);
    return result;
}

//BOOK-05 : Reserve the number of slots for 10 mins to complete a payment.
export async function ayurReserveFun(data) {
    const result = await https.post(`/booking/ayur/reserve`, data);
    return result;
}

//BOOK-06 : Booking for ayur
export async function ayurBookingFun(data) {
    const result = await https.post(`/booking/ayur`, data);
    return result;
}

//BOOK-07 : Check real time availability of the solts
export async function guidAvaFun(data) {
    const result = await https.post(`/booking/guid/check-availability`, data);
    return result;
}

//BOOK-08 : Reserve the number of slots for 10 mins to complete a payment.
export async function guidReserveFun(data) {
    const result = await https.post(`/booking/guid/reserve`, data);
    return result;
}

//BOOK-09 : Booking for guid
export async function guidBookingFun(data) {
    const result = await https.post(`/booking/guid`, data);
    return result;
}

//BOOK-10 : Check real time availability of the camp rooms
export async function campAvaFun(data) {
    const result = await https.post(`/booking/camp/check-availability`, data);
    return result;
}

//BOOK-11 : Reserve the camp room for the dates for 10 min to complete the payment
export async function campReserveFun(data) {
    const result = await https.post(`/booking/camp/reserve`, data);
    return result;
}

//BOOK-12 : Booking for camp
export async function campBookingFun(data) {
    const result = await https.post(`/booking/camp`, data);
    return result;
}
