import { getOfferDetailsFun } from "../../../../api/infoAPI";
import errorHandle from "../../../../utils/errorHandle";

export default async function offerApplyTrip(
  offerCode,
  setOfferData,
  setOfferError,
  setBooking
) {
  try {
    const checkOffer = await getOfferDetailsFun(offerCode);
    setOfferData(checkOffer);
    setBooking((prevState) => {
      return { ...prevState, offerCode: offerCode };
    });
    setOfferError("");
  } catch (error) {
    setOfferError("Invalid offer code");
    errorHandle(error);
  }
}
