import https from "../init/https";

//BLOG-01 : Get many different blogs to populate blog home page
export async function blogFun() {
  const result = await https.get(`/blog/home`);
  return result;
}

//BLOG-02 : Get complete content of blog based blog id
export async function blogContentFun(blogId) {
  const result = await https.get(`/blog/${blogId}`);
  return result;
}

//BLOG-03 : Upload complete content of blog
export async function blogUploadFun(data) {
  const result = await https.post(`/data/blog`, data);
  return result;
}

//BLOG-04 : Edit blog
export async function blogEditFun(blogId, data) {
  const result = await https.patch(`/blog/${blogId}`, data);
  return result;
}

//BLOG-05 : Delete blog based on the blogId
export async function blogDeleteFun(blogId) {
  const result = await https.delete(`/blog/${blogId}`);
  return result;
}
