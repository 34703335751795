import https from "../init/https";

//USER-SER-01 : Trip search
export async function tripSearchFun(text, offset, limit) {
  const result = await https.get(
    `/search/trip?value=${text}&offset=${offset}&limit=${limit}`
  );
  return result;
}

//USER-SER-02 : Ayurveda search
export async function ayurSearchFun(text, offset, limit) {
  const result = await https.get(
    `/search/ayur?value=${text}&offset=${offset}&limit=${limit}`
  );
  return result;
}

//USER-SER-03 : Guid search
export async function guidSearchFun(text, offset, limit) {
  const result = await https.get(
    `/search/guid?value=${text}&offset=${offset}&limit=${limit}`
  );
  return result;
}

//USER-SER-04 : Camp search
export async function campSearchFun(text, offset, limit) {
  const result = await https.get(
    `/search/camp?value=${text}&offset=${offset}&limit=${limit}`
  );
  return result;
}

//USER-SER-05 : Blog search
export async function blogSearchFun(text, offset, limit) {
  const result = await https.get(
    `/search/blog?value=${text}&offset=${offset}&limit=${limit}`
  );
  return result;
}
