import { useRef, useState } from 'react';
import './otpinput.css';

const OTPInput = ({ length = 6, setUserData, keyName, handleKeyDown }) => {
    const inputRef = useRef(Array(length).fill(null));
    const [OTP, setOTP] = useState(Array(length).fill(''));

    const handleTextChange = (input, index) => {
        const newPin = [...OTP];
        newPin[index] = input;
        //update the OTP in the emailOTP or phoneOTP in parent page
        const newStringOTP = newPin.join('');
        console.log(newStringOTP);
        console.log(keyName);
        setUserData((prevState) => {
            return { ...prevState, [keyName]: newStringOTP };
        });
        setOTP(newPin);

        if (input.length === 1 && index < length - 1) {
            inputRef.current[index + 1]?.focus();
        }

        if (input.length === 0 && index > 0) {
            inputRef.current[index - 1]?.focus();
        }
    };

    return (
        <div className="otpinput-wrapper">
            {Array.from({ length }, (_, index) => (
                <input
                    key={index}
                    type="text"
                    maxLength={1}
                    value={OTP[index]}
                    onChange={(e) => handleTextChange(e.target.value, index)}
                    onKeyDown={(event) => {
                        if (index === 5) {
                            handleKeyDown(event);
                        }
                    }}
                    ref={(ref) => (inputRef.current[index] = ref)}
                    className="otpinput"
                />
            ))}
        </div>
    );
};

export default OTPInput;
