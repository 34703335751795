import { useState } from 'react';
import CharCount from '../../../../components/charCount/CharCount';

export default function Owner({ formData, setFormData }) {
    const [ownerLength, setOwnerLength] = useState(1);
    const identityProofDrop = ['Aadhar', 'Voter Id'];
    function handleDeleteOwner() {
        if (ownerLength !== 1) {
            setFormData(function (prevState) {
                const updatedArray = prevState.owner;
                updatedArray.pop(); //pop should used here only, if use pop in the above line, it returns
                //the removed item which is not the expected outcome
                console.log(updatedArray);
                return {
                    ...prevState,
                    owner: [...updatedArray]
                };
            });
            setOwnerLength(ownerLength - 1);
        }
    }

    function addOwner() {
        setFormData(function (prevState) {
            return {
                ...prevState,
                owner: [
                    ...prevState.owner,
                    {
                        name: '',
                        designation: '',
                        phoneNumber: '',
                        countryCode: '',
                        emailId: '',
                        identityProof: '',
                        identityProofNum: ''
                    }
                ]
            };
        });
        setOwnerLength(ownerLength + 1);
    }

    function handleOwner(e, index) {
        setFormData(function (prevState) {
            const updatedArray = prevState.owner.map(function (item, itemKey) {
                if (index === itemKey) {
                    return { ...item, [e.target.name]: e.target.value };
                }
                return item;
            });
            return {
                ...prevState,
                owner: updatedArray
            };
        });
    }

    return (
        <div>
            <h2 className="orgRegisterH2">Owner Information</h2>
            <div className="orgRegisterSecDiv">
                {Array.apply(null, { length: ownerLength }).map(function (
                    _,
                    ownerIndex
                ) {
                    return (
                        <div key={ownerIndex}>
                            <p className="orgRegisterLabel">
                                Owner {ownerIndex + 1}
                            </p>
                            <div className="orgRegisterInDiv">
                                <label className="orgRegisterLabel">Name</label>
                                <div className="orgRegisterInputDiv">
                                    <input
                                        value={formData.owner[ownerIndex].name}
                                        type="text"
                                        name="name"
                                        placeholder="Owner Name"
                                        onChange={(e) =>
                                            handleOwner(e, ownerIndex)
                                        }
                                        className="orgRegisterInput"
                                    />
                                    <CharCount
                                        string={formData.owner[ownerIndex].name}
                                        min={3}
                                        limit={30}
                                    />
                                </div>
                            </div>
                            <div className="orgRegisterInDiv">
                                <label className="orgRegisterLabel">
                                    Designation
                                </label>
                                <div className="orgRegisterInputDiv">
                                    <input
                                        value={
                                            formData.owner[ownerIndex]
                                                .designation
                                        }
                                        type="text"
                                        name="designation"
                                        placeholder="Position in company"
                                        onChange={(e) =>
                                            handleOwner(e, ownerIndex)
                                        }
                                        className="orgRegisterInput"
                                    />
                                    <CharCount
                                        string={
                                            formData.owner[ownerIndex]
                                                .designation
                                        }
                                        min={3}
                                        limit={30}
                                    />
                                </div>
                            </div>
                            <div className="orgRegisterInDiv">
                                <label className="orgRegisterLabel">
                                    Country code
                                </label>
                                <div className="orgRegisterInputDiv">
                                    <input
                                        value={
                                            formData.owner[ownerIndex]
                                                .countryCode
                                        }
                                        type="text"
                                        name="countryCode"
                                        placeholder=""
                                        onChange={(e) =>
                                            handleOwner(e, ownerIndex)
                                        }
                                        className="orgRegisterInput"
                                    />
                                    <CharCount
                                        string={
                                            formData.owner[ownerIndex]
                                                .countryCode
                                        }
                                        min={2}
                                        limit={4}
                                    />
                                </div>
                            </div>
                            <div className="orgRegisterInDiv">
                                <label className="orgRegisterLabel">
                                    "Phone Number
                                </label>
                                <div className="orgRegisterInputDiv">
                                    <input
                                        value={
                                            formData.owner[ownerIndex]
                                                .phoneNumber
                                        }
                                        type="text"
                                        name="phoneNumber"
                                        placeholder=""
                                        onChange={(e) =>
                                            handleOwner(e, ownerIndex)
                                        }
                                        className="orgRegisterInput"
                                    />
                                    <CharCount
                                        string={
                                            formData.owner[ownerIndex]
                                                .phoneNumber
                                        }
                                        min={10}
                                        limit={10}
                                    />
                                </div>
                            </div>
                            <div className="orgRegisterInDiv">
                                <label className="orgRegisterLabel">
                                    Email Id
                                </label>
                                <div className="orgRegisterInputDiv">
                                    <input
                                        value={
                                            formData.owner[ownerIndex].emailId
                                        }
                                        type="text"
                                        name="emailId"
                                        placeholder=""
                                        onChange={(e) =>
                                            handleOwner(e, ownerIndex)
                                        }
                                        className="orgRegisterInput"
                                    />
                                    <CharCount
                                        string={
                                            formData.owner[ownerIndex].emailId
                                        }
                                        min={9}
                                        limit={40}
                                    />
                                </div>
                            </div>
                            <div className="orgRegisterInDiv">
                                <label className="orgRegisterLabel">
                                    Identity Proof
                                </label>
                                <div className="orgRegisterInputDiv">
                                    <select
                                        value={
                                            formData.owner[ownerIndex]
                                                .identityProof
                                        }
                                        name="identityProof"
                                        onChange={(e) =>
                                            handleOwner(e, ownerIndex)
                                        }
                                        className="orgRegisterSelect"
                                    >
                                        <option value="-1">Select</option>
                                        {identityProofDrop.map(function (
                                            val,
                                            typeIndex
                                        ) {
                                            return (
                                                <option
                                                    key={typeIndex}
                                                    value={val}
                                                >
                                                    {val}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </div>
                            </div>
                            <div className="orgRegisterInDiv">
                                <label className="orgRegisterLabel">
                                    Identity Proof Num
                                </label>
                                <div className="orgRegisterInputDiv">
                                    <input
                                        value={
                                            formData.owner[ownerIndex]
                                                .identityProofNum
                                        }
                                        type="text"
                                        name="identityProofNum"
                                        placeholder=""
                                        onChange={(e) =>
                                            handleOwner(e, ownerIndex)
                                        }
                                        className="orgRegisterInput"
                                    />
                                    <CharCount
                                        string={
                                            formData.owner[ownerIndex]
                                                .identityProofNum
                                        }
                                        min={5}
                                        limit={30}
                                    />
                                </div>
                            </div>
                        </div>
                    );
                })}
                <button
                    type="button"
                    onClick={addOwner}
                    className="orgRegisterAddBtn blueBtn"
                >
                    Add Owner
                </button>
                <button
                    type="button"
                    onClick={handleDeleteOwner}
                    disabled={ownerLength === 1 ? true : false}
                    className="orgRegisterDelBtn"
                >
                    Delete owner
                </button>
            </div>
        </div>
    );
}
