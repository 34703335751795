import { useContext, useState } from 'react';
import handleChange from '../../../utils/handleChange';
import './supLogin.css';
import emailValid from '../../../utils/emailValid';
import { supLoginFunAPI, supOTPFunAPI } from '../../../api/supLoginAPI';
import errorHandle from '../../../utils/errorHandle';
import ReactLoading from 'react-loading';
import { toast } from 'react-toastify';
import { SupAuthContext } from '../../../context/SupAuthContext';
import { Navigate } from 'react-router-dom';
import OTPInput from '../../otpinput/otpinput';
import TrvToLogo from '../../../assets/images/logo.png';
import { ReactComponent as TravelImg } from '../../../assets/images/travel.svg';
import { ReactComponent as Announcement } from '../../../assets/images/announcement.svg';
import { ReactComponent as Phone } from '../../../assets/icons/phone.svg';
import { ReactComponent as Email } from '../../../assets/icons/email.svg';

function SupLogin({ setLogButton, setLoginSec }) {
    const [userDetails, setUserDetails] = useState({
        emailId: '',
        otp: ''
    });
    const [emailError, setEmailError] = useState('');
    const [otpError, setOtpError] = useState('');
    const [loading, setLoading] = useState(false);
    const [response, setResponse] = useState('400');

    const { isSupAuthenticated, SupLoginFun } = useContext(SupAuthContext);

    console.log(userDetails);

    async function getSupOTP() {
        if (!emailValid(userDetails.emailId)) {
            setEmailError('Please enter a valid email address');
            console.log('Invalid Email');
            return;
        }
        setEmailError('');
        setLoading(true);
        try {
            await supOTPFunAPI(userDetails);
            //End Loadin
            setLoading(false);
            //Set response to 200 telling OTP is sent
            setResponse('200');
            //Notifaction of OTP sent
            toast.success('OTP sent to your phone number');
        } catch (error) {
            errorHandle(error);
            //End loading animation
            setLoading(false);
        }
    }

    //check for valid email id and
    //send supLogin information to backend for verification
    async function handleLogin() {
        if (userDetails.otp.length <= 5) {
            setOtpError('Please enter the complete OTP');
            console.log('Please enter the complete OTP');
            return;
        }
        setOtpError('');
        //Start loading animation
        setLoading(true);

        try {
            const supLoginData = await supLoginFunAPI(userDetails);
            console.log(supLoginData.data);
            //Show notifation of Login success
            toast.success('Login Successful');
            //End loading animation
            setLoading(false);
            SupLoginFun(supLoginData.data);
        } catch (error) {
            errorHandle(error);
            //End loading animation
            setLoading(false);
        }
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            response !== '200' ? getSupOTP() : handleLogin();
        }
    };

    if (isSupAuthenticated) {
        return <Navigate from="support/login" to="/support/assistance" />;
        // return <Navigate to="/org/dashboard" />;
    }

    return (
        <div>
            {loading && (
                <div className="loadingBackground">
                    <ReactLoading
                        type="spin"
                        color="#006aff"
                        className="loadingSpin"
                    />
                </div>
            )}
            <div className="supLogin">
                <div>
                    <div className="supLoginLogoDiv">
                        <img src={TrvToLogo} alt="TrvTo logo" />
                    </div>
                    <div className="supLoginDiv">
                        <Announcement />
                        <h1 className="supLoginH1">Support Login</h1>

                        <div className="supLoginInputDiv">
                            <input
                                placeholder="Organizer Email"
                                name="emailId"
                                onChange={(e) =>
                                    handleChange(e, setUserDetails)
                                }
                                onKeyDown={handleKeyDown}
                                value={userDetails.emailId}
                                type="email"
                                className="supLoginInput"
                            />
                            {emailError && (
                                <span className="supLoginError">
                                    {emailError}
                                </span>
                            )}
                            {response === '200' && (
                                <div className="supInputDiv">
                                    <p>Check your email for OTP</p>
                                    <label>OTP</label>
                                    <OTPInput
                                        length={6}
                                        setUserData={setUserDetails}
                                        keyName={'otp'}
                                        handleKeyDown={handleKeyDown}
                                    />
                                </div>
                            )}
                            {otpError && (
                                <span className="supLoginError">
                                    {otpError}
                                </span>
                            )}
                        </div>
                        <button
                            type="button"
                            onClick={() =>
                                response !== '200' ? getSupOTP() : handleLogin()
                            }
                            className="supLoginBtn"
                        >
                            Login
                        </button>
                    </div>
                </div>
                <div className="supLoginInfoDiv">
                    <TravelImg />
                    <h2>Want to work with us? Contact Our team.</h2>
                    <div className="contact-details sup-login supContInfDiv">
                        <div className="details-item">
                            <div className="icon-con">
                                <Phone />
                            </div>
                            <a href="tel:9740665900">9742229962</a>
                        </div>
                        <div className="details-item">
                            <div className="icon-con">
                                <Email />
                            </div>{' '}
                            <a href="mailto:mr.sky.kj@gmail.com">
                                sagarb.b0@gmail.com
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SupLogin;
