import supHttps from '../init/supHttps';

//SUP-LOGIN-01 : Support user login
export async function supLoginFunAPI(data) {
    const result = await supHttps.post(`/support/user/login`, data);
    return result;
}

//SUP-LOGIN-02-get OTP in email to login
export async function supOTPFunAPI(data) {
    const result = await supHttps.post(`/support/user/login/otp`, data);
    return result;
}
