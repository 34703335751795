import { useState } from 'react';
import './orgHeader.css';
// import OrgLogin from "../../../components/orgLogin/orgLogin/OrgLogin";
// import OrgForgotPass from "../../../components/orgLogin/orgForgot/OrgForgotPass";
import { Link } from 'react-router-dom';
import OrgHeadDrop from './orgHeadDrop/OrgHeadDrop';
import Logo from '../../../assets/images/logo.svg';
import MenuIcon from '../../../assets/icons/downArrow.svg';
import UserIcon from '../../../assets/icons/user.svg';
import AddIcon from '../../../assets/icons/plus.svg';

export default function OrgHeader() {
  const [page, setPage] = useState(window.location.pathname.split('/')[2]);
  const [headDrop, setHeadDrop] = useState(false);
  const name = localStorage.getItem('orgName');

  function linkClick(text) {
    setPage(text);
  }

  return (
    <>
      <div className="orgHeaderHomeDiv">
        <div className="logoContainer">
          <img alt="travel logo" src={Logo} className="logo" />
        </div>
        {/* <div>
        <Link
          to="/org/dashboard"
          className={page === "dashboard" ? "orgHeaderBtnSel" : "orgHeaderBtn"}
          onClick={() => linkClick("dashboard")}
        >
          Dashboard
        </Link>
        <Link
          to="/org/my-pack"
          className={page === "my-pack" ? "orgHeaderBtnSel" : "orgHeaderBtn"}
          onClick={() => linkClick("my-pack")}
        >
          My Packages
        </Link>
        <Link
          to="/org/my-room"
          className={page === "my-room" ? "orgHeaderBtnSel" : "orgHeaderBtn"}
          onClick={() => linkClick("my-room")}
        >
          My Rooms
        </Link>
        <button
          className={more ? "orgHeaderBtn moreDropBtnSel" : "orgHeaderBtn"}
          name="moreDrop"
          type="button"
          onClick={() => setMore(!more)}
        >
          More
        </button>
      </div> */}
        {/* {more && (
                    <div
                        className="overlay"
                        onClick={() => setMore(false)}
                    ></div>
                )}
                {more && <MoreDrop page={page} linkClick={linkClick} />} */}
        <div className="orgTabsEndDiv">
          {/* <Link
                        className="orgTabsHeading add-btn"
                        to="/org/create/room"
                        onClick={() => linkClick('create-room')}
                    >
                        <img
                            alt="add-icn"
                            src={AddIcon}
                            className="orgHeaderMenuIcon"
                        />
                        Create Room
                    </Link> */}
          <Link
            className="orgTabsHeading add-btn"
            to="/org/create/pack"
            onClick={() => linkClick('create-package')}
          >
            <img
              alt="add-icn"
              src={AddIcon}
              className="orgHeaderMenuIcon"
            />
            Create Package
          </Link>
          {/* <Link className="orgTabsHeading">
                        <img
                            alt="user-icon"
                            src={UserIcon}
                            className="orgHeaderMenuIcon"
                        />
                    </Link> */}
          <div className="divider" />
          <button
            className="orgTabsHeading menu"
            onClick={() => {
              setHeadDrop(true);
            }}
          >
            <img
              alt="menu-icon"
              src={UserIcon}
              width={16}
              height={16}
            />
            {name}
            <img
              alt="menu-icon"
              src={MenuIcon}
              className="orgHeaderMenuIcon"
            />
          </button>
        </div>
        {headDrop && (
          <div
            className="overlay"
            onClick={() => setHeadDrop(false)}
          ></div>
        )}
        {headDrop && <OrgHeadDrop linkClick={linkClick} />}
        {/* {logButton && (
        <div className="orgLoginHeaderPop">
          {loginSec === "login" ? (
            <OrgLogin setLogButton={setLogButton} setLoginSec={setLoginSec} />
          ) : loginSec === "forgot" ? (
            <OrgForgotPass
              setLogButton={setLogButton}
              setLoginSec={setLoginSec}
            />
          ) : null}
        </div>
      )} */}
      </div>
    </>
  );
}
