import TripDetailsSubHeading from "../../tripDetailsSubHeading/TripDetailsSubHeading";
import "./included.css";
export default function Included({ dataInc, dataExc }) {
  return (
    <div>
      <div className="detailsIncludedDiv">
        <TripDetailsSubHeading text={"Included"} />
        <div className="includedpoints">
          <ul>
            {dataInc?.map((inc, incIndex) => {
              return (
                <li key={incIndex} className="incText">
                  ✅ {inc}
                </li>
              );
            })}
          </ul>
        </div>
      </div>
      <div>
        <TripDetailsSubHeading text={"Excluded"} />
        <div className="includedpoints">
          <ul>
            {dataExc?.map((exc, excIndex) => {
              return (
                <li key={excIndex} className="incText">
                  🚫 {exc}
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
}
