export const share = async (e, id) => {
    e.stopPropagation();
    if (navigator.share) {
        try {
            await navigator.share({
                title: 'Check this out!',
                text: 'Here is something cool I wanted to share with you.',
                url: `${window.location.origin}/trip/details/${id}`,
            });
        } catch (error) {
            console.error('Error sharing:', error);
        }
    }
};