import DirectInput from "../../../../components/directInput/DirectInput";

export default function ContactPerson(props) {
  const { formData, setFormData } = props;
  return (
    <div>
      <h2 className="orgRegisterH2">Contact Person</h2>
      <div className="orgRegisterSecDiv">
        <DirectInput
          label="Name"
          name={"name"}
          value={formData.contactPerson.name}
          setFormData={setFormData}
          type="text"
          min={3}
          limit={30}
          nest="contactPerson"
        />
        <DirectInput
          label="Country Code"
          name={"countryCode"}
          value={formData.contactPerson.countryCode}
          setFormData={setFormData}
          type="text"
          min={3}
          limit={30}
          nest="contactPerson"
        />
        <DirectInput
          label="Phone Number"
          name={"phoneNumber"}
          value={formData.contactPerson.phoneNumber}
          setFormData={setFormData}
          type="text"
          min={3}
          limit={30}
          nest="contactPerson"
        />
        <DirectInput
          label="Email Id"
          name={"emailId"}
          value={formData.contactPerson.emailId}
          setFormData={setFormData}
          type="text"
          min={3}
          limit={30}
          nest="contactPerson"
        />
      </div>
    </div>
  );
}
