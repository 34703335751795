import React, { useEffect, useState } from "react";
import "./typingAni.css";

// export default function TypingAnimation({ text, speed }) {
//   const [displayedText, setDisplayedText] = useState("");
//   const [multipleText, setMultipleText] = useState(0);

//   useEffect(() => {
//     let index = 0;
//     console.log(text.length);
//     // let interval;
//     // for (let i = 0; i < text.length + 1; i++) {
//     if (multipleText < text.length) {
//       let interval = setInterval(() => {
//         setDisplayedText((prev) => prev + text[multipleText][index]);
//         index += 1;
//         if (index === text[multipleText].length - 1) {
//           clearInterval(interval);
//         }
//       }, speed);
//       let intervalTwo = setInterval(() => {
//         setDisplayedText((prev) => prev - text[multipleText][index]);
//         index += 1;
//         if (index === text[multipleText].length - 1) {
//           clearInterval(interval);
//         }
//       }, speed);
//       setMultipleText(multipleText + 1);
//       setDisplayedText("");

//       if (multipleText === text.length) {
//         return () => clearInterval(interval);
//       }
//     }
//   }, [text, speed, multipleText]);

//   return (
//     <div className="typing-animation">
//       {displayedText}
//       <span className="cursor">|</span>
//     </div>
//   );
// }
export default function TypingAnimation({ texts, handleSpan, seConStyle }) {
  const [currentText, setCurrentText] = useState("");
  const [currentTextIndex, setCurrentTextIndex] = useState(0);
  const [currentCharIndex, setCurrentCharIndex] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    function handleTextChange() {
      if (!isDeleting && currentCharIndex < texts[currentTextIndex].length) {
        setCurrentText(
          currentText + texts[currentTextIndex].charAt(currentCharIndex)
        );
        setCurrentCharIndex(currentCharIndex + 1);
      } else if (isDeleting && currentCharIndex > 0) {
        setCurrentText(currentText.substring(0, currentCharIndex - 1));
        setCurrentCharIndex(currentCharIndex - 1);
      } else if (
        !isDeleting &&
        currentCharIndex === texts[currentTextIndex].length
      ) {
        setTimeout(() => setIsDeleting(true), 500); // Wait before starting to delete
      } else if (isDeleting && currentCharIndex === 0) {
        setIsDeleting(false);
        setCurrentTextIndex((currentTextIndex + 1) % texts.length);
      }
    }

    const timeout = setTimeout(handleTextChange, isDeleting ? 50 : 50); // Adjust speed for deleting and typing

    return () => clearTimeout(timeout);
  }, [currentCharIndex, currentText, currentTextIndex, isDeleting, texts]);

  return (
    <div
      style={seConStyle}
      className="ani-search-container"
      onClick={handleSpan}
    >
      {/* <input
        type="text"
        value={currentText}
        readOnly
        className="search-input"
      /> */}
      {/* <div className="search-input">{currentText}</div> */}
      {/* <span className="cursor">|</span> */}
      {<span className="ani-search-input">{currentText}</span>}
    </div>
  );
}
