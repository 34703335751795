import './tripDateTable.css';
import '../../dashboard/dashboardTable/dashboardTable.css';

//pagination not required as we are populating data from bookingId array.
//And data wont reach more than 200
export default function TripDateTable({ bookingData }) {
    const tableHeader = [
        'Sl No',
        'Name',
        'Status',
        'Strat City',
        'Pick Up',
        'Booked On',
        'Seats',
        'Transport',
        'Booked Via',
        'People'
    ];

    const getUserData = (row) => {
        return (
            row.userDetails.map(function (
                user,
                userKey
            ) {
                return (
                    <div key={userKey}>
                        <span>{user.name}, </span>
                        <span>{user.age}, </span>
                        <span>{user.sex}, </span>
                        <span>
                            {user.phoneNumber}
                        </span>
                    </div>
                );
            })
        );
    };

    return (
        <div className="tripDateContainer">
            <div className="tableContainer">
                <table className="table">
                    <thead className="tableHead">
                        <tr>
                            {tableHeader.map((head, headKey) => {
                                return <th key={headKey}>{head}</th>;
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {bookingData.map((row, rowKey) => {
                            return (
                                <tr className="tableBodyRow" key={rowKey}>
                                    <td>{rowKey + 1}.</td>
                                    <td className="tripDateNameRow">
                                        {row.userDetails[0].name}
                                    </td>
                                    <td>{row.tripStatus}</td>
                                    <td>{row.stratCity}</td>
                                    <td>{row.pickupLocation}</td>
                                    <td>{row.createdAt.split('T')[0]}</td>
                                    <td>{row.totalSeats}</td>
                                    <td>{row.transport}</td>
                                    <td>{row.bookedVia}</td>
                                    <td>{getUserData(row)}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
                <div className="responsiveCardContainer">
                    {bookingData?.map((row, rowIndex) => (
                        <div key={rowIndex} className={`responsiveCard ${row.tripStatus.toLowerCase()}`}>
                            <div className="cardContent">
                                <h3>{row.userDetails[0].name}</h3>
                                <p><strong>{tableHeader[2]}:</strong> {row.tripStatus}</p>
                                <p><strong>{tableHeader[3]}:</strong> {row.stratCity}</p>
                                <p><strong>{tableHeader[4]}:</strong> {row.pickupLocation}</p>
                                <p><strong>{tableHeader[5]}:</strong> {row.createdAt.split('T')[0]}</p>
                                <p><strong>{tableHeader[6]}:</strong> {row.totalSeats}</p>
                                <p><strong>{tableHeader[7]}:</strong> {row.transport}</p>
                                <p><strong>{tableHeader[8]}:</strong> {row.bookedVia}</p>
                                <p className={`cardLabelDisplay ${row.userDetails.length > 1 ? 'multiple' : ''}`}><strong>{tableHeader[9]}:</strong> {getUserData(row)}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}
