import TripDetailsSubHeading from "../../tripDetailsSubHeading/TripDetailsSubHeading";
import "./itinerary.css";

export default function Itinerary({ data }) {
  return (
    <div>
      {data?.map((dayData, dayIndex) => {
        return (
          <div key={dayIndex} className="detailsItineraryDiv">
            <TripDetailsSubHeading
              text={`Day ${dayIndex} - ${dayData.dayHeading}`}
            />
            <div className="detailsItineraryDayDec">
              <span className="detailsItineraryDayDecSpan">
                {dayData.dayDescription}
              </span>
            </div>
          </div>
        );
      })}
    </div>
  );
}
