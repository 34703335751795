import { useState } from "react";
import Tab from "../../components/tabs/tabs";
import "./tabsBar.css";
import Holiday from "./searchComponents/holiday";
import Camp from "./searchComponents/camp";

export default function TabsBar({ className }) {
  const [activeTab, setActiveTab] = useState('Holiday');

  return (
    <div className={`homeSearchWrapper ${className}`}>
      <div className="tabsWrapper">
        <Tab label='Holiday' activeTab={activeTab} setActiveTab={setActiveTab} />
        <Tab disabled label='Camp' activeTab={activeTab} setActiveTab={setActiveTab} />
        <Tab disabled label='Guide' activeTab={activeTab} setActiveTab={setActiveTab} />
        <Tab disabled label='Vehicle' activeTab={activeTab} setActiveTab={setActiveTab} />
      </div>
      <div className="tabContent">
        {activeTab === 'Holiday' && <Holiday />}
        {activeTab === 'Camp' && <Camp />}
        {activeTab === 'Guide' && <Holiday />}
        {activeTab === 'Vehicle' && <Camp />}
      </div>
    </div>
  );
}
