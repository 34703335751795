import TitleBar from "../../components/titleBar/titleBar";
import "./privacy.css";

export default function Privacy() {
  return (
    <>
      <TitleBar title="Privacy Policy" className='customTitle' />
      <div className="customContainer privacy">
        <p>
          TrvTo is committed to safeguarding your privacy and understands the importance of protecting your personal information, including details such as your name, address, financial data, and phone number. We want to reassure you that we adhere to industry-standard practices to ensure your privacy is protected on our websites and applications.
        </p>
        <p>
          Generally, you can browse TrvTo without disclosing your identity or personal information. We monitor the internet addresses of the domains that visit us and analyze this data for trends and statistical purposes, but individual users remain anonymous.
        </p>
        <p>
          TrvTo is committed to protecting the information you provide. We have adopted advanced technology, security measures, and strict policy guidelines to ensure the privacy of your personal data, preventing unauthorized access, misuse, or disclosure. As new technologies emerge, we will continue to improve our security protocols.
        </p>
        <p>
          At times, we may collect personal information when you create an account, provide us with your details, or use services related to TrvTo. This could include participating in contests, surveys, applying for a job, or engaging in other activities we offer that require your information, such as filling out contact forms or requesting newsletters. While you can still visit the TrvTo website or app without providing this information, please note that some options, offers, and services may not be available to you.
        </p>
        <p>
          Transactions on TrvTo are only permitted for individuals aged 16 years or older. If you are under 16, you are not authorized to make a transaction on the platform. Legal guardians of minors under the age of 16 are responsible for ensuring their wards do not conduct transactions without supervision on TrvTo. By permitting anyone under the age of 16 to make a transaction on TrvTo, it will be considered that the legal guardians have given explicit consent for their use. We disclaim any responsibility for any issues arising from a failure to supervise such transactions.
        </p>
        <p>When you access TrvTo, we automatically collect certain data from your device, which may include:</p>
        <ul>
          <li>Browser type</li>
          <li>IP address</li>
          <li>Preferred language</li>
          <li>Operating system</li>
          <li>Cookies, as well as the ID and location of your device</li>
          <li>The state or country from which you accessed TrvTo</li>
          <li>The pages or videos you viewed</li>
          <li>The amount of time spent on pages or videos</li>
          <li>The date and time of your visit</li>
          <li>Metadata, log files, and error logs</li>
          <li>Geographic and demographic details</li>
          <li>Information about your hardware and software</li>
          <li>Interactions with pop-up or push notifications you may have seen and responded to</li>
        </ul>
        <p>We collect this computer data to analyze and assess how we can improve TrvTo, the services we offer, and enhance your overall experience, ensuring you receive better services and benefits. If this data is stored in a way that can identify your name or contact information, it will be considered personal data. Otherwise, it will be treated as non-personal data.</p>
        <p>The personal data we gather may be used or shared with third parties (including affiliated companies and external service providers) for various purposes, including</p>
        <ul>
          <li>Creating or managing your account or profile with us</li>
          <li>Tracking your activity on TrvTo</li>
          <li>Verifying and processing financial transactions related to your payments or transfers</li>
          <li>Conducting research and analysis on user demographics and behaviors</li>
          <li>Personalizing communications we send to you and offering products, services, or information that might be useful to you or that you have requested</li>
          <li>Enhancing your overall user experience</li>
          <li>Assessing your eligibility for certain marketing campaigns, transactions, and features of TrvTo</li>
          <li>Enforcing our terms of use</li>
          <li>Communicating with you about your account and keeping you informed of any updates</li>
          <li>Managing any competitions, promotions, or offers you enter, including fraud prevention and screening activities</li>
        </ul>
        <p>When you create an account or provide us with your personal data, we may use this information to send you marketing and promotional materials about our services occasionally. You can opt-out of receiving such communications at any time by using the unsubscribe option within the marketing emails.
        </p>
        <p>To deliver our products and services to you or fulfill our contractual obligations, we may need to engage third-party organizations to handle some of the data processing on our behalf. These may include payment processors, delivery companies, fraud detection services, credit risk management firms, and mailing agencies.</p>
        <p>We may also share your data with advertising networks and social media platforms to help target and deliver relevant ads to you, as well as with search engine and analytics providers.</p>
        <p>As an intermediary, we facilitate transactions between you and the event organizers, trip planners, Guides, vehicle owners, hotel providers, and other services. To complete these transactions and meet our contractual obligations with these providers, we may need to share your personal data with them. This allows them to verify the information you’ve provided, issue valid tickets or bookings, offer you the services and benefits you’re entitled to, and conduct their own analyses.
        </p>

        <b>Social Media Platforms</b>
        <p>Any communication, engagement, or actions taken through external social media platforms that this website and its owners participate in are governed by the terms and conditions and privacy policies of each respective platform.</p>
        <p>Users are encouraged to exercise caution when using social media platforms, especially regarding the protection of their privacy and personal information. This website and its owners will never request sensitive personal details via social media and recommend that users contact us through more secure methods, such as phone or email, for discussing sensitive matters.</p>
        <p>This website may incorporate social sharing buttons that enable users to share content directly with social media platforms. Before using these buttons, users should be aware that their actions may be tracked by the social media platform and that their accounts may be used to record the sharing activity.</p>
        <p>Shortened Links in Social Media
        </p>
        <p>The website and its owners may share links to relevant web pages through their social media accounts. Social media platforms sometimes shorten URLs for convenience.</p>
        <p>Users should exercise caution when clicking on shortened URLs shared via social media. While we make every effort to ensure these links are legitimate, social media platforms are sometimes susceptible to spam and malicious activity, so we cannot be held responsible for any harm or consequences resulting from clicking on such links.
        </p>

        <b>Ads and Sponsored Links</b>
        <p>This website may contain sponsored content or advertisements, which are typically provided by advertising partners. These partners may have their own privacy policies governing the ads they serve.
          Clicking on sponsored ads will direct users to the advertiser's website, which may track referral activity via cookies. Users should be aware that by clicking on these external links, they do so at their own risk, and this website and its owners are not liable for any issues arising from visiting external websites.</p>
        <p>External Links</p>
        <p>TrvTo and its services may include links to third-party websites, such as identity verification services or social media platforms. The use of these features may involve the collection or sharing of your information, which is governed by the respective privacy policies of those third-party services.</p>
        <p>We are not responsible for the content or privacy practices of these external sites, nor do we endorse or make any claims about them. Any personal data you provide to third parties is not covered under our privacy policy, and we strongly recommend reviewing the privacy statements of these third parties.</p>

        <b>Sharing of Personal Data</b>
        <p>We may disclose your personal data to third parties under the following circumstances:</p>
        <p>To comply with legal obligations, such as responding to subpoenas, court orders, government requests, or other legal processes;</p>
        <p>To cooperate in legal proceedings, investigations, or to protect the rights and safety of TrvTo or others;</p>
        <p>As otherwise allowed by applicable law.</p>
        <p>We may also share personal data with service providers, including those involved in processing payments, customer support, and fulfillment services. We ensure that these third parties take reasonable steps to protect your personal data and only retain it for as long as necessary to perform their services.</p>
        <p>If required, we may disclose your personal data to regulatory or government authorities as part of legal obligations. Should any personal data be transferred outside of India, we will ensure that appropriate safeguards are in place to protect it.</p>

        <b>Security Measures</b>
        <p>We implement various security protocols, including physical, administrative, and technical measures, to safeguard your personal data. Our PCI DSS certification ensures that your data is protected according to global security standards. However, no security system is completely foolproof, and if you believe your privacy has been compromised, please contact us immediately.</p>
        <p>You are responsible for safeguarding your passwords and OTPs for accessing your account. If your login details are compromised, you may lose control over your account and personal data, so please inform us immediately if you suspect any unauthorized access.</p>

        <b>Cookies</b>
        <p>This website uses cookies to improve your browsing experience and customize content for returning visitors. Cookies are small files that store information on your computer to facilitate future visits. You can configure your browser to notify you when a cookie is being sent, giving you the choice to accept or decline it. Refusing cookies may limit access to some features of the website.</p>

        <b>Data Retention</b>
        <p>We retain personal data only for as long as necessary to fulfill the purposes for which it was collected. We may also retain data for legal, business, or contractual purposes, such as to resolve disputes or comply with legal obligations.</p>

        <b>Privacy Policy Changes</b>
        <p>If our privacy policy is updated, the new version will be posted here with immediate effect. We recommend that you review this policy regularly to stay informed about any changes.</p>
        <p>If you have any questions or concerns about how we handle your personal information, please contact our Customer Grievance Officer or use our support page to resolve any issues promptly.</p>
      </div>
    </>
  );
}
