import './cancelBooking.css';

const CancelBooking = ({ setShowModal, handleConfirm }) => {
    const handleClose = (e) => {
        e.stopPropagation();
        setShowModal(false);
    };
    return (
        <div className="modal">
            <div className="modal-content">
                <p className='modal-title'>Do you want to cancel the booking?</p>
                <button onClick={handleConfirm} className="confirm-btn">
                    Yes
                </button>
                <button onClick={(e) => handleClose(e)} className="cancel-btn">
                    No
                </button>
            </div>
        </div>
    );
};

export default CancelBooking;
