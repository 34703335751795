import {
    orgTripBookList,
    orgTripBookToday,
    orgTripDrop
} from '../../../api/orgTripAPI';
import errorHandle from '../../../utils/errorHandle';

export const fetchOrgBooking = (
    setBookings,
    setLoading,
    offset,
    trip = '',
    selectedDate = ''
) => {
    setLoading(true);
    try {
        orgTripBookList(10, offset, trip, selectedDate).then((res) => {
            setBookings(res.data);
            setLoading(false);
        });
    } catch (error) {
        setLoading(false);
        errorHandle(error);
    }
};

export const getBookingsToday = (setTodaysBookings, setLoading) => {
    setLoading(true);
    try {
        orgTripBookToday().then((res) => {
            setTodaysBookings(res.data);
            setLoading(false);
        });
    } catch (error) {
        setLoading(false);
        errorHandle(error);
    }
};

export const getTrips = (setTrips, setLoading) => {
    setLoading(true);
    try {
        orgTripDrop().then((res) => {
            setTrips(res.data);
            setLoading(false);
        });
    } catch (error) {
        setLoading(false);
        errorHandle(error);
    }
};
