import { createContext, useState } from "react";

export const OrgDatesContext = createContext();

export function OrgDatesProvider({ children }) {
  const [dates, setDates] = useState([]);
  const [upComing, setUpComing] = useState(true);

  return (
    <OrgDatesContext.Provider
      value={{ dates, setDates, upComing, setUpComing }}
    >
      {children}
    </OrgDatesContext.Provider>
  );
}
