import './tripStrap.css';

import Calendar from '../../assets/icons/calendar.svg';
import CancelBooking from './tripStrapFun/cancelBooking';
import { FormContent } from '../bookingConfirmationCard/bookingConfirmationFun';
import ReviewStars from '../reviewStars/ReviewStars';
import UserCount from '../../assets/icons/userCount.svg';
import { getAmenities } from './tripStrapFun/amenities';
import image1 from '../../assets/images/tripDetails1.jpg';
import { ReactComponent as Share } from '../../assets/icons/share.svg';
import { ReactComponent as Info } from '../../assets/icons/info.svg';
import { useEffect, useState } from 'react';
import { share } from './tripStrapFun/shareFun';
import { Navigate } from 'react-router-dom';
import { handleBooking } from './tripStrapFun/handleBooking';
import { userReview } from '../../api/userAPI';

export default function TripStrap({ page, data }) {
    const [showModal, setShowModal] = useState(false);
    const [typeSel, setTypeSel] = useState('trip');
    const [nav, setNav] = useState(false);
    const [userRating, setUserRating] = useState(0);
    const [id, setId] = useState('');
    const [isRatingEnabled, setIsRatingEnabled] = useState(false);
    const [showRatingModal, setShowRatingModal] = useState(false);
    const [comment, setComment] = useState("");
    const [newCardData, setNewCardData] = useState({});
    const [popupRating, setPopupRating] = useState(userRating);
    const startDate = new Date(data?.startDate);
    const handleCancelBooking = (e) => {
        e.stopPropagation();
        setShowModal(true);
    };

    const openRatingModal = (e, cardData) => {
        e.stopPropagation();
        setShowRatingModal(true);
        setPopupRating(userRating);
        setNewCardData(cardData);
    };

    const closeRatingModal = () => setShowRatingModal(false);

    const handleClick = (data) => {
        setId(data);
        setNav(true);
    };

    useEffect(() => {
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        if (startDate <= today) {
            setIsRatingEnabled(true);
        }
    }, [startDate]);

    const handleSubmitRating = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        const ratingData = {
            packageId: newCardData._id,
            userName: localStorage.getItem("name"),
            userId: localStorage.getItem("userId"),
            rating: popupRating,
            organizerId: data.organizerId,
            comment
        };
        await userReview(typeSel, ratingData);
        setUserRating(popupRating);
        closeRatingModal();
    };

    let cardData;

    if (page === 'bookings') {
        cardData = data.packId;
    } else {
        cardData = data;
    }

    return (
        <div className="tripStrap" onClick={() => handleClick(cardData._id)}>
            <div className="tripStrapContent">
                <img src={image1} alt="trip" className="tripStrapImage" />
                <div className="tripStrapInfoDiv">
                    <div className="tripStrapNameDiv alignCenter">
                        <div className="tripStrapInfo">
                            <p className="tripStrapName">
                                {cardData?.title || 'N/A'}
                                {cardData?.quickView?.type === 'Trek' && (
                                    <div className="typeInfo">
                                        {cardData?.quickView?.type || 'N/A'}
                                    </div>
                                )}
                            </p>
                            <p className='quickView'>{cardData?.quickView?.where + ' (' + cardData?.quickView?.duration + ') | ' + cardData?.quickView?.baseCities}</p>
                            <div className="tripCardNumInfo">
                                <ReviewStars rating={cardData?.rating || 0} />
                                <span>{cardData?.rating || 0}</span>
                                <p style={{ margin: 0 }}>
                                    ({cardData?.reviewCount || 0} Reviews)
                                </p>
                            </div>
                            <div className="tripStrapDateDetails">
                                <div className="alignCenter">
                                    <img src={Calendar} alt="calendar" />
                                    <span>
                                        {cardData?.quickView?.startTime.toUpperCase()} -{' '}
                                        {cardData?.quickView?.endTime.toUpperCase()}
                                    </span>
                                </div>
                                {page === 'bookings' && (
                                    <>
                                        <div className="divider" />
                                        <div className="alignCenter">
                                            <img src={UserCount} alt="user" />
                                            <span className='userCount'>{data?.totalSeats}</span>
                                        </div>
                                    </>
                                )}
                            </div>
                            {cardData?.type === 'rooms' && (
                                <div className="amenities">
                                    {getAmenities(cardData?.quickView?.amenities)}
                                </div>
                            )}
                            {(page === 'bookings' && cardData?.quickView?.type === 'Trek') && (
                                <>
                                    <div className="innerFormContent">
                                        <FormContent
                                            id="extraStyle"
                                            label="Timing"
                                            answer={cardData?.quickView?.startTime}
                                            className="borderNone width30"
                                        />
                                        <FormContent
                                            id="extraStyle"
                                            label="Organizer"
                                            answer={cardData?.organizerName}
                                            className="borderNone width30"
                                        />
                                    </div>
                                </>
                            )}
                        </div>
                        <div className="tripStrapPriceDiv">
                            <div className="tripStrapPrDiv">
                                <p className="tripStrapPrice">
                                    {page === 'bookings' ? `₹ ${data?.amountPaid?.paid}` : `₹ ${cardData?.price[0]?.price}`}
                                    <span>/{typeSel === "trip" ? "person" : "night"}</span>
                                </p>
                                {page === 'bookings' && (
                                    <div className='rateBtnCon'>
                                        <button
                                            className="cancelBooking"
                                            onClick={(e) => handleCancelBooking(e)}
                                        >
                                            Cancel Booking
                                        </button>
                                        {isRatingEnabled && <button onClick={(e) => openRatingModal(e, cardData)} className="rateButton">
                                            Rate Now
                                        </button>}
                                    </div>
                                )}
                            </div>
                            {/* {page === 'bookings' && (
                                <div>
                                    <button>Change Date</button>
                                    <p className="tripStrapName">
                                        Rate the Trip
                                    </p>
                                </div>
                            )} */}
                        </div>
                    </div>
                </div>
                <div className='moreIcons'>
                    <Share onClick={(e) => share(e, cardData._id)} />
                    <Info onClick={(e) => e.stopPropagation()} title={cardData?.overview} />
                </div>
            </div>
            <div>
                {cardData?.type === 'trip' && (
                    <>
                        <div className="tripDivider holidayDivider" />
                        <p className="locationInfo">
                            Pickup Location:{' '}
                            <span>{cardData?.quickView?.pickupLocation}</span>
                        </p>
                    </>
                )}
            </div>
            {showModal && <CancelBooking setShowModal={setShowModal} handleConfirm={(e) => handleBooking(e, cardData, setShowModal, typeSel)} />}
            {showRatingModal && (
                <div className="ratingModal">
                    <div className="modalContent">
                        <h3>Rate Your Trip</h3>
                        <ReviewStars
                            rating={popupRating}
                            onRatingChange={(newRating) => setPopupRating(newRating)}
                            isInteractive
                        />
                        <textarea
                            value={comment}
                            onChange={(e) => setComment(e.target.value)}
                            onClick={(e) => e.stopPropagation()}
                            placeholder="Add your comments here..."
                            className="commentBox"
                        />
                        <div className="modalActions">
                            <button onClick={(e) => {
                                e.stopPropagation();
                                closeRatingModal(e);
                                handleSubmitRating(e);
                            }} className="submitRating">
                                Submit
                            </button>
                            <button
                                onClick={(e) => {
                                    e.stopPropagation();
                                    closeRatingModal(e);
                                }}
                                className="closeModal">
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {nav && <Navigate to={`/trip/details/${id}`} />}
        </div>
    );
}
