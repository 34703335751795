import { useEffect } from 'react';
import FooterIcon from '../../../assets/icons/footerIcon.png';
import { Link, useLocation } from 'react-router-dom';

const FooterItem = ({ name }) => {
    const location = useLocation();
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }, [location]);
    return (
        <div className="menuItem">
            <img src={FooterIcon} alt="arrow" />
            <Link to={name.link}>{name.name}</Link>
        </div>
    );
};

export default FooterItem;
