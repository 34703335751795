import { toast } from "react-toastify";
import { blogUploadFun } from "../../../api/blogAPI";
import { uploadImageToFirebase } from "./uploadImage";
import { generateUuid } from "../../../utils/randomString";

export default async function blogSubmit(blog, file, userId, setLoading) {
  if (blog.name.length < 20) {
    toast.error('Name should be minimum 20 characters');
    return;
  }
  if (blog.name.length > 60) {
    toast.error('Name should be not more than 60 characters');
    return;
  }

  const uuid = generateUuid();

  try {
    setLoading(true);
    const description = await Promise.all(blog.description.map(async (section) => {
      const totalDescriptionLength = section.description.reduce((acc, paragraph) => acc + paragraph.length, 0);
      if (section.subHeader.length < 50) {
        setLoading(false);
        toast.error('Short description should be minimum 50 characters');
        return null;
      }
      if (section.subHeader.length > 150) {
        setLoading(false);
        toast.error('Short description should be not more than 150 characters');
        return null;
      }
      if (totalDescriptionLength < 250) {
        setLoading(false);
        toast.error('Description should not be less than 250 characters');
        return null;
      }
      if (totalDescriptionLength > 5000) {
        setLoading(false);
        toast.error('Description should be less than 5000 characters');
        return null;
      }
      const sectionImageUrl = section.image ? await uploadImageToFirebase(section.image, uuid) : "";

      return {
        title: section.subHeader,
        description: section.description,
        image: sectionImageUrl,
      };
    }));

    const mainImageUrl = await uploadImageToFirebase(file, uuid);

    const blogData = {
      name: blog.name,
      shortDescription: blog.shortDescription,
      description: description,
      image: mainImageUrl,
      author: userId,
      tags: blog.tags,
      imageRefId: uuid
    };

    await blogUploadFun(blogData);
    setLoading(false);
    toast.success("Blog uploaded successfully!");
  } catch (error) {
    console.log(error);
    setLoading(false);
    toast.error("An error occurred while submitting the blog");
  }
}
