import { DiscountCards } from "../../../dummyData/homePage";
import './discount.css';

const DiscountSection = () => {
    return (
        <div className="customContainer">
            <div className="discountSection">
                {DiscountCards.map((item) => {
                    return (
                        <img key={item.id} src={item.image} alt={item.description} />
                    );
                })}
            </div>
        </div>
    );
}

export default DiscountSection;
