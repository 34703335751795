import Counter from "../../../components/counter/counter";
import { Counters } from "../../../dummyData/homePage";
import './counter.css';

const CounterSection = () => {
    return (
        <div className="counterBg">
            <div className="counterWrapper customContainer" id="counterWrapper">
                {Counters.map((item) => {
                    return (
                        <div key={item.id}>
                            <img src={item.image} alt={item.number} width={40} height={40} />
                            <Counter className='counter' targetNumber={item.number}></Counter>
                            <p className="counterSubhead">{item.title}</p>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

export default CounterSection;