import Textarea from "../../../../components/textarea/Textarea";

export default function InEx({ tempData, setTempData }) {
  return (
    <div>
      <h2 className="orgPackUpH2">Included/Excluded</h2>
      <div className="orgPackUpSecDiv">
        <div>
          <p className="tagsNoteP">
            Note: Type each point in new(press enter) line (each new point
            should come in next line). Don't number the points as it will be
            numbered automatically on the user end
          </p>
        </div>
        <Textarea
          label="Included"
          name="included"
          value={tempData.included}
          placeholder="What is included in the package"
          setFormData={setTempData}
          directInDiv="orgPackUpInputDiv"
          labelCss="orgPackUpLabel"
          inputCss="orgPackUpInput"
          min={20}
          limit={800}
        />
        <Textarea
          label="Excluded"
          name="excluded"
          value={tempData.excluded}
          placeholder="What is excluded in the package"
          setFormData={setTempData}
          directInDiv="orgPackUpInputDiv"
          labelCss="orgPackUpLabel"
          inputCss="orgPackUpInput"
          min={20}
          limit={800}
        />
      </div>
    </div>
  );
}
