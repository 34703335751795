import { blogDeleteFun } from "../../../api/blogAPI";
import errorHandle from "../../../utils/errorHandle";

export default async function deleteBlogsData(blogData, setLoading) {
  setLoading(true);
  try {
    await blogDeleteFun(blogData);
    setLoading(false);
  } catch (error) {
    errorHandle(error);
    setLoading(false);
  }
}
